import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import type { UniqueIdentifier } from "@dnd-kit/core";
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Fragment, type ReactNode } from "react";
import { DragHandle } from "./DragHandle";
import { SortableItem } from "./SortableItem";

interface BaseItem {
  id: UniqueIdentifier;
}

export interface SortableListProps<T extends BaseItem> {
  items: T[];
  onChange?: (items: T[]) => void;
  onMove?: (activeIndex: number, overIndex: number) => void;
  renderItem: (item: T, index: number) => ReactNode;
}

export function SortableList<T extends BaseItem>({ items, onChange, onMove, renderItem }: SortableListProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragOver={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          if (onMove) onMove(activeIndex, overIndex);
          if (onChange) onChange(arrayMove(items, activeIndex, overIndex));
        }
      }}
    >
      <SortableContext items={items}>
        <ul className="flex list-none flex-col gap-2">
          {items.map((item, index) => (
            <Fragment key={item.id}>{renderItem(item, index)}</Fragment>
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
