import { isEmpty } from "ramda";
import { Link } from "react-router-dom";
import DownloadAppStore from "@/assets/images/download-appstore.png";
import DownloadGooglePlay from "@/assets/images/download-googleplay.png";
import MobileAppImage from "@/assets/images/signup/mobile.svg";
import { Container, Typography } from "@/components/atoms";
import { GeneralFooter, GeneralHeader } from "@/components/organisms";
import { useOperator } from "@/hooks";
import { Restricted } from "../Restricted/Restricted";
import QRCode from "react-qr-code";

export const DriverSignup = () => {
  const {
    availableAccountTypesOnRegister,
    singleTenant,
    name,
    registerLinks: { driver },
  } = useOperator();
  const signUpEnabled = !isEmpty(availableAccountTypesOnRegister) || !singleTenant;

  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      <div className="relative flex flex-1 flex-col">
        <GeneralHeader action="login" />
        {signUpEnabled ? (
          <div className="flex-1">
            <Container className="flex w-full max-w-[1040px] flex-1 justify-center gap-5 px-5 py-8 md:py-[100px]">
              <div className="flex flex-col md:flex-row ">
                <div className="relative z-10 w-full p-5">
                  <Typography variant="h1" className="mb-4">
                    {name} Driver App
                  </Typography>
                  <Typography variant="paragraph" className="mb-4">
                    Download the Driver App to get started. Driver sign-up is now exclusively vailable on our mobile app. Click below to
                    download and complete your registration.
                  </Typography>
                  <div className="flex gap-4">
                    <Link to={driver} target="_blank">
                      <img src={DownloadAppStore} className="w-[120px]" alt="Download on the App Store" />
                    </Link>
                    <Link to={driver} target="_blank">
                      <img src={DownloadGooglePlay} className="w-[135px]" alt="Get it on Google Play" />
                    </Link>
                  </div>
                  <Typography variant="paragraph" className="my-4 text-neutral-dark-gray">
                    or Scan the QR to download
                  </Typography>
                  <QRCode size={120} value={driver} />
                </div>
                <div className="relative hidden w-full sm:block">
                  <br />
                  <img src={MobileAppImage} alt="Phone" className="absolute w-[900px] max-w-none md:-right-1/2 md:-top-1/4" />
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <Restricted />
        )}
        <div className="pointer-events-none fixed bottom-0 min-h-[50%] w-full bg-city bg-cover bg-no-repeat" />
      </div>
      <GeneralFooter />
    </div>
  );
};
