import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { createSearchParams } from "@/utils";
import { transformClientOptions, transformClients } from "./helpers";
import { GetClientsParams, GetClientsResponse, ReactivateAccountResponse, RemoveAccessResponse } from "./types";
import { ClientsOptions } from "@/redux/slices/client/types";

export const clientApi = createApi({
  reducerPath: "clientApi",
  baseQuery: baseQueryWithProvider("client"),
  endpoints: (builder) => ({
    getClients: builder.query<GetClientsResponse, GetClientsParams>({
      query: (params) => `?${createSearchParams(params).toString()}`,
      providesTags: ["Clients"],
      transformResponse: transformClients,
    }),
    removeAccess: builder.mutation<RemoveAccessResponse, string>({
      query: (id) => ({
        url: `/${id}/access`,
        method: "DELETE",
      }),
      invalidatesTags: ["Clients"],
    }),
    reactivateAccount: builder.mutation<ReactivateAccountResponse, string>({
      query: (id) => ({
        url: `/${id}/access`,
        method: "POST",
      }),
      invalidatesTags: ["Clients"],
    }),
    getClientOptions: builder.query<ClientsOptions, void>({
      query: () => "/options",
      transformResponse: transformClientOptions,
    }),
  }),
  tagTypes: ["Clients"],
});

export const {
  useGetClientsQuery,
  useLazyGetClientsQuery,
  useRemoveAccessMutation,
  useReactivateAccountMutation,
  useGetClientOptionsQuery,
} = clientApi;
