import { ClientsContextProvider, ClientsFiltersProvider, ClientsPanel } from "@/components/organisms";
import { AddClient } from "./AddClients";

export const Clients = () => (
  <ClientsContextProvider>
    <ClientsFiltersProvider>
      <ClientsPanel />
    </ClientsFiltersProvider>
  </ClientsContextProvider>
);

Clients.Add = AddClient;
