import { ApplicationAnswers, RawApplicationAnswers } from "./types";

export const transformApplicationAnswers = (res: RawApplicationAnswers) => {
  const { driver_application_responses } = res;

  const response: ApplicationAnswers = {
    applicationAnswers: driver_application_responses.map(applicationAnswer => ({
      id: applicationAnswer.uuid,
      question: applicationAnswer.question,
      type: applicationAnswer.type,
      response: applicationAnswer.response,
    })),
  };

  return response;
};