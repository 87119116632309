import { useDebouncedState } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Autocomplete, Avatar, Button, Icon, IconButton, Loading, Modal, Spinner, TextArea, Tooltip, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateBookingMutation } from "@/redux/apis/booking/bookingApi";
import { useLazySearchDriverQuery } from "@/redux/apis/typeahead/typeaheadApi";
import { useAppDispatch } from "@/redux/hooks";
import { setInlineEditing } from "@/redux/slices/booking/bookingSlice";
import { BookingDriver } from "@/redux/slices/booking/types";
import { addNotification, addToast, clsx, getAssetUrl } from "@/utils";
import { getDriverErrorTooltip, getDriverWarningTooltip } from "../helpers/driver";
import { useIsOperatorOwnJob } from "../hooks/useIsOperatorOwnJob";
import { useSelectedBooking } from "../hooks/useSelectedBooking";
import { addBookingNotification } from "../utils";

type DriverOption = {
  id: string;
  name: string;
  avatar: string;
  phone: string;
  nickname: string;
  email: string;
  error: { key: string; value: string };
  warning: string;
  operatorName: string;
  operatorKey: string;
  recommendedCommissionRate?: number;
};

interface ChangeDriverProps {
  open: boolean;
  onClose: () => void;
}

export const ChangeDriver = ({ open, onClose }: ChangeDriverProps) => {
  const dispatch = useAppDispatch();
  const [newDriver, setNewDriver] = useState<BookingDriver>();
  const [reason, setReason] = useState<string>("");
  const {
    id: bookingId,
    driver: currentDriver,
    status,
    cost: { percentageToDriver },
  } = useSelectedBooking();
  const [updateBooking, { isLoading: isSaving }] = useUpdateBookingMutation();
  const [step, setStep] = useState<"select-driver" | "remove-reason" | "reassign-reason" | "confirm-percentage">("select-driver");

  const renderTitle = () => {
    switch (step) {
      case "select-driver":
        return "Change Driver";
      case "reassign-reason":
        return "Reason for Changing Driver";
      case "remove-reason":
        return "Reason for Driver Removal";
      case "confirm-percentage":
        return "Driver Percentage Confirmation";
    }
  };

  const handleSelectDriver = () => {
    if (!currentDriver && newDriver) {
      if (!newDriver?.recommendedCommissionRate || newDriver.recommendedCommissionRate === percentageToDriver)
        updateBooking({
          bookingId,
          driver_uuid: newDriver?.id,
        })
          .unwrap()
          .then(() => {
            addBookingNotification(`Job ${bookingId} updated`, `Assigned new driver (${newDriver.name})`, status);
            dispatch(setInlineEditing(undefined));
          })
          .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
      else setStep("confirm-percentage");
    } else if (currentDriver && newDriver) setStep("reassign-reason");
    else dispatch(setInlineEditing(undefined));
  };

  const handleReasonSubmit = (reason: string) => {
    if (!newDriver?.recommendedCommissionRate || newDriver.recommendedCommissionRate === percentageToDriver) {
      if (!newDriver) throw Error("Cannot change driver without new driver data");

      updateBooking({
        bookingId,
        driver_uuid: newDriver?.id,
        driver_reason: reason,
      })
        .unwrap()
        .then(() => {
          addBookingNotification(`Job ${bookingId} updated`, `Assigned new driver (${newDriver.name})`, status);
          dispatch(setInlineEditing(undefined));
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    } else {
      setReason(reason);
      setStep("confirm-percentage");
    }
  };
  const handleRemoveDriver = () => {
    if (!currentDriver) setNewDriver(undefined);
    else setStep("remove-reason");
  };

  const renderContent = () => {
    switch (step) {
      case "select-driver":
        return (
          <SelectDriver
            newDriver={newDriver}
            onDriverChange={setNewDriver}
            onRemoveDriver={handleRemoveDriver}
            onSubmit={handleSelectDriver}
          />
        );
      case "reassign-reason":
        return newDriver ? <ChangeDriverReason newDriver={newDriver} onSubmit={handleReasonSubmit} /> : null;
      case "remove-reason":
        return <RemoveDriverReason />;
      case "confirm-percentage":
        return newDriver ? <ConfirmDriverPercentage newDriver={newDriver} reason={reason} /> : null;
    }
  };

  return (
    <Modal open={open} onClose={onClose} className="w-[550px] lg:px-5">
      {isSaving && <Loading />}
      <header className="flex">
        <Typography variant="title" className="flex-1">
          {renderTitle()}
        </Typography>
        {step !== "confirm-percentage" && <IconButton iconName="close" iconSize="lg" isCustomIcon variant="custom" onClick={onClose} />}
      </header>
      {renderContent()}
    </Modal>
  );
};

interface SelectDriverProps {
  newDriver?: BookingDriver;
  onDriverChange: (driver?: BookingDriver) => void;
  onRemoveDriver: () => void;
  onSubmit: () => void;
}

const SelectDriver = ({ newDriver, onDriverChange, onRemoveDriver, onSubmit }: SelectDriverProps) => {
  const {
    id: bookingId,
    driver: currentDriver,
    metadata: { isOffloaded },
  } = useSelectedBooking();
  const isOwnJob = useIsOperatorOwnJob();
  const [selectedDriver, setSelectedDriver] = useState<DriverOption | null>(null);
  const [query, setQuery] = useDebouncedState("", 300);
  const [searchDriver, { isFetching, data }] = useLazySearchDriverQuery();

  useEffect(() => {
    if (query.length > 1) searchDriver({ q: query, job_uuid: bookingId });
  }, [bookingId, query, searchDriver]);

  const handleChange = (driver: DriverOption | null) => {
    setSelectedDriver(driver);
    onDriverChange(
      driver
        ? ({
            id: driver.id,
            name: driver.name,
            avatar: driver.avatar,
            phone: driver.phone,
            nickname: "",
            email: driver.email,
            operator: {
              name: driver.operatorName,
              phone: "",
              providerKey: driver.operatorKey,
            },
            recommendedCommissionRate: driver.recommendedCommissionRate,
          } as BookingDriver)
        : undefined
    );
  };

  const options = data?.map(
    (i) =>
      ({
        id: i.id,
        name: i.name,
        avatar: i.avatar,
        phone: i.phone,
        email: i.email,
        error: i.errors ? i.errors[0] : "",
        warning: i.warnings ? i.warnings[0] : "",
        operatorName: i.operator.name,
        operatorKey: i.operator.providerKey,
        recommendedCommissionRate: i.recommendedCommissionRate,
      } as DriverOption)
  );

  const previewDriver = newDriver || currentDriver;
  const isEditable = !isOffloaded || isOwnJob;

  return (
    <>
      {previewDriver && (
        <div className="flex gap-2">
          <figure className="mt-3 flex w-full gap-3 rounded bg-neutral-gray px-4 py-3">
            <Avatar src={getAssetUrl(previewDriver.avatar, { height: 64, width: 64 })} alt={previewDriver.name} size="sm" />
            <Typography variant="small" className="flex flex-1 flex-col ">
              <span>{previewDriver.name}</span>
              <span className="text-neutral-dark-gray">{previewDriver.phone}</span>
            </Typography>
            {isEditable && (
              <IconButton onClick={onRemoveDriver} iconName="Trash" variant="custom" iconSize="md" className="ml-3 text-danger" />
            )}
          </figure>
        </div>
      )}
      <Typography variant="paragraph" className="mt-3 leading-loose">
        Search Driver
      </Typography>
      <Autocomplete
        isLoading={isFetching}
        onQueryChange={setQuery}
        onValueChange={handleChange}
        value={selectedDriver}
        options={options}
        nullable={false}
        size="md"
        focused
      >
        <Autocomplete.Options className="mt-2 max-h-72 w-full overflow-auto rounded-md border border-neutral-gray bg-white text-base shadow-lg empty:mt-0">
          {options?.map((i) => (
            <Autocomplete.Option
              key={i.id}
              value={i}
              className={clsx("flex w-full cursor-pointer px-4 py-4 hover:bg-primary-light", {
                "cursor-not-allowed text-neutral-dark-gray active:pointer-events-none": i.error,
              })}
              disabled={!!i.error}
            >
              <div className="flex flex-1 items-center gap-2">
                <Typography className="leading-6">{i.name}</Typography>
                {i.warning && (
                  <Tooltip content={getDriverWarningTooltip(i.warning)} placement="left">
                    <Icon name="Danger" variant="Bold" size="sm" className="text-warning" />
                  </Tooltip>
                )}
              </div>
              <div>
                <Typography variant="action" className="flex items-center gap-2 text-sm text-neutral-dark-gray">
                  {i.error && (
                    <Tooltip content={getDriverErrorTooltip(i.error)} placement="left">
                      <Icon name="InfoCircle" variant="Bold" size="sm" className="text-danger" />
                    </Tooltip>
                  )}{" "}
                  {i.operatorName}
                </Typography>
              </div>
            </Autocomplete.Option>
          ))}
        </Autocomplete.Options>
      </Autocomplete>

      <Button onClick={onSubmit} variant="primary" size="lg" className="mt-4 w-full">
        Update Driver
      </Button>
    </>
  );
};

interface ChangeDriverReasonProps {
  newDriver: BookingDriver;
  onSubmit: (reason: string) => void;
}

const ChangeDriverReason = ({ newDriver, onSubmit }: ChangeDriverReasonProps) => {
  const { driver: prevDriver } = useSelectedBooking();
  if (!prevDriver) throw Error("Cannot change driver without current driver");
  if (!newDriver) throw Error("Cannot change driver without new driver data");

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<{ reason: string }>({
    defaultValues: {
      reason: "",
    },
  });

  const onReasonSubmit = (data: { reason: string }) => onSubmit(data.reason);

  return (
    <>
      <div className="flex gap-2">
        <figure className="mt-3 flex w-full gap-3 rounded bg-neutral-gray px-4 py-3">
          <Avatar src={getAssetUrl(prevDriver.avatar, { height: 64, width: 64 })} alt={prevDriver.name} size="sm" />
          <Typography variant="small" className="flex flex-1 flex-col ">
            <span>{prevDriver.name}</span>
            <span className="text-neutral-dark-gray">{prevDriver.phone}</span>
          </Typography>
        </figure>
        <Icon name="ArrowSwapHorizontal" size="lg" className="mt-8 shrink-0 " />
        <figure className="mt-3 flex w-full gap-3 rounded bg-neutral-gray px-4 py-3">
          <Avatar src={getAssetUrl(newDriver.avatar, { height: 64, width: 64 })} alt={newDriver.name} size="sm" />
          <Typography variant="small" className="flex flex-1 flex-col ">
            <span>{newDriver.name}</span>
            <span className="text-neutral-dark-gray">{newDriver.phone}</span>
          </Typography>
        </figure>
      </div>

      <form onSubmit={handleSubmit(onReasonSubmit)} className="mt-3 flex flex-col ">
        <TextArea {...register("reason")} hasError={!!errors.reason} placeholder="Enter your reason for driver removal" />
        <Typography variant="paragraph" className="mb-4 mt-1 text-neutral-dark-gray">
          This message will be forwarded to the designated driver.
        </Typography>
        <Button type="submit" disabled={!isDirty} variant="primary" size="lg">
          Submit Response
        </Button>
      </form>
    </>
  );
};

const RemoveDriverReason = () => {
  const dispatch = useAppDispatch();
  const { id: bookingId, status, driver: currentDriver } = useSelectedBooking();
  const [updateBooking, { isLoading: isSaving }] = useUpdateBookingMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<{ reason: string }>({
    defaultValues: {
      reason: "",
    },
  });

  const onSubmit = async (data: { reason: string }) =>
    updateBooking({
      bookingId,
      driver_uuid: null,
      driver_reason: data.reason,
    })
      .unwrap()
      .then(() => {
        addBookingNotification(`Job ${bookingId} updated`, "Successfully removed driver", status);
        dispatch(setInlineEditing(undefined));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));

  if (!currentDriver) return null;

  return (
    <>
      <div className="flex gap-2">
        <figure className="mt-3 flex w-full gap-3 rounded bg-neutral-gray px-4 py-3">
          <Avatar src={getAssetUrl(currentDriver.avatar, { height: 64, width: 64 })} alt={currentDriver.name} size="sm" />
          <Typography variant="small" className="flex flex-1 flex-col ">
            <span>{currentDriver.name}</span>
            <span className="text-neutral-dark-gray">{currentDriver.phone}</span>
          </Typography>
        </figure>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-3 flex flex-col ">
        <TextArea {...register("reason")} hasError={!!errors.reason} placeholder="Enter your reason for driver removal" />
        <Typography variant="paragraph" className="mb-4 mt-1 text-neutral-dark-gray">
          This message will be forwarded to the designated driver.
        </Typography>
        <Button type="submit" disabled={!isDirty || isSaving} variant="primary" size="lg">
          {isSaving ? <Spinner /> : "Submit Response"}
        </Button>
      </form>
    </>
  );
};

interface ConfirmDriverPercentageProps {
  reason: string;
  newDriver: BookingDriver;
}

const ConfirmDriverPercentage = ({
  reason,
  newDriver: { id: driverId, name: driverName, recommendedCommissionRate },
}: ConfirmDriverPercentageProps) => {
  const dispatch = useAppDispatch();
  const {
    id: bookingId,
    cost: { percentageToDriver },
    status,
  } = useSelectedBooking();

  const [updateBooking, { isLoading: isSaving }] = useUpdateBookingMutation();

  const handleKeepCurrent = () => {
    updateBooking({
      bookingId,
      driver_uuid: driverId,
      driver_reason: reason,
    })
      .unwrap()
      .then(() => {
        addBookingNotification(`Job ${bookingId} updated`, `Assigned new driver (${driverName})`, status);
        dispatch(setInlineEditing(undefined));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const handleUseRecommendedClick = () => {
    updateBooking({
      bookingId,
      driver_uuid: driverId,
      driver_percentage: recommendedCommissionRate,
    })
      .unwrap()
      .then(() => {
        addBookingNotification(`Job ${bookingId} updated`, `Assigned new driver (${driverName})`, status);
        addNotification("success", "Driver Percentage Updated", `Driver payment percentage changed to ${recommendedCommissionRate}`);
        dispatch(setInlineEditing(undefined));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <div className="relative">
      {isSaving && <Loading />}
      <Typography variant="paragraph" className="mt-3">
        To ensure quick and efficient coverage, RideMinder recommends <span className="text-info">{recommendedCommissionRate}%</span>{" "}
        commission for this job. Please review your percentage below.
      </Typography>
      <div className="mt-3 flex justify-end gap-3">
        <Button variant="secondary" onClick={handleKeepCurrent} disabled={isSaving} className="px-8">
          Keep Current ({percentageToDriver}%)
        </Button>
        <Button variant="primary" onClick={handleUseRecommendedClick} disabled={isSaving} className="px-8">
          Use Recommended ({recommendedCommissionRate}%)
        </Button>
      </div>
    </div>
  );
};
