import { useOperator, usePagination, useSearch, useSorting } from "@/hooks";
import { GetClientsParams } from "@/redux/apis/client/types";
import { useQueryParams } from "use-query-params";
import { ClientsFilterParams } from "./features/Filters/types";

export const useGetClientsParams = () => {
  const { id: operatorId } = useOperator();
  const { page, pageSize } = usePagination("clients");
  const [search, setSearch] = useSearch();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const [filters, setFilters] = useQueryParams(ClientsFilterParams);

  const params: GetClientsParams = {
    page,
    page_size: pageSize,
    sort: sortKey,
    direction,
    search,
    provider_uuid: operatorId,
    company_uuid: filters.company ? filters.company : undefined,
    email: filters.email ? filters.email : undefined,
    phone: filters.phoneNumber ? filters.phoneNumber : undefined,
    account_type: filters.type ? (filters.type.filter(Boolean) as string[]) : undefined,
    account_status: filters.status ? (filters.status.filter(Boolean) as string[]) : undefined,
  };

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    search,
    setSearch,
    operatorId,
    filters, 
    setFilters,
  };
};
