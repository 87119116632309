import { DynamicPricingState, FixedPricingLocation, FixedPricingState } from "@/redux/slices/pricing/types";
import { slugify } from "@/utils";
import {
  GetDynamicPricingStatesRawResponse,
  GetDynamicPricingStatesResponse,
  GetPricingProfilesRawResponse,
  GetPricingProfilesResponse,
  GetFixedPricingStatesRawResponse,
  GetFixedPricingStatesResponse,
  GetNationwideFixedPricingRawResponse,
  GetNationwideFixedPricingResponse,
  GetFixedPricingLocationsRawResponse,
  GetFixedPricingLocationsResponse,
} from "./types";

export const transformDynamicPricingStates = (res: GetDynamicPricingStatesRawResponse) => {
  const {
    _links,
    _embedded: { standard: items },
  } = res;

  const pricingStates: DynamicPricingState[] = items.map((i) => ({
    id: slugify(i.name),
    name: i.name,
    background: i.background ?? "https://via.placeholder.com/800x400/efefef",
    state: i.state,
    countryCode: i.country_code,
    currency: i.currency,
    distanceUnit: i.distance_unit,
    managed: i.managed,
    baseInterval: i.base_interval
      ? {
          minimumRange: i.base_interval.minimum_range,
          maximumRange: i.base_interval.maximum_range,
          cost: i.base_interval.interval_cost / 100,
          note: i.base_interval.note,
        }
      : null,
    intervals: i.intervals.map((i) => ({
      minimumRange: i.minimum_range,
      rate: i.rate_per_distance_unit / 100,
      note: i.note,
    })),
  }));

  const response: GetDynamicPricingStatesResponse = {
    data: { pricingStates },
    links: _links,
  };

  return response;
};

export const transformPricingProfiles = (res: GetPricingProfilesRawResponse) => {
  const {
    _embedded: { pricing_profile: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetPricingProfilesResponse = {
    data: {
      pricingProfiles: items.map((i) => {
        const { uuid, name, valid_from, valid_to, metadata } = i;

        return {
          id: uuid,
          name,
          validFrom: valid_from,
          validTo: valid_to,
          hasLocationPricing: metadata.has_location_pricing,
          hasNationwidePricing: metadata.has_nationwide_pricing,
          hasStatePricing: metadata.has_state_pricing,
        };
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};

export const transformNationwideFixedPricing = (res: GetNationwideFixedPricingRawResponse) => {
  const { uuid, type, country_code, currency, distance_unit, managed, base_interval, intervals, _links } = res;

  const response: GetNationwideFixedPricingResponse = {
    data: {
      nationwidePricing: {
        id: uuid,
        type,
        countryCode: country_code,
        currency,
        distanceUnit: distance_unit,
        managed,
        baseInterval: base_interval
          ? {
              minimumRange: base_interval.minimum_range,
              maximumRange: base_interval.maximum_range,
              intervalCost: base_interval.interval_cost / 100,
              note: base_interval.note,
              managementCostUnit: base_interval.management_cost_unit,
              managementCost:
                base_interval.management_cost_unit === "percentage" ? base_interval.management_cost : base_interval.management_cost / 100,
            }
          : null,
        intervals: intervals.map((interval) => ({
          minimumRange: interval.minimum_range,
          maximumRange: interval.maximum_range,
          intervalCost: interval.interval_cost / 100,
          note: interval.note,
          managementCost: interval.management_cost_unit === "percentage" ? interval.management_cost : interval.management_cost / 100,
          managementCostUnit: interval.management_cost_unit,
          ratePerDistanceUnit: interval.rate_per_distance_unit / 100,
        })),
      },
    },
    links: _links,
  };

  return response;
};

export const transformFixedPricingStates = (res: GetFixedPricingStatesRawResponse) => {
  const {
    _links,
    _embedded: { pricing_profile_pricing: items },
  } = res;

  const pricingStates: FixedPricingState[] = items.map((i) => ({
    id: slugify(i.name),
    name: i.name,
    background: i.background ?? "https://via.placeholder.com/800x400/efefef",
    state: i.state,
    type: i.type,
    countryCode: i.country_code,
    currency: i.currency,
    distanceUnit: i.distance_unit,
    managed: i.managed,
    baseInterval: i.base_interval
      ? {
          minimumRange: i.base_interval.minimum_range,
          maximumRange: i.base_interval.maximum_range,
          intervalCost: i.base_interval.interval_cost / 100,
          note: i.base_interval.note,
          managementCostUnit: i.base_interval.management_cost_unit,
          managementCost:
            i.base_interval.management_cost_unit === "percentage" ? i.base_interval.management_cost : i.base_interval.management_cost / 100,
        }
      : null,
    intervals: i.intervals.map((interval) => ({
      minimumRange: interval.minimum_range,
      maximumRange: interval.maximum_range,
      intervalCost: interval.interval_cost / 100,
      note: interval.note,
      managementCost: interval.management_cost_unit === "percentage" ? interval.management_cost : interval.management_cost / 100,
      managementCostUnit: interval.management_cost_unit,
      ratePerDistanceUnit: interval.rate_per_distance_unit / 100,
    })),
  }));

  const response: GetFixedPricingStatesResponse = {
    data: { pricingStates },
    links: _links,
  };

  return response;
};

export const transformFixedPricingLocations = (res: GetFixedPricingLocationsRawResponse) => {
  const {
    _links,
    _embedded: { pricing_profile_pricing: items },
  } = res;

  const pricingLocations: FixedPricingLocation[] = items.map((i) => ({
    id: i.uuid,
    pickupLocation: {
      name: i.pickup_location.name,
      address: i.pickup_location.address,
      latitude: i.pickup_location.latitude,
      longitude: i.pickup_location.longitude,
      radius: i.pickup_location.radius,
    },
    type: i.type,
    countryCode: i.country_code,
    currency: i.currency,
    distanceUnit: i.distance_unit,
    managed: i.managed,
    baseInterval: i.base_interval
      ? {
          minimumRange: i.base_interval.minimum_range,
          maximumRange: i.base_interval.maximum_range,
          intervalCost: i.base_interval.interval_cost / 100,
          note: i.base_interval.note,
          managementCostUnit: i.base_interval.management_cost_unit,
          managementCost:
            i.base_interval.management_cost_unit === "percentage" ? i.base_interval.management_cost : i.base_interval.management_cost / 100,
        }
      : null,
    intervals: i.intervals.map((interval) => ({
      minimumRange: interval.minimum_range,
      maximumRange: interval.maximum_range,
      intervalCost: interval.interval_cost / 100,
      note: interval.note,
      managementCost: interval.management_cost_unit === "percentage" ? interval.management_cost : interval.management_cost / 100,
      managementCostUnit: interval.management_cost_unit,
      ratePerDistanceUnit: interval.rate_per_distance_unit / 100,
    })),
  }));

  const response: GetFixedPricingLocationsResponse = {
    data: { pricingLocations },
    links: _links,
  };

  return response;
};
