import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddClientFormData } from "../types";

export const EmailField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<AddClientFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Email</Typography>
      <TextInput
        className="w-full"
        hasError={!!errors.email}
        placeholder="clients.name@email.com"
        maxLength={100}
        type="email"
        {...register("email")}
      />
      <ErrorMessage errors={errors} name="email" />
    </div>
  );
};
