import { useClose } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TextInput, Typography } from "@/components/atoms";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useClientsFilters } from "../useClientsFilters";

const schema = z.object({
  phoneNumber: z.string(),
});

type FormData = z.infer<typeof schema>;

export const PhoneNumberFilter = () => {
  const {
    filters: { phoneNumber },
    setFilter,
  } = useClientsFilters();

  const value = useMemo(() => phoneNumber ?? "", [phoneNumber]);

  const onClose = () => setFilter("phoneNumber", undefined);

  return (
    <FilterPopover name="phoneNumber" value={value} label="Phone Number" onClose={onClose}>
      <PhoneNumberFilterForm />
    </FilterPopover>
  );
};

interface PhoneNumberFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const PhoneNumberFilterDrawer = ({ open, onOpenChange }: PhoneNumberFilterDrawerProps) => {
  const {
    filters: { phoneNumber },
    setFilter,
  } = useClientsFilters();

  const value = useMemo(() => phoneNumber ?? "", [phoneNumber]);
  const onClose = () => setFilter("phoneNumber", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Phone Number" onClose={onClose}>
      <PhoneNumberFilterForm />
    </FilterDrawer>
  );
};

const PhoneNumberFilterForm = () => {
  const close = useClose();

  const { setFilter, filters } = useClientsFilters();

  const { watch, register } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { phoneNumber: filters.phoneNumber ? filters.phoneNumber : "" },
  });

  const value = watch("phoneNumber");

  useDebouncedEffect(
    () => {
      setFilter("phoneNumber", value);
    },
    [value],
    300
  );

  return (
    <form
      className="contents"
      onSubmit={(event) => {
        event.preventDefault();
        close();
      }}
    >
      <Typography className="mb-4">Search Phone Number</Typography>
      <TextInput {...register("phoneNumber")} size="md" iconName="SearchNormal1" placeholder="Search Phone Number" />
    </form>
  );
};
