import { createSlice } from "@reduxjs/toolkit";
import { clientApi } from "@/redux/apis";
import { pageState } from "@/redux/types";
import { clientOptionsInitialState, ClientState } from "./types";

const initialState: ClientState = {
  clients: [],
  page: pageState,
  options: clientOptionsInitialState,
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(clientApi.endpoints.getClients.matchFulfilled, (state, { payload }) => {
      const { clients, page } = payload.data;
      state.clients = clients;
      state.page = page;
    });
    builder.addMatcher(clientApi.endpoints.getClientOptions.matchFulfilled, (state, { payload }) => {
      state.options = payload;
    });
  },
});

export default clientSlice.reducer;
