import { useClose } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Autocomplete, DropdownSelectOption, Typography } from "@/components/atoms";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useClientsFilters } from "../useClientsFilters";
import { useLazySearchCompanyQuery } from "@/redux/apis/typeahead/typeaheadApi";

const schema = z.object({
  company: z.string(),
});

type FormData = z.infer<typeof schema>;

export const CompanyFilter = () => {
  const {
    filters: { company },
    setFilter,
  } = useClientsFilters();

  const value = useMemo(() => company ?? "", [company]);

  const onClose = () => setFilter("company", undefined);

  return (
    <FilterPopover name="company" value={value} label="Company" onClose={onClose} containerClassName="overflow-y-visible">
      <CompanyFilterForm />
    </FilterPopover>
  );
};

interface CompanyFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const CompanyFilterDrawer = ({ open, onOpenChange }: CompanyFilterDrawerProps) => {
  const {
    filters: { company },
    setFilter,
  } = useClientsFilters();

  const value = useMemo(() => company ?? "", [company]);
  const onClose = () => setFilter("company", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Company" onClose={onClose}>
      <CompanyFilterForm />
    </FilterDrawer>
  );
};

const CompanyFilterForm = () => {
  const close = useClose();
  const [query, setQuery] = useState("");
  const [searchCompany, { isFetching, data: companies }] = useLazySearchCompanyQuery();
  const { setFilter, filters } = useClientsFilters();
  const { watch, control } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { company: filters.company ? filters.company : "" },
  });
  const value = watch("company");
  const options: DropdownSelectOption[] = companies
    ? companies.map((i) => ({
        value: i.id,
        name: i.name,
      }))
    : [];

  useDebouncedEffect(
    () => {
      if (query.length > 2) searchCompany({ q: query });
    },
    [query, searchCompany],
    500
  );

  useDebouncedEffect(
    () => {
      setFilter("company", value);
    },
    [value],
    300
  );

  return (
    <form
      className="contents"
      onSubmit={(event) => {
        event.preventDefault();
        close();
      }}
    >
      <Typography className="mb-4">Search Company</Typography>
      <Controller
        control={control}
        name="company"
        render={({ field }) => (
          <Autocomplete
            placeholder="Select Company Name"
            options={options}
            isLoading={isFetching}
            value={options.find((i) => i.value === field.value) ?? null}
            onValueChange={(option) => field.onChange(option?.value ?? "")}
            onQueryChange={setQuery}
            size="md"
          />
        )}
      />
    </form>
  );
};
