import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import { useGetApplicationRulesQuery } from "@/redux/apis/driver/driverApi";
import { ApplicationRule } from "./types";

interface IApplicationRulesContext {
  rules: ApplicationRule[];
  draftRule: ApplicationRule | null;
  setDraftRule: (rule: ApplicationRule | null) => void;
  activeRule: ApplicationRule | null;
  setActiveRule: (rule: ApplicationRule | null) => void;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  isFetching: boolean;
}

export const ApplicationRulesContext = createContext<IApplicationRulesContext | null>(null);

export const ApplicationRulesProvider = ({ children }: PropsWithChildren) => {
  const { data, isFetching } = useGetApplicationRulesQuery();

  const [activeRule, setActiveRule] = useState<ApplicationRule | null>(null);
  const [editMode, setEditMode] = useState(false);

  const rules = useMemo(() => {
    if (!data) return [];
    return data as ApplicationRule[];
  }, [data]);

  const [draftRule, setDraftRule] = useState<ApplicationRule | null>(null);

  return (
    <ApplicationRulesContext.Provider
      value={{ rules, draftRule, setDraftRule, editMode, setEditMode, activeRule, setActiveRule, isFetching }}
    >
      {children}
    </ApplicationRulesContext.Provider>
  );
};

export const useApplicationRulesContext = () => {
  const context = useContext(ApplicationRulesContext);
  if (!context) {
    throw new Error("useApplicationRulesContext must be used within an ApplicationRulesProvider ");
  }
  return context;
};
