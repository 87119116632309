import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Skeleton, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useOperator } from "@/hooks";
import { useGetDriverVehiclesQuery } from "@/redux/apis/driver/driverProfileApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { getAssetUrl, getCountryFlagSrc } from "@/utils";
import { useVehiclesContext } from "../context";

export const OwnedVehiclesPanel = () => {
  const { driver } = useVehiclesContext();
  const { data, isFetching } = useGetDriverVehiclesQuery({
    owner_uuid: driver.uuid,
    owned_by: "OWNER",
  });

  const vehicles = useMemo(() => {
    if (!data) return [];

    return data.vehicles;
  }, [data]);

  if (isFetching) return <Skeleton />;
  if (!isFetching && vehicles.length === 0) return <EmptyState title="Owned Vehicles" description="This driver does not own a vehicle" />;

  return (
    <div className="mt-5 space-y-4">
      {vehicles.map((vehicle) => (
        <OwnedVehicle key={vehicle.uuid} vehicle={vehicle} />
      ))}
    </div>
  );
};

const OwnedVehicle = ({ vehicle }: { vehicle: Vehicle }) => {
  const navigate = useNavigate();
  const { id: operatorId } = useOperator();
  const { avatar, model, registrationNumber, year, country, state } = vehicle;

  const onViewClick = () => {
    navigate(`/operator/${operatorId}/vehicles/affiliate-vehicles/${vehicle.uuid}`);
  };

  const renderState = () => {
    const flagSrc = getCountryFlagSrc(country.countryCode);

    return (
      <div className="flex gap-2 ">
        {flagSrc && <img src={flagSrc} className="h-6 w-6 rounded-full object-cover" />}
        <Typography variant="action" className="text-neutral-dark-gray">
          {state.name}
        </Typography>
      </div>
    );
  };

  const renderStatus = () => {
    return null;
    //todo: once approval field is available
    // switch (true) {
    //   case 1:
    //     return (
    //       <Typography className="mt-auto flex items-center gap-2">
    //         <i className="inline-block h-2 w-2 rounded-full bg-success"></i>
    //         Approved
    //       </Typography>
    //     );
    //   case 2:
    //     return (
    //       <Typography className="mt-auto flex items-center gap-2">
    //         <i className="inline-block h-2 w-2 rounded-full bg-danger"></i>
    //         Denied
    //       </Typography>
    //     );

    //   default:
    //     return (
    //       <div className="mt-auto flex items-center gap-2">
    //         <Button variant="secondary" size="sm">
    //           Deny
    //         </Button>
    //         <Button variant="primary" size="sm">
    //           Approve
    //         </Button>
    //       </div>
    //     );
    // }
  };

  return (
    <div className="flex gap-3  rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
      <div className="flex flex-1 flex-col gap-7 md:flex-row">
        <div className="relative self-start">
          <img
            src={getAssetUrl(avatar, {
              width: 200,
              height: 200,
              background: "transparent",
            })}
            alt={model.model}
            className="border-neutral-mid-gray-gray h-[100px] w-[100px] shrink-0 rounded-full border bg-neutral-surface-gray object-contain"
          />
          <div className="border-neutral-mid-gray-gray absolute bottom-0 right-0 flex items-center justify-center rounded-full border bg-neutral-surface-gray p-[7px]">
            <img
              src={getAssetUrl(model.avatar, {
                width: 36,
                height: 36,
                background: "transparent",
                fit: "contain",
              })}
              alt={`${model.make} ${model.model}`}
              className="h-[18px] w-[18px]"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Typography variant="h2">
            {`${model.make} ${model.model}`}
            {year && <span> ({year})</span>}
          </Typography>
          {registrationNumber && (
            <Typography variant="action" className="flex items-center gap-2 text-neutral-dark-gray">
              Number Plate: {registrationNumber}
            </Typography>
          )}
          <div className="mt-1 flex ">{renderState()}</div>
        </div>
      </div>
      <div className="flex flex-col">
        <IconButton onClick={onViewClick} iconName="ExportSquare" variant="custom" className="place-self-end text-primary" />
        {renderStatus()}
      </div>
    </div>
  );
};
