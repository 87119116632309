import { SiteInfo } from "@/redux/slices/config/types";
import { CountryState } from "@/redux/slices/resource/types";
import { GetCountryStatesRawResponse, GetCountryStatesResponse, GetSiteInfoRawResponse } from "./types";

export const transformStates = (res: GetCountryStatesRawResponse) => {
  const states = res._embedded.state.map((i) => {
    const { uuid, country_uuid, name, image_cdn, abbreviation } = i;
    return {
      id: uuid,
      countryId: country_uuid,
      abbreviation,
      imageCdn: image_cdn,
      name,
    } as CountryState;
  });

  const response: GetCountryStatesResponse = {
    data: {
      states,
    },
    links: res._links,
  };

  return response;
};

export const transformSiteInfo = (res: GetSiteInfoRawResponse) => {
  const { domain_key, metadata, url } = res;

  const response: SiteInfo = {
    key: domain_key.toLowerCase(),
    isMultiTenant: metadata.is_multi_tenant,
    apiUrl: url.api,
    url: url.domain,
  };

  return response;
};
