import { matchPath, Outlet, useLocation } from "react-router-dom";
import { Container } from "@/components/atoms";
import { Tabs } from "@/components/molecules";
import { ArchivedExternalSuppliersActionMenu, ExternalSuppliersFilters, ExternalSuppliersFiltersProvider, MyExternalSuppliersActionMenu, PageHeader } from "@/components/organisms";
import { MyExternalSuppliers } from "./MyExternalSuppliers";
import { ArchivedExternalSuppliers } from "./ArchivedExternalSuppliers";

export const ExternalSuppliers = () => {
  const { pathname } = useLocation();

  const pageTabs = [
    {
      name: "External Suppliers",
      to: "my-external-suppliers",
    },
    {
      name: "Archived External Suppliers",
      to: "archived-external-suppliers",
    },
  ];

  const isParentPage = Boolean(
    matchPath({ path: "/external-suppliers/:slug" }, pathname) || matchPath({ path: "operator/:operatorId/external-suppliers/:slug" }, pathname)
  );

  return (
    <ExternalSuppliersFiltersProvider>
      <Container className="w-full">
        <div className="sticky top-0 z-30 w-full bg-neutral-surface-gray">
          <PageHeader 
            title="External Suppliers" 
            skipCrumbs={1} 
            info="This is where you can add any suppliers that could excel your service"
          />
          {isParentPage && (
            <div className="flex w-full gap-2 border-b !border-neutral-mid-gray py-1 max-xl:justify-between lg:py-0">
              <Tabs className="no-scrollbar min-w-0 border-none" tabs={pageTabs} />
              <ActionMenu />
            </div>
          )}
          <ExternalSuppliersFilters.ActiveFilters className="mt-2" />
        </div>
        <Outlet />
      </Container>
    </ExternalSuppliersFiltersProvider>
  );
};

const ActionMenu = () => {
  const { pathname } = useLocation();

  switch (true) {
    case pathname.includes("/external-suppliers/my-external-suppliers"):
      return <MyExternalSuppliersActionMenu />;
    case pathname.includes("/external-suppliers/archived-external-suppliers"):
      return <ArchivedExternalSuppliersActionMenu />;
    default:
      return null;
  }
};

ExternalSuppliers.MyExternalSuppliers = MyExternalSuppliers;
ExternalSuppliers.ArchivedExternalSuppliers = ArchivedExternalSuppliers;
