import { useMemo } from "react";
import { usePagination } from "@/hooks";
import { GetPricingProfilesParams } from "@/redux/apis/config/pricing/tripCost";

export const useGetPricingProfilesParams = () => {
  const { page, pageSize } = usePagination("pricing-profiles");

  const params: GetPricingProfilesParams = useMemo(
    () => ({ page, page_size: pageSize }),
    [page, pageSize]
  );

  return { params };
};
