import { useState } from "react";
import { Skeleton, Button, IconButton, SearchInput, Container } from "@/components/atoms";
import { PageHeaderV2 } from "@/components/organisms";
import { RatingsTable } from "@/components/organisms/RatingManager";
import { RatingsModals } from "@/components/organisms/RatingManager/RatingsModals";
import { useGetRatingsParams } from "@/components/organisms/RatingManager/hooks/useGetRatingsParams";
import { RatingsStep } from "@/components/organisms/RatingManager/types";
import { usePagination } from "@/hooks/usePagination";
import { useGetRatingsQuery } from "@/redux/apis/rating/ratingApi";
import { useAppSelector } from "@/redux/hooks";
import { ratingsSelector } from "@/redux/slices/rating/selectors";
import { RatingItem } from "@/redux/slices/rating/types";
import { clsx } from "@/utils";

export const RatingManager = () => {
  const [selectedRating, setSelectedRating] = useState<RatingItem | undefined>(undefined);
  const { clearPagination } = usePagination();

  const { params, search, setSearch } = useGetRatingsParams();
  const { isFetching } = useGetRatingsQuery(params, { refetchOnMountOrArgChange: true });

  const ratings = useAppSelector(ratingsSelector);
  const isRatingsEmpty = ratings.length < 1 && !search && !isFetching;

  const [step, setStep] = useState<RatingsStep>(undefined);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleSearch = (value?: string) => {
    if (!value) setIsSearchActive(false);

    clearPagination();
    setSearch(value);
  };

  const renderHeaderActions = () => {
    return (
      <div className="flex flex-row items-center">
        <div className="hidden sm:flex sm:flex-row sm:gap-x-1">
          {!search && !isSearchActive ? (
            <div className="flex flex-1 items-center justify-end gap-0 xl:gap-2 ">
              <Button
                className="max-md:[&>span]:hidden"
                variant="tertiary"
                size="sm"
                startIcon="SearchNormal1"
                onClick={() => setIsSearchActive(true)}
              >
                Search
              </Button>
            </div>
          ) : (
            <div className="flex flex-1 items-center justify-end gap-2">
              <SearchInput
                search={search}
                onSearch={handleSearch}
                autoFocus
                placeholder="Search Rating"
                className={clsx("w-full max-w-sm p-0 sm:w-fit [&>input]:transition-size", isSearchActive && "[&>input]:w-[280px]")}
                size="sm"
                onClear={() => setIsSearchActive(false)}
              />
            </div>
          )}
        </div>
        {/* mobile view */}
        <div className="flex flex-row gap-x-1 sm:hidden">
          <IconButton variant="tertiary" size="sm" iconName="SearchNormal1" onClick={() => setIsSearchActive(true)} />
        </div>
      </div>
    );
  };

  return (
    <Container className="mb-12 flex w-full flex-1 flex-col">
      <PageHeaderV2 name="Rating Manager" skipCrumbs={1}>
        {renderHeaderActions()}
      </PageHeaderV2>
      {isFetching ? (
        <Skeleton />
      ) : (
        <div className="mt-2">
          <RatingsTable items={ratings} isEmpty={isRatingsEmpty} setStep={setStep} setSelectedRating={setSelectedRating} />
          <RatingsTable.Pagination />
        </div>
      )}
      <RatingsModals step={step} setStep={setStep} setSelectedRating={setSelectedRating} item={selectedRating} />
    </Container>
  );
};
