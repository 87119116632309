import { ArrayParam, StringParam } from "use-query-params";

export type ClientsFilters = {
  company?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  type?: string[] | null;
  status?: string[] | null;
};

export type GetClientsFilterParams = {
  company?: string;
  email?: string;
  phoneNumber?: string;
  type?: string[];
  account_status?: string[];
};

export const ClientsFilterParams = {
  company: StringParam,
  email: StringParam,
  phoneNumber: StringParam,
  type: ArrayParam,
  status: ArrayParam,
};
