import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { AddExternalSupplierFormData } from "../types";
import { clsx } from "@/utils";
import { useAppSelector } from "@/redux/hooks";
import { externalSuppliersFormOptionsSelector } from "@/redux/slices/externalSupplier/selectors";

export const TypeField = ({ className, ...props }: React.HTMLAttributes<HTMLElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddExternalSupplierFormData>();
  const { types } = useAppSelector(externalSuppliersFormOptionsSelector);

  return (
    <div className={clsx("col-span-3 flex flex-col gap-1 lg:col-span-1", className)} {...props}>
      <Typography variant="paragraph">Type</Typography>
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            options={types}
            placeholder="Select Type"
            value={field.value?.toString()}
            onChange={(value) => field.onChange(value)}
            hasError={!!errors.type}
          />
        )}
      />
      <ErrorMessage errors={errors} name="type" />
    </div>
  );
};
