import { Link } from "react-router-dom";
import { Icon, Tooltip, Typography } from "@/components/atoms";
import { DynamicPricingState, FixedPricingState } from "@/redux/slices/pricing/types";
import { clsx, getAssetUrl } from "@/utils";

interface PricingStateListProps extends React.HTMLAttributes<HTMLUListElement> {
  pricingStates: DynamicPricingState[] | FixedPricingState[];
  actionLabel: string;
}

export const PricingStateList = ({ pricingStates, actionLabel }: PricingStateListProps) => (
  <ul role="list" className="mt-5 grid grid-cols-2 gap-x-5 gap-y-5 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-5">
    {pricingStates.map((state, i) => (
      <li key={i} className="relative overflow-hidden rounded-lg border">
        <Link
          to={state.state}
          className={clsx("group aspect-h-9 aspect-w-16 block w-full", {
            "pointer-events-none [&>img]:opacity-50": state.managed,
          })}
        >
          <img src={getAssetUrl(state.background)} alt={state.name} className="pointer-events-none object-cover" />
          {!state.managed && !state.baseInterval && (
            <div className={clsx("absolute inset-0 flex items-center justify-center bg-neutral-dim/70 text-white")}>
              <Icon name="Add" size="md" className="mr-2 inline-block" />
              <Typography variant="action">{actionLabel}</Typography>
            </div>
          )}
        </Link>
        <div className="flex flex-row items-center gap-2 px-4 py-3">
          <Typography className="flex items-center truncate" variant="action">
            {state.name}{" "}
          </Typography>
          {state.managed && (
            <Tooltip
              content="This pricing is managed by RideMinder. Please contact RideMinder to make changes"
              placement="top"
              maxWidth={320}
            >
              <Icon name="Lock1" variant="Bold" size={20} />
            </Tooltip>
          )}
        </div>
      </li>
    ))}
  </ul>
);
