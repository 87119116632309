import isValidABN from "is-valid-abn";
import { z } from "zod";

export const addExternalSupplierFormSchema = z.object({
  name: z
    .string()
    .min(2, "Minimum of 2 characters.")
    .max(100, "Maximum of 100 characters"),
  address: z.string().min(1, "Field is required and can't be empty").max(255, "Maximum of 255 characters."),
  type: z.string().min(1, "Field is required and can't be empty.").max(255, "Maximum of 255 characters"),
  state: z.string().min(1, "Field is required and can't be empty."),
  contactNumber: z
    .string({ description: "Please enter a valid phone number" })
    .min(1, "Field is required and can't be empty.")
    .max(14, "Maximum of 14 characters"),
  email: z
    .string()
    .email("This is not a valid email.")
    .min(2, "Minimum of 2 characters."),
  keyContactName: z.string().min(1, "Field is required and can't be empty.").max(50, "Maximum of 50 characters"),
  abn: z.string().optional(),
  isGstRegistered: z.boolean(),
  regionAu: z.boolean(),
}).refine((data) => !(data.regionAu && data.isGstRegistered && (!data.abn || !isValidABN(data.abn))), {
    message: "Field must be a valid ABN.",
    path: ["abn"],
});

