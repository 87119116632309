import { format } from "date-fns";
import Confetti from "@/assets/images/signup/confetti.svg";
import { Button, Icon, Modal, Tooltip, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { clsx, getAssetUrl, getCountryFlagSrc } from "@/utils";

interface ConfirmVerificationChangeModalProps extends React.HTMLAttributes<HTMLDivElement> {
  oldVehicle?: Vehicle;
  newVehicle?: Vehicle;
  open: boolean;
  onClose: () => void;
}

export const ConfirmVerificationChangeModal = ({
  oldVehicle,
  newVehicle,
  open,
  onClose,
  ...props
}: ConfirmVerificationChangeModalProps) => {
  const { dateFormat } = useProfile();

  if (!newVehicle || !oldVehicle) return null;

  const renderStateFlag = () => {
    const flagSrc = getCountryFlagSrc(newVehicle.country.countryCode);

    return (
      <div className="mr-1 flex items-center gap-3">
        {flagSrc && <img src={flagSrc} className="h-[18px] w-[18px] rounded-full object-cover" />}
      </div>
    );
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className="!max-w-[550px] !p-5">
        <div className="relative" {...props}>
          <div className="mt-5 flex">
            <Typography variant="title" className="flex-1">
              Vehicle Successfully Registered
            </Typography>
            <div className="flex justify-end">
              <Icon name="close" isCustom className="flex cursor-pointer" onClick={onClose} />
            </div>
          </div>

          <div className="mt-3 flex items-center">
            <img src={Confetti} alt="Confetti" className="h-16 w-16" />
            <Typography variant="paragraph" className="mt-2 text-neutral-dark-gray">
              This vehicle has been successfully updated and verified. It can accept network jobs
            </Typography>
          </div>

          <div className="rounded-lg bg-neutral-surface-gray">
            <div className="mt-3 flex items-center gap-3 border-b border-neutral-mid-gray p-3">
              <div className="flex items-center justify-center rounded-full border border-solid border-neutral-mid-gray p-2">
                <img
                  src={getAssetUrl(newVehicle.avatar, { height: 56, width: 56, background: "transparent", fit: "contain" })}
                  className="h-[28px] w-[28px] rounded-full"
                />
              </div>
              <div className="flex flex-1 flex-col">
                <Typography variant="title" className="text-neutral-black">
                  {newVehicle.model.make} {newVehicle.model.model}
                </Typography>

                <Typography variant="action" className="flex items-center gap-1 text-neutral-dark-gray">
                  Number Plate:{" "}
                  {oldVehicle.registrationNumber !== newVehicle.registrationNumber ? (
                    <ChangedField
                      className="text-neutral-dark-gray"
                      oldValue={oldVehicle.registrationNumber}
                      newValue={newVehicle.registrationNumber}
                    />
                  ) : (
                    newVehicle.registrationNumber
                  )}
                </Typography>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3 p-3">
              <div className="col-span-1 flex items-center">
                <Typography className="flex items-center text-neutral-dark-gray">Year</Typography>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Typography className="inline-block capitalize text-neutral-black">
                  {oldVehicle.year && newVehicle.year && oldVehicle.year !== newVehicle.year ? (
                    <ChangedField oldValue={oldVehicle.year} newValue={newVehicle.year} />
                  ) : (
                    newVehicle.year
                  )}
                </Typography>
              </div>
              <div className="col-span-1 flex items-center">
                <Typography className="flex items-center text-neutral-dark-gray">Color</Typography>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Typography className="inline-flex items-center capitalize text-neutral-black">
                  {oldVehicle.color && newVehicle.color && oldVehicle.color !== newVehicle.color ? (
                    <ChangedField
                      oldValue={oldVehicle.color.toLowerCase().replace("_", "")}
                      newValue={newVehicle.color.toLowerCase().replace("_", "")}
                    >
                      <div
                        className="ml-2 h-3 w-3 rounded-full border border-black"
                        style={{ backgroundColor: newVehicle.color ? newVehicle.color : "" }}
                      />
                    </ChangedField>
                  ) : (
                    <>
                      {newVehicle.color?.toLowerCase() ?? "-"}
                      <div
                        className="ml-2 h-3 w-3 rounded-full border border-black"
                        style={{ backgroundColor: newVehicle.color ? newVehicle.color : "" }}
                      />
                    </>
                  )}
                </Typography>
              </div>

              <div className="col-span-1 flex items-center">
                <Typography className="flex items-center text-neutral-dark-gray">Engine Type</Typography>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Typography className="inline-block capitalize text-neutral-black">
                  {oldVehicle.model.engine !== newVehicle.model.engine ? (
                    <ChangedField oldValue={oldVehicle.model.engine.toLowerCase()} newValue={newVehicle.model.engine.toLowerCase()} />
                  ) : (
                    newVehicle.model.engine.toLowerCase()
                  )}
                </Typography>
              </div>

              <div className="col-span-1 flex items-center">
                <Typography className="flex items-center text-neutral-dark-gray">VIN number</Typography>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Typography className="inline-block capitalize text-neutral-black">
                  {oldVehicle.vin && newVehicle.vin && oldVehicle.vin !== newVehicle.vin ? (
                    <ChangedField oldValue={oldVehicle.vin} newValue={newVehicle.vin} />
                  ) : (
                    newVehicle.vin
                  )}
                </Typography>
              </div>
              <div className="col-span-1 flex items-center">
                <Typography className="flex items-center text-neutral-dark-gray">State</Typography>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Typography className="inline-flex gap-1 capitalize text-neutral-black">
                  {newVehicle.state.uuid !== oldVehicle.state.uuid ? (
                    <ChangedField className="flex-row-reverse" oldValue={oldVehicle.state.name} newValue={newVehicle.state.name}>
                      {renderStateFlag()}
                    </ChangedField>
                  ) : (
                    newVehicle.state.name
                  )}
                </Typography>
              </div>
              {oldVehicle.registration?.expiryDate && newVehicle.registration?.expiryDate && (
                <>
                  <div className="col-span-1 flex items-center">
                    <Typography className="flex items-center text-neutral-dark-gray">Registration Expiry</Typography>
                  </div>
                  <div className="col-span-1 flex items-center justify-end">
                    <Typography className="inline-block capitalize text-neutral-black">
                      {oldVehicle.registration?.expiryDate !== newVehicle.registration?.expiryDate ? (
                        <ChangedField
                          oldValue={format(new Date(oldVehicle.registration?.expiryDate), dateFormat)}
                          newValue={format(new Date(newVehicle.registration?.expiryDate), dateFormat)}
                        />
                      ) : (
                        newVehicle.registration?.expiryDate
                      )}
                    </Typography>
                  </div>
                </>
              )}

              <div className="col-span-1 flex items-center">
                <Typography className="flex items-center text-neutral-dark-gray">
                  Network Eligibility
                  <Tooltip
                    interactive
                    content={
                      <div className="max-w-[295px] font-medium">
                        This helps the system match your vehicle accurately within the platform and with external networks, ensuring proper job allocations.
                      </div>
                    }
                    placement="top"
                  >
                    <Icon name="InfoCircle" variant="Bold" className="ml-1 inline-block -rotate-180 text-neutral-dark-gray" size="sm" />
                  </Tooltip>
                </Typography>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Typography className="inline-block capitalize text-neutral-black">
                  {oldVehicle.model.class !== newVehicle.model.class ? (
                    <ChangedField oldValue={oldVehicle.model.class.toLowerCase()} newValue={newVehicle.model.class.toLowerCase()} />
                  ) : (
                    newVehicle.model.class.toLowerCase()
                  )}
                </Typography>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <Typography variant="small" className="my-3 w-full text-center text-neutral-dark-gray">
              If the information is incorrect, please email <a href="mailto:support@rideminder.com">support@rideminder.com</a>
            </Typography>
            <Button variant="secondary" onClick={onClose} size="lg" className="w-full">
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const ChangedField = ({
  oldValue,
  newValue,
  className,
  children,
}: {
  oldValue: string | number;
  newValue: string | number;
  className?: string;
  children?: React.ReactNode;
}) => {
  const tooltip = `From "${oldValue}" to "${newValue}"`;
  return (
    <div className={clsx("inline-flex items-center rounded-sm bg-warning px-1 capitalize text-neutral-black", className)}>
      <Tooltip content={tooltip} placement="top">
        <Typography>{newValue}</Typography>
      </Tooltip>
      {children}
    </div>
  );
};
