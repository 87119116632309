import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Icon, OTPInput, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLayout, useProfile } from "@/hooks";
import { useLazyVerifyEmailRequestQuery, useVerifyEmailMutation } from "@/redux/apis/profile/profileApi";
import { addToast } from "@/utils";

type Step = "verify" | "sent" | "success";

interface VerificationState {
  step: Step;
  setStep: (step: Step) => void;
}

const VerificationContext = createContext<VerificationState>({} as VerificationState);

export const ProfileEmailVerificationForm = () => {
  const [step, setStep] = useState<Step>("verify");

  const renderSteps = () => {
    switch (step) {
      case "sent":
        return <Sent />;

      case "success":
        return <Success />;

      default:
        return <Verify />;
    }
  };

  return (
    <div className="flex min-h-full max-w-lg flex-1 flex-col items-center justify-center px-8">
      <VerificationContext.Provider value={{ setStep, step }}>{renderSteps()}</VerificationContext.Provider>
    </div>
  );
};

const Verify = () => {
  const profile = useProfile();
  const { setFullScreen } = useLayout();
  const navigate = useNavigate();
  const { setStep } = useContext(VerificationContext);
  const [requestEmailVerification, { isFetching }] = useLazyVerifyEmailRequestQuery();

  const handleBack = () => {
    setFullScreen(false);
    navigate("../../profile");
  };

  const handleClick = () => {
    requestEmailVerification()
      .unwrap()
      .then(() => setStep("sent"));
  };

  return (
    <div className="mt-8 flex flex-col items-center gap-3 text-center">
      <Icon name="envelope" isCustom size={128} className="inline" />
      <Typography variant="h1" className="-mt-4">
        Verify your email address
      </Typography>
      <Typography variant="paragraph" className="mb-4 mt-2 max-w-md text-center text-neutral-dark-gray">
        You&apos;re using {profile.email} for your email. Please verify this email address by clicking the button below
      </Typography>
      <Button variant="primary" size="lg" onClick={handleClick} disabled={isFetching}>
        {isFetching ? <Spinner /> : "Verify your email"}
      </Button>
      <Button variant="tertiary" size="lg" onClick={handleBack} disabled={isFetching}>
        <Icon name="ArrowLeft" className="mr-2 inline" />
        Back to Profile
      </Button>
    </div>
  );
};

const Sent = () => {
  const profile = useProfile();
  const { setStep } = useContext(VerificationContext);
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const [otp, setOtp] = useState("");

  const handleClick = () => {
    if (!otp) return;

    verifyEmail(otp)
      .unwrap()
      .then(() => setStep("success"))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <div className="mt-8 flex flex-col items-center gap-3 text-center">
      <Icon name="envelope-sent" isCustom size={128} className="inline" />
      <Typography variant="h1" className="-mt-4">
        Check your email
      </Typography>
      <Typography variant="paragraph" className="mb-2 mt-2 max-w-sm text-center text-neutral-dark-gray">
        We sent a verification link to {profile.email}
      </Typography>
      <OTPInput value={otp} onChange={setOtp} className="mb-4" />
      <Button variant="primary" size="lg" onClick={handleClick} disabled={isLoading} className="w-full">
        {isLoading ? <Spinner /> : "Verify your email"}
      </Button>
      <Typography className="mt-4">
        Didn&apos;t receive the email?{" "}
        <span className="cursor-pointer text-info" onClick={() => setStep("verify")}>
          Click to resend
        </span>
      </Typography>
    </div>
  );
};

const Success = () => {
  const { setFullScreen } = useLayout();
  const navigate = useNavigate();

  const handleBack = () => {
    setFullScreen(false);
    navigate("../../profile?updated=1");
  };

  return (
    <div className="mt-8 flex flex-col items-center gap-3 text-center">
      <Icon name="envelope-verified" isCustom size={128} className="inline" />
      <Typography variant="h1" className="-mt-4">
        Email Verified
      </Typography>
      <Typography variant="paragraph" className="mb-4 mt-2 max-w-md text-center text-neutral-dark-gray">
        Your account has been fully verified. You can now use the full access of the Rideminder Web App.
      </Typography>
      <Button variant="primary" size="lg" onClick={handleBack} className="!w-full">
        Continue to Profile
      </Button>
    </div>
  );
};
