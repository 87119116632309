import { Radio, RadioGroup } from "@headlessui/react";
import { clsx } from "@/utils";

export interface RadioButtonGroupProps extends React.ComponentProps<typeof RadioGroup> {
  children: React.ReactNode;
}

export const RadioButtonGroup = ({ value, onChange, className, children, ...props }: RadioButtonGroupProps) => {
  return (
    <fieldset>
      <RadioGroup value={value} onChange={onChange} className={clsx("flex items-center gap-3", className)} {...props}>
        {children}
      </RadioGroup>
    </fieldset>
  );
};

export interface RadioButtonOptionProps extends React.ComponentProps<typeof Radio> {
  name: string;
}

export const RadioButtonOption = ({ name, value, disabled, className, ...props }: RadioButtonOptionProps) => {
  return (
    <Radio
      value={value}
      disabled={disabled}
      className={clsx(
        !disabled ? "cursor-pointer focus:outline-none" : "cursor-not-allowed opacity-25",
        "flex items-center justify-center rounded-lg bg-neutral-gray px-6 py-4 text-base font-semibold leading-none text-neutral-black ring-1 ring-neutral-gray hover:bg-primary-light data-[checked]:bg-primary-light data-[checked]:text-primary data-[checked]:ring-1 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[checked]:ring-primary data-[focus]:ring-primary data-[focus]:ring-offset-2 data-[checked]:hover:bg-primary-light sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset",
        className
      )}
      {...props}
    >
      {name}
    </Radio>
  );
};

RadioButtonGroup.Option = RadioButtonOption;
