import { Panel } from "@/components/atoms";
import { DriverNavigation } from "../../../common/DriverNavigation";
import { PendingDriverAccountDetails } from "../PendingDriver/sections/PendingDriverAccountDetails";
import { PendingDriverDetails } from "../PendingDriver/sections/PendingDriverDetails";
import { PendingDriverHeader } from "../PendingDriver/sections/PendingDriverHeader";
import { useProfileContext } from "../context";
import { ArchivedDriverStatus } from "./sections/ArchivedDriverStatus";
import { CustomApplicationAnswers } from "../ApprovedDriver/sections/CustomApplicationAnswers";

export const ArchivedDriverPanel = () => {
  const { driver } = useProfileContext();

  return (
    <div className="flex gap-3">
      <DriverNavigation driver={driver} />
      <Panel>
        <PendingDriverHeader />
        <ArchivedDriverStatus />
        <CustomApplicationAnswers />
        <PendingDriverAccountDetails />
        <PendingDriverDetails />
      </Panel>
    </div>
  );
};
