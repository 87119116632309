import { AffiliateVehiclesPanel } from "@/components/organisms";
import { EditVehicle } from "./subpages/EditVehicle";
import { ViewVehicle } from "./subpages/ViewVehicle";

export const AffiliateVehicles = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <AffiliateVehiclesPanel />
    </div>
  );
};

AffiliateVehicles.ViewVehicle = ViewVehicle;
AffiliateVehicles.EditVehicle = EditVehicle;
