import { useMemo } from "react";
import { DataTable, Pagination } from "@/components/molecules";
import { useOperator, usePagination, useSorting } from "@/hooks";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { Page } from "@/redux/types";
import { affiliateVehiclesColumns } from "./fixtures";
import { generateAffiliateVehicleTableData } from "./helpers";

export interface AffiliateVehiclesTableProps {
  items: Vehicle[];
}

export const AffiliateVehiclesTable = ({ items }: AffiliateVehiclesTableProps) => {
  const { id: operatorId } = useOperator();
  const { sorting, setSorting } = useSorting();

  const data = useMemo(() => generateAffiliateVehicleTableData(operatorId, items), [items, operatorId]);

  return (
    <DataTable
      className="w-full overflow-x-auto [&_table]:table-auto [&_tr]:bg-white"
      data={data}
      columns={affiliateVehiclesColumns}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
};

const AffiliateVehiclesTablePagination = ({ page }: { page: Page }) => {
  const { current, size, total, count } = page;
  const { handlePageClick, handleSizeChange } = usePagination("affiliate-vehicles", 30);

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

AffiliateVehiclesTable.Pagination = AffiliateVehiclesTablePagination;
