import { useCallback, useMemo } from "react";
import { DataTable, NoSearchResults, Pagination } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { MyExternalSuppliersEmptyState } from "./components/MyExternalSuppliersEmptyState";
import { generateMyExternalSuppliersTableColumns, generateMyExternalSuppliersTableData } from "./helpers";
import { useGetExternalSuppliersParams } from "./useGetExternalSuppliersParams";
import { ExternalSupplier } from "@/redux/slices/externalSupplier/types";
import { externalSuppliersPageSelector } from "@/redux/slices/externalSupplier/selectors";
import { getPhpHostUrl } from "@/helpers/apiHelpers";

interface MyExternalSuppliersTableProps {
  items: ExternalSupplier[];
}

export const MyExternalSuppliersTable = ({ items }: MyExternalSuppliersTableProps) => {
  const { sorting, setSorting, search, filters } = useGetExternalSuppliersParams();
  const isFiltersEmpty = Object.values(filters).every((el) => el === undefined);

  const renderEmptyState = () => {
    if (search) return <NoSearchResults />;
    if (!isFiltersEmpty) return <MyExternalSuppliersEmptyState hasFilters />;

    return <MyExternalSuppliersEmptyState />;
  };

  const onManageClick = useCallback((id: string) => window.location.replace(`${getPhpHostUrl()}/driver/${id}/profile/`), []);

  const columns = useMemo(() => generateMyExternalSuppliersTableColumns(), []);
  const data = useMemo(
    () => generateMyExternalSuppliersTableData(items, onManageClick),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  return (
    <div className="relative">
      <DataTable
        className="w-full overflow-x-auto [&_table]:table-auto [&_tr]:bg-white"
        data={data}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={renderEmptyState()}
      />
    </div>
  );
};

const MyExternalSuppliersTablePagination = () => {
  const { current, size, total, count } = useAppSelector(externalSuppliersPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("my-external-suppliers");

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

MyExternalSuppliersTable.Pagination = MyExternalSuppliersTablePagination;
