import { useLocalStorageValue } from "@react-hookz/web";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DecodedValueMap, useQueryParams } from "use-query-params";
import { useOperator, usePagination } from "@/hooks";
import { GetClientsFilterParams, ClientsFilters, ClientsFilterParams } from "./types";

export type ClientsFilterKey = keyof Omit<ClientsFilters, "search">;
export type ClientsFilterValue = string | boolean | (string | null)[] | null | undefined;
export type ClientsDecodedFilters = DecodedValueMap<typeof ClientsFilterParams>;

const DEFAULT_FILTERS: Record<ClientsFilterKey, undefined> = {
  company: undefined,
  email: undefined,
  phoneNumber: undefined,
  type: undefined,
  status: undefined,
};

interface IClientsFiltersContext {
  params: GetClientsFilterParams;
  sortedFilters: [ClientsFilterKey, ClientsFilterValue][];
  filters: ClientsDecodedFilters;
  setFilter: (key: ClientsFilterKey, value?: ClientsFilterValue) => void;
  setFilters: (newFilters: Record<ClientsFilterKey, ClientsFilterValue>) => void;
  clearFilters: () => void;
  resetFilters: () => void;
}

const ClientsFiltersContext = createContext<IClientsFiltersContext | null>(null);

export const ClientsFiltersProvider = ({ children }: React.PropsWithChildren) => {
  const { id: operatorId } = useOperator();
  const { clearPagination } = usePagination("clients");
  const [filters, setFilters] = useQueryParams(ClientsFilterParams);
  const { set: setPersistedFilters, value: persistedFilters } = useLocalStorageValue<ClientsFilters>(`${operatorId}:clients-filter`, {
    defaultValue: DEFAULT_FILTERS,
  });
  const [filterSorting, setFilterSorting] = useState<ClientsFilterKey[]>([]);

  useEffect(() => {
    if (!persistedFilters || !!filters) return;
    setFilters(persistedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedFilters = useMemo(() => {
    const mergedFilters = Object.keys(filters).reduce((acc, key) => {
      if (!filterSorting.includes(key as ClientsFilterKey) && filters[key as ClientsFilterKey] !== undefined) {
        acc.push(key as ClientsFilterKey);
      }
      return acc;
    }, filterSorting);

    return (mergedFilters || []).map((key) => [key, filters[key]] as const);
  }, [filterSorting, filters]);

  const params: GetClientsFilterParams = useMemo(
    () => ({
      company: filters.company ?? undefined,
      email: filters.email ?? undefined,
      phoneNumber: filters.phoneNumber ?? undefined,
      type: filters.type ? (filters.type.filter(Boolean) as string[]) : undefined,
      account_status: filters.status ? (filters.status.filter(Boolean) as string[]) : undefined,
    }),
    [filters]
  );

  const clearFilters = useCallback(() => {
    clearPagination();
    const clearedFilters = Object.entries(filters)
      .filter(([_, value]) => value !== undefined)
      .reduce((acc, [key]) => {
        acc[key as ClientsFilterKey] = null;
        return acc;
      }, {} as ClientsFilters);

    setFilters(clearedFilters);
    setPersistedFilters(clearedFilters);
  }, [clearPagination, filters, setFilters, setPersistedFilters]);

  const resetFilters = useCallback(() => {
    clearPagination();
    setFilterSorting([]);
    setFilters(DEFAULT_FILTERS);
    setPersistedFilters(DEFAULT_FILTERS);
  }, [clearPagination, setFilterSorting, setFilters, setPersistedFilters]);

  const setFilter = useCallback(
    (key: ClientsFilterKey, value?: ClientsFilterValue) => {
      if (value) {
        clearPagination();
      }

      setFilters((prev) => ({ ...prev, [key]: value }));
      setPersistedFilters((prev) => ({ ...prev, [key]: value }));
      setFilterSorting((prev = []) => {
        if (value === undefined) {
          return prev.filter((i) => i !== key);
        }
        return prev.includes(key) ? prev : [...prev, key];
      });
    },
    [setFilters, setPersistedFilters, setFilterSorting, clearPagination]
  );

  const setAllFilters = useCallback(
    (newFilters: Record<ClientsFilterKey, ClientsFilterValue>) => {
      clearPagination();
      setFilters(newFilters as ClientsFilters);
      setPersistedFilters(newFilters as ClientsFilters);
      setFilterSorting(Object.keys(newFilters) as ClientsFilterKey[]);
    },
    [setFilters, setPersistedFilters, setFilterSorting, clearPagination]
  );

  const value = {
    params,
    sortedFilters: sortedFilters as [ClientsFilterKey,ClientsFilterValue][],
    filters,
    setFilter,
    setFilters: setAllFilters,
    clearFilters,
    resetFilters,
  };

  return <ClientsFiltersContext.Provider value={value}>{children}</ClientsFiltersContext.Provider>;
};

export const useClientsFilters = () => {
  const context = useContext(ClientsFiltersContext);
  if (!context) {
    throw new Error("useClientsFilters must be used within a ClientsFiltersProvider");
  }

  return context;
};
