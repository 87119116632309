import { Icon, Skeleton, Typography } from "@/components/atoms";
import { DocumentList } from "@/components/molecules";
import { useOperator, usePage } from "@/hooks";
import { useGetOtherLinksQuery } from "@/redux/apis/config/linksAndDocuments";
import { useAppSelector } from "@/redux/hooks";
import { documentsSelector } from "@/redux/slices/documents/selectors";
import { TermsAndConditions } from "./TermsAndConditions/TermsAndConditions";

export const Documents = () => {
  const { name } = useOperator();
  const { pathname } = usePage();
  const { isFetching } = useGetOtherLinksQuery();
  const { otherLinks } = useAppSelector(documentsSelector);
  const customLinks = ["client_service_agreement", "driver_service_agreement"]
  const filteredOtherLinks = otherLinks.filter(link => !customLinks.includes(link.document.slug));
  const driverServiceAgreement = otherLinks.find(link => link.document.slug === "driver_service_agreement");
  const clientServiceAgreement = otherLinks.find(link => link.document.slug === "client_service_agreement");

  if (isFetching) return <Skeleton />;

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-6">
        <Typography variant="h3">Documents</Typography>
        <DocumentList>
          <DocumentList.Item
            link={`/dashboard${pathname}/terms-and-conditions`}
            title={`${name} Terms and Conditions`}
            subtext="Terms & Conditions"
            icon={<Icon name="Book" size="sm" />}
          />
          {filteredOtherLinks.map((i) => (
            <DocumentList.Item
              key={i.id}
              link={i.document.link}
              title={i.document.name}
              subtext={i.document.description}
              icon={i.icon !== "document" ? <Icon name={i.icon} size="sm" isCustom /> : <Icon name="Book" size="sm" />}
            />
          ))}
        </DocumentList>
      </div>
      <div className="flex flex-col gap-6">
        <Typography variant="h3">Custom Service Agreements</Typography>
        <DocumentList>
          <DocumentList.CustomItem
            id={driverServiceAgreement?.id}
            document={driverServiceAgreement?.document}
            title="Driver Service Agreement"
            documentType="driver_service_agreement"
            icon={<Icon name="Book" size="sm" />}
          />
          <DocumentList.CustomItem
            id={clientServiceAgreement?.id}
            document={clientServiceAgreement?.document}
            title="Client Service Agreement"
            documentType="client_service_agreement"
            icon={<Icon name="Book" size="sm" />}
          />
        </DocumentList>
      </div>
    </div>
  );
};

Documents.TermsAndConditions = TermsAndConditions;
