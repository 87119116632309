import { useDebouncedEffect } from "@react-hookz/web";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, DropdownSelectOption, ErrorMessage, Typography } from "@/components/atoms";
import { useLazySearchCompanyQuery } from "@/redux/apis/typeahead/typeaheadApi";
import { clsx } from "@/utils";
import { AddClientFormData } from "../types";

export const CompanyField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const [searchCompany, { isFetching, data: companies }] = useLazySearchCompanyQuery();
  const {
    control,
    formState: { errors },
  } = useFormContext<AddClientFormData>();
  const [query, setQuery] = useState("");

  useDebouncedEffect(
    () => {
      if (query.length > 2) searchCompany({ q: query });
    },
    [query, searchCompany],
    500
  );

  const options: DropdownSelectOption[] = companies
    ? companies.map((i) => ({
        value: i.id,
        name: i.name,
      }))
    : [];

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Company (Optional)</Typography>
      <Controller
        control={control}
        name="company"
        render={({ field }) => (
          <Autocomplete
            options={options}
            placeholder="Search Company"
            isLoading={isFetching}
            value={options.find((i) => i.value === field.value) ?? null}
            onValueChange={(option) => field.onChange(option?.value ?? "")}
            onQueryChange={setQuery}
            hasError={!!errors.company}
          />
        )}
      />
      <ErrorMessage errors={errors} name="company" />
    </div>
  );
};
