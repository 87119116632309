import { useLocalStorageValue } from "@react-hookz/web";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ArrayParam, DecodedValueMap, useQueryParams } from "use-query-params";
import { useOperator, usePagination } from "@/hooks";
import { GetExternalSuppliersFilterParams, ExternalSuppliersFilters } from "./types";

export type ExternalSuppliersFilterKey = keyof Omit<ExternalSuppliersFilters, "search">;
export type ExternalSuppliersFilterValue = string | boolean | (string | null)[] | null | undefined;
export type ExternalSuppliersDecodedFilters = DecodedValueMap<typeof externalSuppliersFilterParams>;

const DEFAULT_FILTERS: Record<ExternalSuppliersFilterKey, undefined> = {
  type: undefined,
  state: undefined,
  rating: undefined,
  status: undefined,
};

const externalSuppliersFilterParams = {
  type: ArrayParam,
  state: ArrayParam,
  rating: ArrayParam,
  status: ArrayParam,
};

interface IExternalSuppliersFiltersContext {
  params: GetExternalSuppliersFilterParams;
  sortedFilters: [ExternalSuppliersFilterKey, ExternalSuppliersFilterValue][];
  filters: ExternalSuppliersDecodedFilters;
  setFilter: (key: ExternalSuppliersFilterKey, value?: ExternalSuppliersFilterValue) => void;
  setFilters: (newFilters: Record<ExternalSuppliersFilterKey, ExternalSuppliersFilterValue>) => void;
  clearFilters: () => void;
  resetFilters: () => void;
}

const ExternalSuppliersFiltersContext = createContext<IExternalSuppliersFiltersContext | null>(null);

export const ExternalSuppliersFiltersProvider = ({ children }: React.PropsWithChildren) => {
  const { id: operatorId } = useOperator();
  const { clearPagination } = usePagination("my-external-suppliers");
  const [filters, setFilters] = useQueryParams(externalSuppliersFilterParams);
  const { set: setPersistedFilters, value: persistedFilters } = useLocalStorageValue<ExternalSuppliersFilters>(`${operatorId}:external-suppliers-filter`, {
    defaultValue: DEFAULT_FILTERS,
  });
  const [filterSorting, setFilterSorting] = useState<ExternalSuppliersFilterKey[]>([]);

  useEffect(() => {
    if (!persistedFilters || !!filters) return;
    setFilters(persistedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedFilters = useMemo(() => {
    const mergedFilters = Object.keys(filters).reduce((acc, key) => {
      if (!filterSorting.includes(key as ExternalSuppliersFilterKey) && filters[key as ExternalSuppliersFilterKey] !== undefined) {
        acc.push(key as ExternalSuppliersFilterKey);
      }
      return acc;
    }, filterSorting);

    return (mergedFilters || []).map((key) => [key, filters[key]] as const);
  }, [filterSorting, filters]);

  const params: GetExternalSuppliersFilterParams = useMemo(
    () => ({
      type: filters.type ? (filters.type.filter(Boolean) as string[]) : undefined,
      state: filters.state ? (filters.state.filter(Boolean) as string[]) : undefined,
      rating: filters.rating ? (filters.rating.filter(Boolean) as string[]) : undefined,
      account_status: filters.status ? (filters.status.filter(Boolean) as string[]) : undefined,
    }),
    [filters]
  );

  const clearFilters = useCallback(() => {
    clearPagination();
    const clearedFilters = Object.entries(filters)
      .filter(([_, value]) => value !== undefined)
      .reduce((acc, [key]) => {
        acc[key as ExternalSuppliersFilterKey] = null;
        return acc;
      }, {} as ExternalSuppliersFilters);

    setFilters(clearedFilters);
    setPersistedFilters(clearedFilters);
  }, [clearPagination, filters, setFilters, setPersistedFilters]);

  const resetFilters = useCallback(() => {
    clearPagination();
    setFilterSorting([]);
    setFilters(DEFAULT_FILTERS);
    setPersistedFilters(DEFAULT_FILTERS);
  }, [clearPagination, setFilterSorting, setFilters, setPersistedFilters]);

  const setFilter = useCallback(
    (key: ExternalSuppliersFilterKey, value?: ExternalSuppliersFilterValue) => {
      if (value) {
        clearPagination();
      }

      setFilters((prev) => ({ ...prev, [key]: value }));
      setPersistedFilters((prev) => ({ ...prev, [key]: value }));
      setFilterSorting((prev = []) => {
        if (value === undefined) {
          return prev.filter((i) => i !== key);
        }
        return prev.includes(key) ? prev : [...prev, key];
      });
    },
    [setFilters, setPersistedFilters, setFilterSorting, clearPagination]
  );

  const setAllFilters = useCallback(
    (newFilters: Record<ExternalSuppliersFilterKey, ExternalSuppliersFilterValue>) => {
      clearPagination();
      setFilters(newFilters as ExternalSuppliersFilters);
      setPersistedFilters(newFilters as ExternalSuppliersFilters);
      setFilterSorting(Object.keys(newFilters) as ExternalSuppliersFilterKey[]);
    },
    [setFilters, setPersistedFilters, setFilterSorting, clearPagination]
  );

  const value = {
    params,
    sortedFilters: sortedFilters as [ExternalSuppliersFilterKey, ExternalSuppliersFilterValue][],
    filters,
    setFilter,
    setFilters: setAllFilters,
    clearFilters,
    resetFilters,
  };

  return <ExternalSuppliersFiltersContext.Provider value={value}>{children}</ExternalSuppliersFiltersContext.Provider>;
};

export const useExternalSuppliersFilters = () => {
  const context = useContext(ExternalSuppliersFiltersContext);
  if (!context) {
    throw new Error("useExternalSuppliersFilters must be used within a ExternalSuppliersFiltersProvider");
  }

  return context;
};
