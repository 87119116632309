import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAuth } from "@/redux/baseQuery";
import { transformApplicationAnswers } from "./helpers";
import { ApplicationAnswers, GetApplicationAnswersParams } from "./types";

export const applicationApi = createApi({
  reducerPath: "applicationApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getApplicationAnswers: builder.query<ApplicationAnswers, GetApplicationAnswersParams>({
      query: ({ providerId, accountId }) => `/v3/account-management/${accountId}/providers/${providerId}/application`,
      transformResponse: transformApplicationAnswers,
    }),
  }),
});

export const {
  useGetApplicationAnswersQuery,
} = applicationApi;
