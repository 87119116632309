import { useMemo } from "react";
import { DataTable, NoSearchResults, Pagination } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { ArchivedExternalSuppliersEmptyState } from "./components/ArchivedExternalSuppliersEmptyState";
import { useGetArchivedExternalSuppliersParams } from "./useGetArchivedExternalSuppliersParams";
import { ExternalSupplier } from "@/redux/slices/externalSupplier/types";
import { externalSuppliersPageSelector } from "@/redux/slices/externalSupplier/selectors";
import { generateArchivedExternalSuppliersTableColumns, generateArchivedExternalSuppliersTableData } from "./helpers";

interface ArchivedExternalSuppliersTableProps {
  items: ExternalSupplier[];
}

export const ArchivedExternalSuppliersTable = ({ items }: ArchivedExternalSuppliersTableProps) => {
  const { sorting, setSorting, search, filters } = useGetArchivedExternalSuppliersParams();
  const isFiltersEmpty = Object.values(filters).every((el) => el === undefined);

  const columns = useMemo(() => generateArchivedExternalSuppliersTableColumns(), []);
  const data = useMemo(
    () => generateArchivedExternalSuppliersTableData(items, () => null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const renderEmptyState = () => {
    if (search) return <NoSearchResults />;
    if (!isFiltersEmpty) return <ArchivedExternalSuppliersEmptyState hasFilters />;

    return <ArchivedExternalSuppliersEmptyState />;
  };

  return (
    <div className="relative">
      <DataTable
        className="w-full overflow-x-auto [&_table]:table-auto [&_tr]:bg-white"
        data={data}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={renderEmptyState()}
      />
    </div>
  );
};

const ArchivedExternalSuppliersTablePagination = () => {
  const { current, size, total, count } = useAppSelector(externalSuppliersPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("archived-external-suppliers");

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

ArchivedExternalSuppliersTable.Pagination = ArchivedExternalSuppliersTablePagination;
