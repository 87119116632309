export const PendingStatuses = ["pending", "Pending Qualification", "Application in Progress", "Pending Approval"];

export const ArchivedStatuses = ["removed", "left", "Permanently Terminated", "Retired", "Declined"];

export const SuspendedStatuses = ["suspended", "Temporarily Suspended"];

export const ActiveStatuses = ["active", "Approved"];

export const accountTypes: Record<string, string> = {
  external_driver: "Driver",
  external_hotel: "Hotel",
  external_medical: "Medical",
};

export type ExternalSuppliersTableData = {
  name: JSX.Element;
  type: string;
  email: string;
  phone: string;
  state: string;
  rating: JSX.Element;
  completion: string;
  status: JSX.Element;
  action: JSX.Element;
};

export type ExternalSuppliersTableColumn = {
  id: keyof ExternalSuppliersTableData;
  sortId?: string;
  header: string;
  isJSX?: boolean;
  isSortDisabled?: boolean;
  isHidden?: boolean;
  size?: number;
};
