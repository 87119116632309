import { Icon, Typography } from "@/components/atoms";
import { getAssetUrl } from "@/utils";

export const renderAvatar = (avatar: string, name: string) => (
  <div className="flex flex-shrink-0">
    {avatar ? (
      <img className="h-6 w-6 rounded-full" src={getAssetUrl(avatar, { height: 24, width: 24, fit: "cover" })} alt={name} />
    ) : (
      <div className="h-6 w-6 rounded-full bg-neutral-mid-gray" />
    )}
  </div>
);

export const renderStatus = (status: string) => {
  switch (status) {
    case "pending":
    case "Pending Qualification":
    case "Application in Progress":
    case "Pending Approval":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2 capitalize">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray" /> {status}
        </Typography>
      );
    case "active":
    case "Approved":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2 capitalize">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-success" /> {status}
        </Typography>
      );
    case "Declined":
    case "removed":
    case "left":
    case "inactive":
    case "Permanently Terminated":
    case "Retired":
    case "suspended":
    case "Temporarily Suspended":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2 capitalize">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-danger" /> {status}
        </Typography>
      );
    default:
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2 capitalize">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray" /> {status}
        </Typography>
      );
  }
};

export const renderRating = (rating?: number | null) => (
  <div className="flex flex-row items-center gap-x-1.5">
    <Typography variant="paragraph">{rating || "-"}</Typography>
    {rating && <Icon name="Star1" className="fill-warning text-warning" />}
  </div>
);