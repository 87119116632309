import { match } from "ts-pattern";
import { ApplicationRule } from "./types";

export const createDraftApplicationRule = (type: ApplicationRule["type"]): ApplicationRule => {
  const base = {
    uuid: "",
    question: "",
    description: "",
    isRequired: true,
    isEnabled: true,
    isManaged: false,
    orderScore: 99,
  };

  const newRule: ApplicationRule = match(type)
    .with("select_multi", (type) => ({
      ...base,
      type,
      conditions: { options: [] as string[], answers: [] as string[] },
    }))
    .with("select_single", (type) => ({
      ...base,
      type,
      conditions: { options: [] as string[], answers: [] as string[] },
    }))
    .with("numeric", (type) => ({ ...base, type, conditions: { answerMin: 0, answerMax: 0 } }))
    .with("bool", (type) => ({ ...base, type, conditions: { answer: false } }))
    .with("text", (type) => ({ ...base, type, conditions: null }))
    .with("document", (type) => ({ ...base, type, conditions: null }))
    .exhaustive();

  return newRule;
};
