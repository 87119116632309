import { EmptyState } from "@/components/molecules";

export const ClientsEmptyState = ({ hasFilters }: { hasFilters?: boolean }) => {
  if (hasFilters)
    return <EmptyState title="Manage Clients" description="There are no clients matching your filter criteria at the moment." />;

  return (
    <EmptyState
      title="Manage Clients"
      description="It looks like you haven't added any clients yet. Begin by adding or inviting your first client to get started"
    >
      {/* <div className="flex flex-row gap-4">
        <LinkButton to="../my-drivers/add" variant="secondary" startIcon="Add" size="md">
          Add Driver
        </LinkButton>
      </div> */}
    </EmptyState>
  );
};
