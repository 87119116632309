import { ComponentProps, useContext } from "react";
import { IconButton } from "@/components/atoms";
import { clsx } from "@/utils";
import { SortableItemContext } from "./SortableItem";

export const DragHandle = ({ className }: ComponentProps<"button">) => {
  const { attributes, listeners } = useContext(SortableItemContext);

  return (
    <IconButton
      iconSize="lg"
      iconName="drag"
      isCustomIcon
      variant="secondary"
      className={clsx("border-none p-1 text-neutral-dark-gray", className)}
      {...attributes}
      {...listeners}
    />
  );
};
