import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, Loading, Typography } from "@/components/atoms";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useClientsFilters } from "../useClientsFilters";
import { useGetClientOptionsQuery } from "@/redux/apis/client/clientApi";

const schema = z.object({
  status: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const StatusFilter = () => {
  const {
    filters: { status },
    setFilter,
  } = useClientsFilters();

  const value = useMemo(() => {
    if (!status) return undefined;

    return status.filter(Boolean).join(", ");
  }, [status]);

  const onClose = () => setFilter("status", undefined);

  return (
    <FilterPopover name="status" value={value} label="Status" onClose={onClose}>
      <StatusFilterForm />
    </FilterPopover>
  );
};

interface StatusFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const StatusFilterDrawer = ({ open, onOpenChange }: StatusFilterDrawerProps) => {
  const {
    filters: { status },
    setFilter,
  } = useClientsFilters();

  const value = useMemo(() => {
    if (!status) return undefined;

    return status.filter(Boolean).join(", ");
  }, [status]);

  const onClose = () => setFilter("status", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Status" onClose={onClose}>
      <StatusFilterForm />
    </FilterDrawer>
  );
};

const StatusFilterForm = () => {
  const { setFilter, filters } = useClientsFilters();
  const { isFetching, data: options } = useGetClientOptionsQuery();

  const statuses = useMemo(() => {
    if (isFetching || !options) return [];

    return options.statuses.map((status) => status);
  }, [isFetching, options]);

  const { control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { status: (filters.status as string[]) ?? [] },
  });

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        setFilter(
          "status",
          statuses.map((status) => status.value)
        );
        setValue(
          "status",
          statuses.map((status) => status.value)
        );
      } else {
        setFilter("status", null);
        setValue("status", []);
      }
    },
    [setFilter, setValue, statuses]
  );

  const isAllStatusesSelected = useMemo(() => {
    if (!filters.status) return false;
    return filters.status.length === statuses.length;
  }, [filters.status, statuses]);

  return (
    <div className="relative contents">
      {isFetching && <Loading className="rounded-lg" />}
      <Typography className="mb-4">Status</Typography>
      <CheckboxInput label="All Statuses" onChange={handleCheckAll} variant="check" checked={isAllStatusesSelected} />
      <div className="mt-4 grid gap-4 ">
        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <>
              {statuses.map((status) => (
                <CheckboxInput
                  className="capitalize"
                  key={status.value}
                  label={status.name}
                  value={status.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newStatus = [...field.value, e.target.value];
                      field.onChange(newStatus);
                      setFilter("status", newStatus.length > 0 ? newStatus : null);
                    } else {
                      const newStatus = field.value.filter((i) => i !== e.target.value);
                      field.onChange(newStatus);
                      setFilter("status", newStatus.length > 0 ? newStatus : null);
                    }
                  }}
                  variant="check"
                  checked={field.value.includes(status.value)}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
