import { useEffect } from "react";
import { Container } from "@/components/atoms";
import { GeneralFooterOld, GeneralHeaderOld, ProfilePhoneVerificationForm } from "@/components/organisms";
import { useLayout } from "@/hooks";

export const ProfileVerifyPhone = () => {
  const { setFullScreen } = useLayout();

  useEffect(() => {
    setFullScreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen bg-swirls-2 bg-cover ">
      <div className="relative flex-1 ">
        <GeneralHeaderOld />
        <div className="flex h-full flex-col">
          <Container className="flex flex-1 justify-center lg:-mt-24">
            <ProfilePhoneVerificationForm />
          </Container>
        </div>
        <GeneralFooterOld />
      </div>
    </div>
  );
};
