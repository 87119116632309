import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge, UseFormRegister, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import {
  AddOnTextInput,
  Blockquote,
  Button,
  Card,
  Divider,
  ErrorMessage,
  Icon,
  IconButton,
  Loading,
  RadioInput,
  TextArea,
  TextInput,
  Tooltip,
  Typography,
} from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useCloseJobWithExtrasMutation } from "@/redux/apis/booking/endpoints/closeJob";
import { BookingDetails } from "@/redux/slices/booking/types";
import { addToast, clsx, getAssetUrl } from "@/utils";
import { useBookingFees } from "../../../hooks/useBookingFees";
import { useSelectedBookingId } from "../../../hooks/useSelectedBookingId";
import { extraTypeOptions } from "../fixtures";

const zodNumber = z.union([
  z
    .number({
      invalid_type_error: "Must be a valid number",
    })
    .min(0),
  z.nan(),
]);

const schema = z.object({
  additionalRunTime: zodNumber.optional(),
  additionalRunTimeFee: zodNumber.optional(),
  additionalWaitingTime: zodNumber.optional(),
  additionalWaitingTimeFee: zodNumber.optional(),
  additionalUnscheduledStops: zodNumber.optional(),
  additionalUnscheduledStopsFee: zodNumber.optional(),
  airportParkingCharges: zodNumber.optional(),
  airportParkingChargesType: z.string().default(""),
  tollCharges: zodNumber.optional(),
  tollChargesType: z.string().default(""),
  otherCharges: z.array(
    z.object({
      description: z.string().min(1, { message: "Description must be at least 1 character long" }),
      amount: zodNumber,
      type: z.string(),
    })
  ),
  providerNotes: z.string().optional(),
  clientNotes: z.string().optional(),
});

type FormData = z.infer<typeof schema>;

const defaultValues: Partial<FormData> = {
  airportParkingChargesType: "out-of-pocket",
  tollChargesType: "out-of-pocket",
  providerNotes: "",
  clientNotes: "",
  otherCharges: [],
};

interface CloseNoExtrasProps {
  booking: BookingDetails;
  onClose: () => void;
  noShow?: boolean;
}

export const CloseWithExtras = ({ booking, onClose, noShow = false }: CloseNoExtrasProps) => {
  const { id: bookingId, driver, transferType, closeMeta, status } = booking;
  const [_, setSelectedBookingId] = useSelectedBookingId();
  const [closeJobWithExtras, { isLoading: isClosingJob }] = useCloseJobWithExtrasMutation();

  const formValues = useMemo(() => {
    if (!closeMeta || !["closed", "complete_awaiting_review"].includes(status)) return undefined;

    const { waitTime, unscheduledStops, parkingCharges, tollCharges, otherExtras, additionalRunTime, clientNotes, providerNotes } =
      closeMeta;

    return {
      additionalWaitingTime: Number(waitTime?.name) || undefined,
      additionalWaitingTimeFee: Number(waitTime?.cost) || undefined,
      additionalUnscheduledStops: Number(unscheduledStops?.name) || undefined,
      additionalUnscheduledStopsFee: Number(unscheduledStops?.cost) || undefined,
      additionalRunTime: Number(additionalRunTime?.name) || undefined,
      additionalRunTimeFee: Number(additionalRunTime?.cost) || undefined,
      airportParkingCharges: Number(parkingCharges?.cost) || undefined,
      airportParkingChargesType: parkingCharges?.type || "general",
      tollCharges: Number(tollCharges?.cost) || undefined,
      tollChargesType: tollCharges?.type || "general",
      otherCharges: otherExtras?.map((extra) => ({
        type: extra.type ?? "general",
        description: extra.name,
        amount: extra.cost,
      })),
      providerNotes: providerNotes,
      clientNotes: clientNotes,
    } as Partial<FormData>;
  }, [closeMeta, status]);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formValues || defaultValues,
    mode: "onBlur",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "otherCharges",
  });

  const additionalWaitingTime = watch("additionalWaitingTime");
  const additionalRunTime = watch("additionalRunTime");
  const unscheduledStops = watch("additionalUnscheduledStops");

  const {
    unscheduledStopsFee,
    additionalRunTimeFee,
    additionalWaitTimeFee,
    isLoading: isLoadingFees,
  } = useBookingFees(booking.id, {
    additional_run_time: additionalRunTime || undefined,
    unscheduled_stops: unscheduledStops || undefined,
    wait_time: additionalWaitingTime || undefined,
  });

  const onSubmit = async (data: FormData) => {
    return closeJobWithExtras({
      bookingId,
      noShow,
      unscheduled_stops: data.additionalUnscheduledStops
        ? {
            amount: unscheduledStopsFee ? unscheduledStopsFee * 100 : 0,
            description: data.additionalUnscheduledStops.toString(),
            type: "general",
          }
        : undefined,
      wait_time: data.additionalWaitingTime
        ? {
            amount: additionalWaitTimeFee * 100,
            description: data.additionalWaitingTime.toString(),
            type: "general",
          }
        : undefined,
      additional_run_time: data.additionalRunTime
        ? {
            amount: additionalRunTimeFee ? additionalRunTimeFee * 100 : 0,
            description: data.additionalRunTime.toString(),
            type: "general",
          }
        : undefined,
      parking_charges: data.airportParkingCharges
        ? {
            amount: data.airportParkingCharges * 100,
            description: data.airportParkingCharges.toString(),
            type: data.airportParkingChargesType,
          }
        : undefined,
      toll_charges: data.tollCharges
        ? {
            amount: data.tollCharges * 100,
            description: data.tollCharges.toString(),
            type: data.tollChargesType,
          }
        : undefined,
      provider_notes: data.providerNotes,
      client_notes: data.clientNotes,
      other_extra: data.otherCharges.map((i) => ({
        amount: i.amount * 100,
        description: i.description,
        type: i.type,
      })),
    })
      .unwrap()
      .then(() => {
        addToast("success", `Successfully Closed Job J${bookingId} with “Completed - With Extras“`);
        setSelectedBookingId(undefined);
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const isHourlyOrDailyRate = transferType.type === "hourly_rate" || transferType.type === "daily_rate";

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-5 flex flex-1 flex-col">
      <div className="flex-1 space-y-5 pb-5">
        <Card as="fieldset">
          <Typography variant="title">Extras</Typography>

          <Typography className="mb-1 mt-3">
            {isHourlyOrDailyRate ? "Additional Run Time (minutes)" : "Additional Waiting Time (minutes) after the Courtesy Waiting Times"}
          </Typography>
          {isHourlyOrDailyRate ? (
            <div className="grid gap-2">
              <div className="flex gap-4">
                <TextInput
                  placeholder="Eg. 10"
                  type="number"
                  className="flex-1"
                  {...register("additionalRunTime", { valueAsNumber: true })}
                />
                <AddOnTextInput
                  value={additionalRunTimeFee}
                  startAddOn="AUD $"
                  placeholder="0.00"
                  className="w-[150px]"
                  tabIndex={-1}
                  readOnly
                  disabled={isLoadingFees}
                />
              </div>
              <ErrorMessage errors={errors} name="additionalRunTime" />
            </div>
          ) : (
            <div className="grid gap-2">
              <div className="flex gap-4">
                <TextInput
                  placeholder="Eg. 10"
                  type="number"
                  className="flex-1"
                  {...register("additionalWaitingTime", { valueAsNumber: true })}
                />
                <AddOnTextInput
                  value={additionalWaitingTime ? additionalWaitTimeFee : ""}
                  startAddOn="AUD $"
                  placeholder="0.00"
                  tabIndex={-1}
                  className="w-[150px]"
                  readOnly
                  disabled={isLoadingFees}
                />
              </div>
              <ErrorMessage errors={errors} name="additionalWaitingTime" />
            </div>
          )}

          <Typography variant="small" className="mt-1 leading-loose text-neutral-dark-gray">
            {isHourlyOrDailyRate
              ? "Number of minutes of additional run time for hourly / daily jobs."
              : "Courtesy waiting times are: 10 minutes regular pickup, 30 minutes Domestic Airport, 60 minutes International  Airport"}
          </Typography>
          <Typography className="mb-1 mt-3">Additional Unscheduled Stops</Typography>
          <div className="grid gap-2">
            <div className="flex gap-4">
              <TextInput
                placeholder="Eg. 10"
                type="number"
                className="flex-1"
                {...register("additionalUnscheduledStops", { valueAsNumber: true })}
              />
              <AddOnTextInput
                value={unscheduledStops ? unscheduledStopsFee : ""}
                startAddOn="AUD $"
                placeholder="0.00"
                tabIndex={-1}
                className="w-[150px]"
                readOnly
                disabled={isLoadingFees}
              />
            </div>
            <ErrorMessage errors={errors} name="additionalUnscheduledStops" />
          </div>
          <Typography className="mb-1 mt-3">Airport Parking Charges</Typography>
          <div className="grid gap-2">
            <AddOnTextInput
              startAddOn="AUD $"
              placeholder="0.00"
              className="w-[calc(100%-165px)]"
              {...register("airportParkingCharges", { valueAsNumber: true })}
              type="number"
              step="0.01"
            />
            <div className="flex">
              <Controller
                name="airportParkingChargesType"
                control={control}
                render={({ field }) => (
                  <>
                    {extraTypeOptions.map((i) => (
                      <div className="mr-3 inline" key={i.value}>
                        <RadioInput
                          className="mt-2"
                          htmlFor={`airport-charges-${i.value}`}
                          label={i.name}
                          value={i.value}
                          onChange={({ target }) => field.onChange(target.value)}
                          checked={field.value === i.value}
                        />
                      </div>
                    ))}
                  </>
                )}
              />
            </div>

            <ErrorMessage errors={errors} name="airportParkingCharges" />
          </div>
          <Typography className="mb-1 mt-3">Toll Charges (Tolls incurred on the journey)</Typography>
          <div className="grid gap-2">
            <AddOnTextInput
              startAddOn="AUD $"
              placeholder="0.00"
              className="w-[calc(100%-165px)]"
              type="number"
              step="0.01"
              {...register("tollCharges", { valueAsNumber: true })}
            />
            <div className="flex">
              <Controller
                name="tollChargesType"
                control={control}
                render={({ field }) => (
                  <>
                    {extraTypeOptions.map((i) => (
                      <div className="mr-3 inline" key={i.value}>
                        <RadioInput
                          className="mt-2"
                          htmlFor={`toll-charges-${i.value}`}
                          label={i.name}
                          value={i.value}
                          onChange={({ target }) => field.onChange(target.value)}
                          checked={field.value === i.value}
                        />
                      </div>
                    ))}
                  </>
                )}
              />
            </div>
            <ErrorMessage errors={errors} name="tollCharges" />
          </div>
        </Card>
        <Typography variant="action" className="mt-8 block">
          Other Charges
        </Typography>
        {fields.map((field, index) => (
          <OtherChargesField
            key={field.id}
            index={index}
            control={control}
            register={register}
            remove={remove}
            errors={errors.otherCharges?.[index]}
          />
        ))}
        <div className="flex w-full place-content-center">
          <Button
            startIcon="Add"
            className="self-center"
            variant="tertiary"
            onClick={() => append({ amount: NaN, description: "", type: "general" })}
          >
            Add Another Charge
          </Button>
        </div>

        <Card as="fieldset">
          <Typography className="mb-1 flex place-items-center gap-2">Notes for Editing Extras - To be shared with the driver</Typography>
          <TextArea {...register("providerNotes")} placeholder="Enter Notes" rows={3} required={isDirty} />
          <ErrorMessage errors={errors} name="providerNotes" />
        </Card>

        {noShow && (
          <Card as="fieldset">
            <Typography className="mb-1 flex items-center gap-1">
              Notes to Customer
              <Tooltip
                content="We recommend leaving notes explaining why this job is being closed with a charge. These notes will be displayed to the customer."
                placement="right"
                maxWidth={320}
              >
                <i className="inline-flex">
                  <Icon name="InfoCircle" variant="Bold" size="lg" className="inline rotate-180 text-neutral-dark-gray" />
                </i>
              </Tooltip>
            </Typography>
            <TextArea {...register("clientNotes")} placeholder="Enter Notes" rows={3} />
            <ErrorMessage errors={errors} name="clientNotes" className="mt-2" />
          </Card>
        )}

        {closeMeta?.driverNotes && (
          <Blockquote avatarURL={getAssetUrl(driver?.avatar)} heading={driver!.name} subheading="Driver Notes">
            {closeMeta?.driverNotes}
          </Blockquote>
        )}
      </div>
      <div className="w-full">
        <Divider className="-mx-5" />
        <Button type="submit" disabled={isClosingJob} className="relative mt-5 w-full" variant="primary" size="lg">
          {isClosingJob && <Loading className="rounded-md" />}
          Approve For Payment
        </Button>
      </div>
    </form>
  );
};

interface OtherChargesProps {
  control: Control<FormData>;
  register: UseFormRegister<FormData>;
  index: number;
  errors?: Merge<FieldError, FieldErrorsImpl<{ type: string; description: string; amount: number }>>;
  remove: (index: number) => void;
}

const OtherChargesField = ({ control, register, index, remove, errors, ...props }: OtherChargesProps) => {
  const hasError = errors?.amount || errors?.description;

  return (
    <Card as="fieldset" {...props}>
      <div className="flex justify-center gap-4">
        <div className="flex-1">
          <Typography className="mb-1">Charges Description #{index + 1}</Typography>
          <TextInput {...register(`otherCharges.${index}.description`)} placeholder="Enter Description" />
        </div>
        <div className="w-[150px] shrink-0">
          <Typography className="mb-1">Amount</Typography>
          <AddOnTextInput
            {...register(`otherCharges.${index}.amount`, { valueAsNumber: true })}
            startAddOn="AUD $"
            placeholder="0.00"
            type="number"
            step="0.01"
          />
        </div>
        <div className={hasError ? "self-center" : "self-end"}>
          <IconButton
            iconName="Trash"
            variant="custom"
            onClick={() => remove(index)}
            iconSize="lg"
            className={clsx("mb-3 mt-9 text-danger", {
              "mt-2": hasError,
            })}
          />
        </div>
      </div>
      <div className="mt-3">
        {errors?.description && <Typography className="mt-2 truncate text-danger">{errors?.description?.message}</Typography>}
        {errors?.amount && <Typography className="mt-2 truncate text-danger">{errors?.amount?.message}</Typography>}
      </div>
      <div className="mt-3">
        <Typography className="mb-1">Type of Extra</Typography>
        <Controller
          name={`otherCharges.${index}.type`}
          control={control}
          render={({ field }) => (
            <>
              {extraTypeOptions.map((i) => (
                <div className="mr-3 inline" key={i.value}>
                  <RadioInput
                    className="mt-2"
                    htmlFor={`other-charges-${index}-${i.value}`}
                    label={i.name}
                    value={i.value}
                    name={`otherCharges.${index}.type`}
                    onChange={({ target }) => field.onChange(target.value)}
                    checked={field.value === i.value}
                  />
                </div>
              ))}
            </>
          )}
        />
      </div>
    </Card>
  );
};
