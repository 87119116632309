import { ApplicationRuleIcon } from "./common/ApplicationRuleIcon";
import { ApplicationRule } from "./types";

export const applicationRuleLabels: Record<ApplicationRule["type"], string> = {
  numeric: "Numeric Input",
  text: "Text Input",
  document: "File Upload",
  select_multi: "Multiple Choice",
  select_single: "Multiple Choice",
  bool: "Yes / No ",
};

export const addQuestionButtonItems: Array<{
  type: ApplicationRule["type"];
  name: string;
  icon: JSX.Element;
}> = [
  {
    type: "text",
    name: "Text Input",
    icon: <ApplicationRuleIcon type="text" className="[&>svg]:text-black" />,
  },
  {
    type: "numeric",
    name: "Numerical Input",
    icon: <ApplicationRuleIcon type="numeric" className="[&>svg]:text-black" />,
  },
  {
    type: "bool",
    name: "Yes / No",
    icon: <ApplicationRuleIcon type="bool" className="[&>svg]:text-black" />,
  },
  {
    type: "select_single",
    name: "Multiple Choice",
    icon: <ApplicationRuleIcon type="select_single" className="[&>svg]:text-black" />,
  },
  {
    type: "document",
    name: "Document Upload",
    icon: <ApplicationRuleIcon type="document" className="[&>svg]:text-black" />,
  },
];
