import { Modal } from "@/components/atoms";
import { ClientsRemoveAccess } from "./components/ClientsRemoveAccess";
import { useClientsContext } from "./context";

export const ClientsModals = () => {
  const { step, setStep, setSelectedClient } = useClientsContext();

  const onRemoveAccessClose = () => {
    setStep(undefined);
    setSelectedClient(undefined);
  };

  return (
    <>
      <Modal open={step === "remove-access"} onClose={onRemoveAccessClose} className="max-w-[514px]">
        <ClientsRemoveAccess onClose={onRemoveAccessClose} />
      </Modal>
    </>
  );
};
