import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProviderCountry } from "@/redux/baseQuery";
import { transformDeepLinks, transformAppLinks, transformOtherLinks } from "./helpers";
import { GetDeepLinksResponse, GetAppLinksResponse, GetOtherLinksResponse } from "./types";

export const linksAndDocumentsApi = createApi({
  reducerPath: "linksAndDocumentsApi",
  baseQuery: baseQueryWithProviderCountry(),
  endpoints: (builder) => ({
    getDeepLinks: builder.query<GetDeepLinksResponse, void>({
      query: () => "/links-and-documents?type=deep_link",
      transformResponse: transformDeepLinks,
    }),
    getAppLinks: builder.query<GetAppLinksResponse, void>({
      query: () => "/links-and-documents?type=app_link",
      transformResponse: transformAppLinks,
    }),
    getOtherLinks: builder.query<GetOtherLinksResponse, void>({
      query: () => "/links-and-documents?type=other",
      transformResponse: transformOtherLinks,
      providesTags: ["OtherLinks"],
    }),
    removeDocument: builder.mutation<void, string>({
      query: (id) => ({
        url: `/links-and-documents/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["OtherLinks"],
    }),
    uploadDocument: builder.mutation<void, FormData>({
      query: (body) => ({
        url: "/links-and-documents",
        method: "POST",
        body,
      }), 
      invalidatesTags: ["OtherLinks"],
    }),
  }),
  tagTypes: ["OtherLinks"],
});

export const { 
  useGetDeepLinksQuery, 
  useGetAppLinksQuery, 
  useGetOtherLinksQuery, 
  useRemoveDocumentMutation,
  useUploadDocumentMutation,
} = linksAndDocumentsApi;
