import { isEmpty } from "ramda";
import { Container } from "@/components/atoms";
import { GeneralFooter, GeneralHeader, SignUpSteps } from "@/components/organisms";
import { useOperator } from "@/hooks";
import { Restricted } from "../Restricted/Restricted";

export const SignUp = () => {
  const { availableAccountTypesOnRegister, singleTenant } = useOperator();
  const signUpEnabled = !isEmpty(availableAccountTypesOnRegister) || !singleTenant;

  return (
    <div className="flex min-h-screen flex-col overflow-hidden md:flex-row">
      <div className="relative flex flex-1 flex-col">
        <GeneralHeader action="login" />
        {signUpEnabled ? (
          <Container className="flex w-full max-w-[1040px] flex-1 justify-center gap-5 px-5 py-8 md:py-[100px]">
            <SignUpSteps />
          </Container>
        ) : (
          <Restricted />
        )}
        <GeneralFooter />
      </div>
    </div>
  );
};
