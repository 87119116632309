import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { BasicTable, Button, CheckboxInput, Icon, IconButton, Loading, Modal, Tooltip, Typography } from "@/components/atoms";
import { useGetCategoryVerificationQuery, vehicleApi } from "@/redux/apis/vehicle/vehicleApi";
import { addToast } from "@/utils";
import { useViewVehicleContext } from "../../context";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAppDispatch } from "@/redux/hooks";
import { useUpdateAffiliateVehicleMutation } from "@/redux/apis/vehicleGuest/vehicleGuestApi";
import { useOperator } from "@/hooks";

interface VehicleCategoryModalProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  categories: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const VehicleCategoryModal = ({ open, onClose }: VehicleCategoryModalProps) => {
  const { id: operatorId } = useOperator();
  const dispatch = useAppDispatch();
  const { vehicle } = useViewVehicleContext();
  const { capacity, model, approval } = vehicle;
  const isApproved = approval?.status === "APPROVED";

  const { data, isLoading: isLoadingCategoryVerification } = useGetCategoryVerificationQuery(
    { id: vehicle.uuid, capacity_pax: capacity.pax, capacity_bags: capacity.bags },
    { refetchOnMountOrArgChange: true }
  );
  const [updateAffiliateVehicle, { isLoading: isUpdatingAffiliateVehicle }] = useUpdateAffiliateVehicleMutation();
  const isLoading = isLoadingCategoryVerification || isUpdatingAffiliateVehicle;
  
  const { watch, setValue, handleSubmit, reset } = useForm<FormData>();
  const selected = watch("categories");

  useEffect(() => {
    if (data) {
      const defaultCategories: string[] = [];
      vehicle.categories.forEach(category => {
        const includedCategory = data.find(dataCategory => dataCategory.uuid === category.uuid);
        if (includedCategory) defaultCategories.push(includedCategory.uuid);
      });
      reset({ categories: defaultCategories });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) setValue("categories", [...(selected || []), target.value]);
    else
      setValue(
        "categories",
        (selected || []).filter((value) => value !== target.value)
      );
  };

  const handleSave = async (data: FormData) => {
    updateAffiliateVehicle({
      ownerId: operatorId,
      vehicleId: vehicle.uuid,
      approval_status: "APPROVED",
      categories: data.categories.map((category) => ({ uuid: category })),
    })
      .unwrap()
      .then(() => {
        onClose();
        addToast("success", isApproved ?  "Vehicle successfully updated" : "Vehicle approved successfully");
        dispatch(vehicleApi.util.invalidateTags(["Vehicle"]));
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(handleSave)} className="w-[750px] !px-5">
        <header className="flex">
          <Typography variant="title" className="flex-1">
            Select Vehicle Category
          </Typography>
          <IconButton iconName="close" iconSize="lg" isCustomIcon variant="custom" onClick={onClose} />
        </header>
        <div className="mt-2 flex gap-2 ">
          <Typography variant="paragraph" className="text-neutral-dark-gray">
            To continue the approval of vehicle {model.make} {model.model} ({vehicle.registrationNumber}), kindly select the applicable Vehicle Category(s) for this vehicle.
          </Typography>
        </div>
        <section className="mt-2">
          {data && !isLoading ? (
            <div className=" max-h-[50vh] overflow-y-auto p-1">
              <BasicTable className="ring-opacity-100 [&>table]:divide-opacity-100">
                <BasicTable.Row className="[&>th]:bg-neutral-gray">
                  <BasicTable.HeaderCell className="w-[220px] border-r border-neutral-mid-gray">Vehicle Categories</BasicTable.HeaderCell>
                  <BasicTable.HeaderCell className="w-[220px] text-center">Type</BasicTable.HeaderCell>
                  <BasicTable.HeaderCell className="w-[220px] text-center">Class</BasicTable.HeaderCell>
                  {/* <BasicTable.HeaderCell>Color</BasicTable.HeaderCell> */}
                  <BasicTable.HeaderCell className="w-[220px] text-center">Capacity</BasicTable.HeaderCell>
                  <BasicTable.HeaderCell className="w-[220px] text-center">Bags</BasicTable.HeaderCell>
                  {/* <BasicTable.HeaderCell>Engine</BasicTable.HeaderCell> */}
                  {/* <BasicTable.HeaderCell>Make</BasicTable.HeaderCell> */}
                  {/* <BasicTable.HeaderCell>Model</BasicTable.HeaderCell> */}
                </BasicTable.Row>
                <>
                  {data.map((category) => (
                    <BasicTable.Row key={category.uuid}>
                      <BasicTable.Cell className="border-r border-neutral-mid-gray">
                        <div className="flex max-h-[24px] items-center gap-1.5 ">
                          <CheckboxInput
                            value={category.uuid}
                            label={category.name}
                            variant="check"
                            onChange={handleChange}
                            checked={selected?.includes(category.uuid)}
                            className="truncate"
                          />
                          {selected?.includes(category.uuid) &&
                            (!category.validity.capacityBags ||
                              !category.validity.capacityPax ||
                              !category.validity.type ||
                              !category.validity.class) && (
                              <Tooltip content="Vehicle doesn't match Vehicle Category settings">
                                <Icon name="Danger" variant="Bold" size="sm" className="text-warning" />
                              </Tooltip>
                            )}
                        </div>
                      </BasicTable.Cell>
                      <BasicTable.Cell>
                        {category.validity.type ? (
                          <div className="flex w-full justify-center">
                            <Icon className="self-center text-success" name="TickCircle" variant="Bold" size="md" />
                          </div>
                        ) : null}
                      </BasicTable.Cell>
                      <BasicTable.Cell>
                        {category.validity.class ? (
                          <div className="flex w-full justify-center">
                            <Icon className="self-center text-success" name="TickCircle" variant="Bold" size="md" />
                          </div>
                        ) : null}
                      </BasicTable.Cell>
                      <BasicTable.Cell>
                        {category.validity.capacityPax ? (
                          <div className="flex w-full justify-center">
                            <Icon className="self-center text-success" name="TickCircle" variant="Bold" size="md" />
                          </div>
                        ) : null}
                      </BasicTable.Cell>
                      <BasicTable.Cell>
                        {category.validity.capacityBags ? (
                          <div className="flex w-full justify-center">
                            <Icon className="self-center text-success" name="TickCircle" variant="Bold" size="md" />
                          </div>
                        ) : null}
                      </BasicTable.Cell>
                    </BasicTable.Row>
                  ))}
                </>
              </BasicTable>
            </div>
          ) : (
            <Loading />
          )}
          <Button type="submit" variant="primary" size="lg" className="mt-5 w-full">
            {isApproved ? `Apply Vehicle Category` : `Select and Approve Vehicle`}
          </Button>
        </section>
      </form>
    </Modal>
  );
};
