import { createApi } from "@reduxjs/toolkit/dist/query/react";
import camelcaseKeys, { CamelCaseKeys } from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { createSearchParams } from "@/utils";
import {
  transformFleetDrivers,
  transformFleetLeaders,
  transformFleetOptions,
  transformOffloadDrivers,
  transformOffloadOptions,
} from "./helpers";
import {
  AddApplicationRuleParams,
  GetApplicationRulesRawResponse,
  GetFleetDriverResponse,
  GetFleetDriversParams,
  GetFleetDriversResponse,
  GetFleetLeadersResponse,
  GetFleetOptionsResponse,
  GetOffloadDriversParams,
  GetOffloadDriversResponse,
  GetOffloadOptionsResponse,
  RawApplicationRule,
  ToggleFleetLeaderParams,
  ToggleFleetLeaderResponse,
  UpdateFleetStatusParams,
  UpdateFleetStatusResponse,
} from "./types";

export const driverApi = createApi({
  reducerPath: "driverApi",
  baseQuery: baseQueryWithProvider("driver"),
  endpoints: (builder) => ({
    getFleetDrivers: builder.query<GetFleetDriversResponse, GetFleetDriversParams>({
      query: (params) => `/fleet?${createSearchParams(params).toString()}`,
      providesTags: ["FleetDrivers"],
      transformResponse: transformFleetDrivers,
    }),
    getFleetDriver: builder.query<GetFleetDriverResponse, string>({
      query: (id) => `/fleet/${id}`,
      providesTags: ["FleetDriver"],
    }),
    getFleetLeaders: builder.query<GetFleetLeadersResponse, GetFleetDriversParams>({
      query: (params) => `/fleet?${createSearchParams(params).toString()}`,
      transformResponse: transformFleetLeaders,
    }),
    getFleetOptions: builder.query<GetFleetOptionsResponse, void>({
      query: () => "/fleet/options",
      transformResponse: transformFleetOptions,
    }),
    getOffloadDrivers: builder.query<GetOffloadDriversResponse, GetOffloadDriversParams>({
      query: (params) => `/offload?${createSearchParams(params).toString()}`,
      providesTags: ["OffloadDrivers"],
      transformResponse: transformOffloadDrivers,
    }),
    getOffloadOptions: builder.query<GetOffloadOptionsResponse, void>({
      query: () => "/offload/options",
      transformResponse: transformOffloadOptions,
    }),
    toggleFleetLeader: builder.mutation<ToggleFleetLeaderResponse, ToggleFleetLeaderParams>({
      query: ({ id, isFleetLeader }) => ({
        url: `/fleet/${id}`,
        method: "PATCH",
        body: { is_fleet_leader: isFleetLeader },
      }),
    }),
    updateFleetStatus: builder.mutation<UpdateFleetStatusResponse, UpdateFleetStatusParams>({
      query: ({ id, ...body }) => ({
        url: `/fleet/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["FleetDriver"],
    }),
    retainDriver: builder.mutation<void, string>({
      query: (id) => ({
        url: `/fleet/${id}/retain`,
        method: "POST",
      }),
      invalidatesTags: ["FleetDrivers"],
    }),
    getApplicationRules: builder.query<CamelCaseKeys<RawApplicationRule, true>[], void>({
      query: () => "/application-rules?page_size=999",
      transformResponse: (response: GetApplicationRulesRawResponse) =>
        camelcaseKeys(response._embedded.rules, { deep: true }).sort((a, b) => a.orderScore - b.orderScore),
      providesTags: ["ApplicationRule"],
    }),
    addApplicationRule: builder.mutation<CamelCaseKeys<RawApplicationRule, true>, AddApplicationRuleParams>({
      query: (body) => ({
        url: "/application-rules",
        method: "POST",
        body: {
          ...body,
          conditions: body.conditions ? snakecaseKeys(body.conditions) : undefined,
        },
      }),
      transformResponse: (response: RawApplicationRule) => camelcaseKeys(response, { deep: true }),
      invalidatesTags: ["ApplicationRule"],
    }),
    updateApplicationRule: builder.mutation<CamelCaseKeys<RawApplicationRule, true>, { id: string; body: AddApplicationRuleParams }>({
      query: ({ id, body }) => ({
        url: `/application-rules/${id}`,
        method: "PATCH",
        body: {
          ...body,
          conditions: body.conditions ? snakecaseKeys(body.conditions) : undefined,
        },
      }),
      transformResponse: (response: RawApplicationRule) => camelcaseKeys(response, { deep: true }),
      invalidatesTags: (result) => (result ? [{ type: "ApplicationRule" as const, uuid: result.uuid }] : []),
    }),
    removeApplicationRule: builder.mutation<void, string>({
      query: (id) => ({
        url: `/application-rules/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ApplicationRule"],
    }),
  }),
  tagTypes: ["FleetDrivers", "FleetDriver", "OffloadDrivers", "ApplicationRule"],
});

export const {
  useGetFleetDriversQuery,
  useGetFleetDriverQuery,
  useGetFleetLeadersQuery,
  useGetFleetOptionsQuery,
  useGetOffloadDriversQuery,
  useGetOffloadOptionsQuery,
  useToggleFleetLeaderMutation,
  useUpdateFleetStatusMutation,
  useRetainDriverMutation,
  useGetApplicationRulesQuery,
  useAddApplicationRuleMutation,
  useUpdateApplicationRuleMutation,
  useRemoveApplicationRuleMutation,
} = driverApi;
