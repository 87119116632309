import { createSearchParams } from "@/utils";
import { pricingApi } from "../pricingApi";
import {
  transformFixedPricingStates,
  transformDynamicPricingStates,
  transformPricingProfiles,
  transformNationwideFixedPricing,
  transformFixedPricingLocations,
} from "./helpers";
import {
  GetDynamicPricingStatesResponse,
  UpdateStateBasedDynamicPricingParams,
  UpdateStateBasedDynamicPricingResponse,
  DeleteStateBasedDynamicPricingParams,
  GetTripCostEstimateParams,
  GetTripCostEstimateResponse,
  GetPricingProfilesResponse,
  AddPricingProfileResponse,
  AddPricingProfileParams,
  EditPricingProfileResponse,
  EditPricingProfileParams,
  DeletePricingProfileParams,
  GetNationwideFixedPricingResponse,
  GetNationwideFixedPricingParams,
  UpdateNationwideFixedPricingResponse,
  UpdateNationwideFixedPricingParams,
  DeleteNationwideFixedPricingParams,
  GetFixedPricingStatesResponse,
  GetFixedPricingStatesParams,
  UpdateStateBasedFixedPricingResponse,
  UpdateStateBasedFixedPricingParams,
  DeleteStateBasedFixedPricingParams,
  GetFixedPricingLocationsResponse,
  GetFixedPricingLocationsParams,
  AddFixedPricingLocationResponse,
  AddFixedPricingLocationParams,
  UpdateFixedPricingLocationResponse,
  UpdateFixedPricingLocationParams,
  UpdateLocationBasedFixedPricingResponse,
  UpdateLocationBasedFixedPricingParams,
  DeleteLocationBasedFixedPricingParams,
  GetPricingProfilesParams,
} from "./types";

export const tripCostApi = pricingApi
  .enhanceEndpoints({
    addTagTypes: ["DynamicPricingState", "PricingProfile", "NationwideFixedPricing", "FixedPricingState", "FixedPricingLocation"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDynamicPricingStates: builder.query<GetDynamicPricingStatesResponse, void>({
        query: () => "/pricing/standard",
        transformResponse: transformDynamicPricingStates,
        providesTags: ["DynamicPricingState"],
      }),
      updateStateBasedDynamicPricing: builder.mutation<UpdateStateBasedDynamicPricingResponse, UpdateStateBasedDynamicPricingParams>({
        query: ({ state, ...body }) => ({
          url: `/pricing/standard/${state}`,
          method: "PUT",
          body,
        }),
        invalidatesTags: ["DynamicPricingState"],
      }),
      deleteStateBasedDynamicPricing: builder.mutation<void, DeleteStateBasedDynamicPricingParams>({
        query: ({ state }) => ({
          url: `/pricing/standard/${state}`,
          method: "DELETE",
        }),
        invalidatesTags: ["DynamicPricingState"],
      }),
      getTripCostEstimate: builder.query<GetTripCostEstimateResponse, GetTripCostEstimateParams>({
        query: (params) => ({
          url: "/config/pricing/trip-cost/estimate",
          params,
        }),
      }),
      getPricingProfiles: builder.query<GetPricingProfilesResponse, GetPricingProfilesParams>({
        query: (params) => `/pricing/profile/fixed?${createSearchParams(params).toString()}`,
        transformResponse: transformPricingProfiles,
        providesTags: ["PricingProfile"],
      }),
      addPricingProfile: builder.mutation<AddPricingProfileResponse, AddPricingProfileParams>({
        query: (body) => ({
          url: "/pricing/profile/fixed",
          method: "POST",
          body,
        }),
        invalidatesTags: ["PricingProfile"],
      }),
      editPricingProfile: builder.mutation<EditPricingProfileResponse, EditPricingProfileParams>({
        query: ({ id, ...body }) => ({
          url: `/pricing/profile/fixed/${id}`,
          method: "PUT",
          body,
        }),
        invalidatesTags: ["PricingProfile"],
      }),
      deletePricingProfile: builder.mutation<void, DeletePricingProfileParams>({
        query: ({ id }) => ({
          url: `/pricing/profile/fixed/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["PricingProfile"],
      }),
      getNationwideFixedPricing: builder.query<GetNationwideFixedPricingResponse, GetNationwideFixedPricingParams>({
        query: ({ id }) => `/pricing/profile/fixed/${id}/nationwide`,
        transformResponse: transformNationwideFixedPricing,
        providesTags: ["NationwideFixedPricing"],
      }),
      updateNationwideFixedPricing: builder.mutation<UpdateNationwideFixedPricingResponse, UpdateNationwideFixedPricingParams>({
        query: ({ id, ...body }) => ({
          url: `/pricing/profile/fixed/${id}/nationwide`,
          method: "PUT",
          body,
        }),
        invalidatesTags: ["PricingProfile"],
      }),
      deleteNationwideFixedPricing: builder.mutation<void, DeleteNationwideFixedPricingParams>({
        query: ({ id }) => ({
          url: `/pricing/profile/fixed/${id}/nationwide`,
          method: "DELETE",
        }),
        invalidatesTags: ["PricingProfile"],
      }),
      getFixedPricingStates: builder.query<GetFixedPricingStatesResponse, GetFixedPricingStatesParams>({
        query: ({ id }) => `/pricing/profile/fixed/${id}/state`,
        transformResponse: transformFixedPricingStates,
        providesTags: ["FixedPricingState"],
      }),
      updateStateBasedFixedPricing: builder.mutation<UpdateStateBasedFixedPricingResponse, UpdateStateBasedFixedPricingParams>({
        query: ({ id, state, ...body }) => ({
          url: `/pricing/profile/fixed/${id}/state/${state}`,
          method: "PUT",
          body,
        }),
        invalidatesTags: ["PricingProfile"],
      }),
      deleteStateBasedFixedPricing: builder.mutation<void, DeleteStateBasedFixedPricingParams>({
        query: ({ id, state }) => ({
          url: `/pricing/profile/fixed/${id}/state/${state}`,
          method: "DELETE",
        }),
        invalidatesTags: ["PricingProfile"],
      }),
      getFixedPricingLocations: builder.query<GetFixedPricingLocationsResponse, GetFixedPricingLocationsParams>({
        query: ({ id }) => `/pricing/profile/fixed/${id}/location`,
        transformResponse: transformFixedPricingLocations,
        providesTags: ["FixedPricingLocation"],
      }),
      addFixedPricingLocation: builder.mutation<AddFixedPricingLocationResponse, AddFixedPricingLocationParams>({
        query: ({ id, ...body }) => ({
          url: `/pricing/profile/fixed/${id}/location`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["PricingProfile"],
      }),
      updateFixedPricingLocation: builder.mutation<UpdateFixedPricingLocationResponse, UpdateFixedPricingLocationParams>({
        query: ({ profileId, locationId, ...body }) => ({
          url: `/pricing/profile/fixed/${profileId}/location/${locationId}`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["PricingProfile"],
      }),
      updateLocationBasedFixedPricing: builder.mutation<UpdateLocationBasedFixedPricingResponse, UpdateLocationBasedFixedPricingParams>({
        query: ({ profileId, locationId, ...body }) => ({
          url: `/pricing/profile/fixed/${profileId}/location/${locationId}`,
          method: "PUT",
          body,
        }),
        invalidatesTags: ["PricingProfile"],
      }),
      deleteLocationBasedFixedPricing: builder.mutation<void, DeleteLocationBasedFixedPricingParams>({
        query: ({ profileId, locationId }) => ({
          url: `/pricing/profile/fixed/${profileId}/location/${locationId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["PricingProfile"],
      }),
    }),
  });

export const {
  useGetDynamicPricingStatesQuery,
  useUpdateStateBasedDynamicPricingMutation,
  useDeleteStateBasedDynamicPricingMutation,
  useLazyGetTripCostEstimateQuery,
  useGetPricingProfilesQuery,
  useAddPricingProfileMutation,
  useEditPricingProfileMutation,
  useDeletePricingProfileMutation,
  useLazyGetNationwideFixedPricingQuery,
  useUpdateNationwideFixedPricingMutation,
  useDeleteNationwideFixedPricingMutation,
  useLazyGetFixedPricingStatesQuery,
  useUpdateStateBasedFixedPricingMutation,
  useDeleteStateBasedFixedPricingMutation,
  useLazyGetFixedPricingLocationsQuery,
  useAddFixedPricingLocationMutation,
  useUpdateFixedPricingLocationMutation,
  useUpdateLocationBasedFixedPricingMutation,
  useDeleteLocationBasedFixedPricingMutation,
} = tripCostApi;
