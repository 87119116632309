import { Link } from "react-router-dom";
import { Button, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useRemoveAccessMutation } from "@/redux/apis/client/clientApi";
import { addToast, clsx } from "@/utils";
import { getManageAccountPath } from "../helpers";
import { useClientsContext } from "../context";

interface ClientsRemoveAccessProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
}

export const ClientsRemoveAccess = ({ onClose, className, ...props }: ClientsRemoveAccessProps) => {
  const { selectedClient } = useClientsContext();
  const [removeAccess, { isLoading }] = useRemoveAccessMutation();

  const onRemoveClick = () => {
    if (!selectedClient) return;

    removeAccess(selectedClient.id)
      .unwrap()
      .then(() => {
        addToast("success", `Removed access for ${selectedClient.name}`);
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const renderClientName = () => {
    if (selectedClient) {
      return (
        <Link to={getManageAccountPath(selectedClient.id)} className="inline-flex hover:text-info" target="_blank">
          {`${selectedClient.name}'s`}
        </Link>
      );
    }
  };

  return (
    <div className={clsx("relative", className)} {...props}>
      {isLoading && <Loading />}
      <div className="max-w-lg sm:flex sm:items-start ">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <Typography variant="title">Remove Access</Typography>
          <div className="mt-3 text-neutral-dark-gray ">
            <Typography variant="paragraph">Are you sure you want to remove {renderClientName()} Access from RideMinder?</Typography>
          </div>
        </div>
      </div>
      <div className="mt-6 flex justify-end gap-4">
        <Button variant="secondary" onClick={onClose} className="px-8">
          Cancel
        </Button>
        <Button variant="primary" onClick={onRemoveClick} className="!bg-danger px-8 hover:!bg-danger-dark">
          Remove
        </Button>
      </div>
    </div>
  );
};
