import { useMemo } from "react";
import { DataTable, Pagination, NoSearchResults } from "@/components/molecules";
import { usePagination, useProfile } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { addToast, clsx } from "@/utils";
import { useReactivateAccountMutation } from "@/redux/apis/client/clientApi";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { clientsPageSelector } from "@/redux/slices/client/selectors";
import { generateClientColumns, generateClientData } from "./helpers";
import { Client } from "@/redux/slices/client/types";
import { ClientsEmptyState } from "./components/ClientsEmptyState";
import { useGetClientsParams } from "./useGetClientsParams";
import { Loading } from "@/components/atoms";
import { useClientsContext } from "./context";

interface ClientsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: Client[];
}

export const ClientsTable = ({ items, className, ...props }: ClientsTableProps) => {
  const { dateFormat } = useProfile();
  const [reactivateAccount, { isLoading }] = useReactivateAccountMutation();
  const { setStep, setSelectedClient } = useClientsContext();
  const { sorting, setSorting, search, filters } = useGetClientsParams();
  const isFiltersEmpty = Object.values(filters).every((el) => el === undefined);

  const onReactivateClick = (client: Client) => {
    reactivateAccount(client.id)
      .unwrap()
      .then(() => {
        addToast("success", `Reactivated access for ${client.name}`);
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const setPendingRemoveAccess = (client: Client) => {
    setSelectedClient(client);
    setStep("remove-access");
  };

  const columns = useMemo(() => generateClientColumns(), []);
  const data = useMemo(
    () => generateClientData(items, dateFormat, setPendingRemoveAccess, onReactivateClick),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const renderEmptyState = () => {
    if (search) return <NoSearchResults />;
    if (!isFiltersEmpty) return <ClientsEmptyState hasFilters />;

    return <ClientsEmptyState />;
  };

  return (
    <div className={clsx("relative flex bg-white", className)}>
      {isLoading && <Loading />}
      <DataTable 
        className="[&_table]:table-auto overflow-x-auto w-full"
        columns={columns} 
        data={data} 
        sorting={sorting} 
        setSorting={setSorting} 
        emptyState={renderEmptyState()} 
        {...props} 
      />
    </div>
  );
};

const ClientsTablePagination = () => {
  const { current, count, total, size } = useAppSelector(clientsPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("clients");

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

ClientsTable.Pagination = ClientsTablePagination;
