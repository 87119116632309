import { DataTableColumnDef } from "@/components/molecules";
import { AffiliateVehicleTableData } from "./types";

export const affiliateVehiclesColumns: DataTableColumnDef<AffiliateVehicleTableData>[] = [
  {
    header: "Year",
    size: 100,
    accessorKey: "year",
    sortDescFirst: false,
  },
  {
    header: "Make",
    accessorKey: "make",
    cell: (props) => props.row.original.make,
    size: 150,
    sortDescFirst: false,
  },
  {
    header: "Model",
    accessorKey: "model",
    size: 150,
    sortDescFirst: false,
  },
  {
    header: "Number Plate",
    accessorKey: "registration_number",
    cell: (props) => props.row.original.numberPlate,
    size: 150,
    sortDescFirst: false,
  },
  {
    header: "Color",
    id: "color",
    cell: (props) => props.row.original.color,
    size: 150,
  },
  {
    header: "State",
    id: "state",
    cell: (props) => props.row.original.state,
    size: 150,
  },
  {
    header: "Owner",
    id: "driver",
    cell: (props) => props.row.original.owner,
    size: 200,
  },
  {
    header: "Status",
    id: "status",
    cell: (props) => props.row.original.status,
    size: 150,
  },
  {
    header: "Actions",
    id: "actions",
    cell: (props) => props.row.original.actions,
    maxSize: 75,
  },
];
