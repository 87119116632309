import { useSearch } from "@/hooks";
import { GetInvoicesCompaniesParams } from "@/redux/apis/company/types";

export const useGetInvoicesCompaniesParams = () => {
  const [search, setSearch] = useSearch();

  const params: GetInvoicesCompaniesParams = {
    search: search ?? undefined,
  };

  return {
    params,
    search: params.search,
    setSearch,
  };
};
