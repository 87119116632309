import { LinkButton } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";

export const MyDriversEmptyState = ({ hasFilters }: { hasFilters?: boolean }) => {
  if (hasFilters)
    return <EmptyState title="Manage Drivers" description="There are no drivers matching your filter criteria at the moment." />;

  return (
    <EmptyState
      title="My Drivers"
      description="It looks like you haven't added any drivers yet. Begin by adding or inviting your first driver to get started"
    >
      <div className="flex flex-row gap-4">
        <LinkButton to="../my-drivers/add" variant="secondary" startIcon="Add" size="md">
          Add Driver
        </LinkButton>
      </div>
    </EmptyState>
  );
};
