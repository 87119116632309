import { Icon, Loading, Typography } from "@/components/atoms";
import { useProfileContext } from "../../context";
import { useGetApplicationAnswersQuery } from "@/redux/apis/application/applicationApi";
import { useOperator } from "@/hooks";
import { DriverDetail } from "../../../../common/DriverDetail";
import { isEmpty } from "ramda";
import { useLazyRequestDriverApplicationDocumentQuery } from "@/redux/apis/document/documentApi";
import { downloadFile } from "@/utils";
import { ApplicationAnswer } from "@/redux/apis/application/types";

export const CustomApplicationAnswers = () => {
  const operator = useOperator();
  const { driver } = useProfileContext();
  const [requestDocument, { isLoading: isLoadingDocument }] = useLazyRequestDriverApplicationDocumentQuery();
  const { isFetching, data } = useGetApplicationAnswersQuery({ providerId: operator.id, accountId: driver.uuid });
  const applicationAnswers = data?.applicationAnswers ? data.applicationAnswers : [];
  const isLoading = isFetching || isLoadingDocument;

  const downloadDocument = (applicationAnswer: ApplicationAnswer) => {
    const response = applicationAnswer.response as string;

    requestDocument({
      id: response,
      driverId: driver.uuid,
    })
      .unwrap()
      .then((res) => downloadFile(res.filename, response));
  };

  const getResponseDisplay = (response: string | string[]) => {
    if (typeof response === "object") {
      if (response.length > 1) return response.join(', '); 
      else return response[0];
    } else return response;
  };

  if (!isFetching && isEmpty(applicationAnswers)) return null;

  return (
    <div className="relative mt-4 flex flex-col rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
      {isLoading && <Loading className="rounded-lg" />}
      <Typography variant="title" className="flex-1">
        Custom Application Answers
      </Typography>
      <div className="mt-6 grid gap-6 grid-cols-1 md:grid-cols-3 2xl:grid-cols-5">
        {applicationAnswers.map(applicationAnswer => {
          if (applicationAnswer.type === "document" && applicationAnswer.response !== "No answer") {
            return (
              <DriverDetail 
                key={applicationAnswer.id}
                label={applicationAnswer.question}
                detail={
                  <div className="flex">
                    <div className="flex cursor-pointer flex-row items-center gap-2 hover:text-info" onClick={() => downloadDocument(applicationAnswer)}>
                      <Typography variant="action">{applicationAnswer.response}</Typography>
                      <Icon name="ImportCurve" size="sm" className="shrink-0 text-info" />
                    </div>
                  </div>
                }
              />
            );
          }
          return (
            <DriverDetail 
              key={applicationAnswer.id}
              label={applicationAnswer.question} 
              detail={getResponseDisplay(applicationAnswer.response)} 
            />
          );
        })}
      </div>
    </div>
  );
};
