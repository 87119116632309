import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Userpilot } from "userpilot";
import { Button, Loading } from "@/components/atoms";
import { StepIndicator } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useRegisterProviderClientMutation } from "@/redux/apis/auth/authApi";
import { RegisterProviderClientParams } from "@/redux/apis/auth/types";
import { addToast, clsx } from "@/utils";
import { CompanyField } from "./fields/CompanyField";
import { ConfirmPasswordField } from "./fields/ConfirmPasswordField";
import { ContactNumberField } from "./fields/ContactNumberField";
import { EmailField } from "./fields/EmailField";
import { SendLoginInstructionsField } from "./fields/EnableInvoicingField";
import { FirstNameField } from "./fields/FirstNameField";
import { HowDidTheClientHearAboutUsField } from "./fields/HowDidTheClientHearAboutUsField";
import { LastNameField } from "./fields/LastNameField";
import { PasswordField } from "./fields/PasswordField";
import { AddClientFormData } from "./types";
import { addClientFormSchema } from "./fixtures";

export const AddClientForm = () => {
  const navigate = useNavigate();
  const operator = useOperator();
  const [formStep, setFormStep] = useState(1);
  const [registerProviderClient, { isLoading }] = useRegisterProviderClientMutation();
  const methods = useForm<AddClientFormData>({
    resolver: zodResolver(addClientFormSchema),
    defaultValues: { sendLoginInstructions: false },
  });
  const {
    handleSubmit,
    trigger,
    formState: { isDirty },
  } = methods;

  const handleBackClick = () => {
    setFormStep(formStep - 1);
  };

  const handleCancelClick = () => {
    navigate("../");
  };

  const onNextClick = async () => {
    const valid = await trigger(["firstName", "lastName", "company", "email", "contactNumber"], {
      shouldFocus: true,
    });

    if (valid) setFormStep(formStep + 1);
  };

  const onSubmit = handleSubmit((data) => {
    if (!operator) return;

    const clientData: RegisterProviderClientParams = {
      providerId: operator.id,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      mobile: data.contactNumber || "",
      provider_code: operator.providerCode,
      registered_device: "browser",
      hear_about_us: data.howDidTheClientHearAboutUs,
      company_uuid: data.company || undefined,
      send_login_instructions: data.sendLoginInstructions,
      password: data.password,
    };

    registerProviderClient(clientData)
      .unwrap()
      .then(() => {
        Userpilot.track("Client Added");
        addToast("success", "Successfully added new client");
        navigate("../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  return (
    <FormProvider {...methods}>
      <StepIndicator currentStep={formStep} numSteps={2} className="my-8 justify-center" />
      <form onSubmit={onSubmit}>
        <div
          className={clsx("grid animate-enter grid-cols-3 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6", {
            hidden: formStep > 1,
          })}
        >
          <FirstNameField className="col-span-3 lg:col-span-1" />
          <LastNameField className="col-span-3 lg:col-span-1" />
          <CompanyField className="col-span-3 lg:col-span-1" />
          <EmailField className="col-span-3 lg:col-span-1" />
          <ContactNumberField className="col-span-3 lg:col-span-1" />
        </div>
        <div
          className={clsx("grid animate-enter grid-cols-3 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6", {
            hidden: formStep < 2,
          })}
        >
          <PasswordField className="col-span-3 lg:col-span-1" />
          <ConfirmPasswordField className="col-span-3 lg:col-span-1" />
          <SendLoginInstructionsField className="col-span-3 lg:col-span-1" />
          <HowDidTheClientHearAboutUsField className="col-span-3 lg:col-span-2" />
        </div>
        <div className="mb-4 mt-10 flex justify-end gap-x-2">
          <Button variant="secondary" startIcon="ArrowLeft" className={clsx({ hidden: formStep < 2 })} onClick={handleBackClick} size="lg">
            Back
          </Button>
          <Button variant="secondary" className={clsx({ hidden: formStep > 1 })} onClick={handleCancelClick} size="lg">
            Cancel
          </Button>
          <div className="relative">
            {isLoading && <Loading />}
            <Button variant="primary" type="submit" className={clsx({ hidden: formStep < 2 })} disabled={!isDirty} size="lg">
              Add Client
            </Button>
          </div>
          <Button
            variant="primary"
            endIcon="ArrowRight"
            className={clsx({ hidden: formStep > 1 })}
            disabled={!isDirty}
            onClick={onNextClick}
            size="lg"
          >
            Next
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
