import {
  accountApi,
  accreditationApi,
  adminApi,
  assetApi,
  brandingApi,
  authApi,
  clientApi,
  companyApi,
  linksAndDocumentsApi,
  termsAndConditionsApi,
  networkApi,
  operatorApi,
  pricingApi,
  qrApi,
  ratingApi,
  reportingApi,
  reportApi,
  documentApi,
  resourceApi,
  userAccountApi,
  configVehicleApi,
  recommendApi,
  profileApi,
  notificationApi,
  typeaheadApi,
  systemApi,
  driverApi,
  driverProfileApi,
  driverPayoutApi,
  blockApi,
  bookingApi,
  externalSupplierApi,
  bookingOfferApi,
  paymentApi,
  payInvoiceApi,
  vehicleGuestApi,
  addOnApi,
  contentApi,
  jobRatingApi,
  paymentMethodApi,
  vehicleCategoryApi,
  bankAccountApi,
  applicationApi,
} from "./apis";
import { guestApi } from "./apis/guest/guestApi";
import { vehicleApi } from "./apis/vehicle/vehicleApi";

export const middlewares = [
  authApi.middleware,
  accountApi.middleware,
  pricingApi.middleware,
  operatorApi.middleware,
  adminApi.middleware,
  configVehicleApi.middleware,
  assetApi.middleware,
  brandingApi.middleware,
  linksAndDocumentsApi.middleware,
  termsAndConditionsApi.middleware,
  qrApi.middleware,
  accreditationApi.middleware,
  resourceApi.middleware,
  userAccountApi.middleware,
  ratingApi.middleware,
  companyApi.middleware,
  reportingApi.middleware,
  reportApi.middleware,
  documentApi.middleware,
  networkApi.middleware,
  clientApi.middleware,
  recommendApi.middleware,
  profileApi.middleware,
  notificationApi.middleware,
  typeaheadApi.middleware,
  systemApi.middleware,
  driverApi.middleware,
  driverProfileApi.middleware,
  driverPayoutApi.middleware,
  blockApi.middleware,
  bookingApi.middleware,
  externalSupplierApi.middleware,
  bookingOfferApi.middleware,
  paymentApi.middleware,
  payInvoiceApi.middleware,
  vehicleGuestApi.middleware,
  addOnApi.middleware,
  guestApi.middleware,
  vehicleApi.middleware,
  contentApi.middleware,
  jobRatingApi.middleware,
  paymentMethodApi.middleware,
  vehicleCategoryApi.middleware,
  bankAccountApi.middleware,
  applicationApi.middleware,
];
