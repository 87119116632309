import { ComponentProps } from "react";
import { IconSax } from "@/components/atoms";
import { clsx } from "@/utils";
import { ApplicationRule } from "../types";

interface ApplicationRuleIconProps extends ComponentProps<"span"> {
  type: ApplicationRule["type"];
}

export const ApplicationRuleIcon = ({ type, className, ...props }: ApplicationRuleIconProps) => {
  const classes = clsx("[&>svg]:text-white p-[3px] shrink-0 text-neutral-gray rule-icon rounded", className);

  const renderIcon = () => {
    switch (type) {
      case "numeric":
        return <IconSax name="TextAlignLeft" size="lg" />;

      case "text":
        return <IconSax name="Text" size="lg" />;

      case "document":
        return <IconSax name="DocumentText" size="lg" />;

      case "select_single":
      case "select_multi":
        return <IconSax name="ElementEqual" size="lg" />;

      case "bool":
        return <IconSax name="LikeDislike" size="lg" />;

      default:
        return null;
    }
  };

  return (
    <span {...props} className={classes}>
      {renderIcon()}
    </span>
  );
};
