import { useWindowSize } from "@react-hookz/web";
import { saveAs } from "file-saver";
import { useState } from "react";
import { Button, DropdownMenu, Icon, IconButton, Loading, SearchInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePagination } from "@/hooks";
import { useLazyExportExternalSuppliersQuery } from "@/redux/apis/reporting/reportingApi";
import { addToast, clsx } from "@/utils";
import { ExternalSuppliersFilters } from "../../../features/Filters/ExternalSuppliersFilters";
import { useGetExternalSuppliersParams } from "../useGetExternalSuppliersParams";
import { useNavigate } from "react-router-dom";

export const MyExternalSuppliersActionMenu = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [exportExternalSuppliers, { isFetching }] = useLazyExportExternalSuppliersQuery();
  const { search, setSearch } = useGetExternalSuppliersParams();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const { clearPagination } = usePagination("my-external-suppliers");

  const handleSearch = (value?: string) => {
    if (!value) setIsSearchActive(false);

    clearPagination();
    setSearch(value);
  };

  const handleExport = (type: "full" | "active") => {
    exportExternalSuppliers({ report_type: type })
      .unwrap()
      .then((res) => saveAs(res.data.path, "external-suppliers"))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  if (!isSearchActive && !search) {
    return (
      <div className="flex flex-1 items-center justify-end gap-1 xl:gap-2 ">
        <Button
          className="max-md:[&>span]:hidden"
          variant="tertiary"
          size="sm"
          startIcon="SearchNormal1"
          onClick={() => setIsSearchActive(true)}
        >
          Search
        </Button>
        <FilterButton />
        <DropdownMenu
          button={
            <div className="relative">
              {isFetching && <Loading />}
              {width >= 768 ? (
                <DropdownMenu.Button as={Button} startIcon="Add" isDropdown size="sm" className="w-full">
                  Actions
                </DropdownMenu.Button>
              ) : (
                <DropdownMenu.Button as={IconButton} iconName="options" isCustomIcon variant="primary" size="sm" className="fill-white" />
              )}
            </div>
          }
          isCustomButton
          position="bottom-start"
        >
          <DropdownMenu.Item className="w-[298px] text-primary-dark" onClick={() => navigate("my-external-suppliers/add")}>
            <Icon name="Add" className="mr-2.5" size="sm" />
            <Typography>Add External Supplier</Typography>
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => handleExport("full")} className="w-[298px] text-primary-dark">
            <Icon name="Export" className="mr-2.5" size="sm" />
            <Typography>Export External Supplier</Typography>
          </DropdownMenu.Item>
        </DropdownMenu>
      </div>
    );
  }

  return (
    <div className="flex flex-1 items-center justify-end gap-2">
      <SearchInput
        search={search}
        onSearch={handleSearch}
        autoFocus
        placeholder="Search External Suppliers"
        className={clsx("w-full max-w-sm p-0 sm:w-fit [&>input]:transition-size", isSearchActive && "[&>input]:w-[280px]")}
        size="sm"
        onClear={() => setIsSearchActive(false)}
      />
    </div>
  );
};

const FilterButton = () => {
  const { width } = useWindowSize();

  return width >= 768 ? <ExternalSuppliersFilters.Button /> : <ExternalSuppliersFilters.DrawerButton />;
};
