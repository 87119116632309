import { Button, DropdownMenu, Typography } from "@/components/atoms";
import { ApplicationRuleNav } from "./common/ApplicationRuleNav";
import { useApplicationRulesContext } from "./context";
import { addQuestionButtonItems } from "./fixtures";
import { createDraftApplicationRule } from "./helpers";
import { ApplicationRule } from "./types";

export const ApplicationRulesSidebar = () => {
  const { activeRule, setActiveRule, rules, draftRule } = useApplicationRulesContext();

  return (
    <aside className="w-[320px] max-w-full">
      <Typography variant="action" className="block px-5">
        Registration Questions
      </Typography>
      <div className="thin-scrollbar max-h-[500px] space-y-1 overflow-auto px-5">
        {[...rules, ...(draftRule ? [draftRule] : [])].map((rule) => (
          <ApplicationRuleNav key={rule.uuid} rule={rule} isSelected={activeRule?.uuid === rule.uuid} onClick={() => setActiveRule(rule)} />
        ))}
      </div>
      <AddQuestionButton />
    </aside>
  );
};

const AddQuestionButton = () => {
  const { setDraftRule, setActiveRule, setEditMode } = useApplicationRulesContext();

  const addQuestion = (type: ApplicationRule["type"]) => {
    const draftRule = createDraftApplicationRule(type);
    setDraftRule(draftRule);
    setActiveRule(draftRule);
    setEditMode(true);
  };

  return (
    <DropdownMenu
      className="w-full"
      button={
        <Button
          startIcon="AddCircle"
          iconVariant="Bold"
          variant="tertiary"
          className="mx-5 min-h-[50px] w-full justify-start !p-3 text-left text-primary"
        >
          Add Question
        </Button>
      }
      position="bottom-start"
    >
      <div className="w-[280px]">
        {addQuestionButtonItems.map((item, i) => (
          <DropdownMenu.Item key={i} className="w-full" onClick={() => addQuestion(item.type)}>
            <Typography className="flex items-center gap-2">
              {item.icon} {item.name}
            </Typography>
          </DropdownMenu.Item>
        ))}
      </div>
    </DropdownMenu>
  );
};
