import { Avatar, Button, Icon, IconButton, Tooltip, Typography } from "@/components/atoms";
import { useAppDispatch } from "@/redux/hooks";
import { setAction, setInlineEditing } from "@/redux/slices/booking/bookingSlice";
import { clsx, getAssetUrl } from "@/utils";
import { getDriverWarningTooltip } from "../../../helpers/driver";
import { useIsOperatorOwnJob } from "../../../hooks/useIsOperatorOwnJob";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const Driver = () => {
  const dispatch = useAppDispatch();

  const {
    driver,
    assignedVehicle,
    metadata: { isDriverEditAllowed, isOffloaded, isVehicleCorrect },
  } = useSelectedBooking();

  const isOwnJob = useIsOperatorOwnJob();

  const handleEditMode = () => {
    dispatch(setInlineEditing("change-driver"));
  };

  const handleAssignmentLog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setAction("driver-assignment-logs"));
    return null;
  };

  const showWarning = assignedVehicle && driver?.vehicle ? assignedVehicle.uuid !== driver.vehicle.uuid : !isVehicleCorrect;

  const renderContent = () => {
    if (driver)
      return (
        <>
          <div className="flex flex-1 items-center gap-2 max-2xl:-translate-y-1">
            <Avatar src={getAssetUrl(driver.avatar, { height: 64, width: 64, fit: "cover" })} className="h-8 w-8" />
            <div className="flex flex-1 items-center gap-2">
              <Typography variant="small" className="flex flex-col ">
                <span>{driver.name}</span>
                <span className=" hidden text-neutral-dark-gray lg:block">{driver.phone}</span>
                <a
                  href={`tel:${driver.phone}`}
                  onClick={(e) => e.stopPropagation()}
                  className="flex text-neutral-dark-gray text-primary lg:hidden"
                >
                  {driver.phone}
                </a>
              </Typography>
              {showWarning && (
                <Tooltip content={getDriverWarningTooltip("warning_vehicle_type_incorrect")} placement="bottom">
                  <i className="leading-tight">
                    <Icon name="Danger" size={18} variant="Bold" className="inline text-warning" />
                  </i>
                </Tooltip>
              )}
            </div>
            <IconButton
              onClick={handleAssignmentLog}
              iconName="Stickynote"
              variant="tertiary"
              className="p-0 text-info hover:bg-transparent"
            />
            {isDriverEditAllowed && (
              <IconButton
                onClick={handleEditMode}
                iconName="Edit2"
                variant="tertiary"
                iconSize="sm"
                className="p-0 text-info hover:bg-transparent"
              />
            )}
          </div>
        </>
      );

    return isDriverEditAllowed ? (
      <Button onClick={handleEditMode} startIcon="Add" variant="tertiary" size="sm" className="-ml-4 self-start text-info [&>span]:text-sm">
        Add driver
      </Button>
    ) : (
      <Typography variant="small">-</Typography>
    );
  };

  return (
    <>
      <div className="relative space-y-3">
        <div
          {...(isDriverEditAllowed && { onClick: handleEditMode })}
          className={clsx(
            "flex flex-col gap-1.5 rounded-md 2xl:flex-row 2xl:items-center 2xl:gap-3",
            isDriverEditAllowed && "!-mb-3 -mr-4 -translate-x-2  cursor-pointer px-2 hover:bg-neutral-gray"
          )}
        >
          <Typography variant="small" className="w-[60px] text-neutral-black/70">
            Driver:
          </Typography>
          {renderContent()}
        </div>
        {/* <div className="flex items-center  flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="w-[60px] text-neutral-black/70">
            ID:
          </Typography>
          <Typography variant="small" className="flex items-center gap-2 ">
            {driver?.id}
          </Typography>
        </div> */}
        {(!isOffloaded || isOwnJob) && (
          <div className="flex flex-col gap-1.5 xl:my-1 2xl:translate-y-1 2xl:flex-row 2xl:items-center">
            <Typography variant="small" className="w-[60px] text-neutral-black/70">
              Rating:
            </Typography>
            {driver?.rating ? (
              <Typography variant="small" className="flex items-center gap-2">
                {driver.rating.average || driver.rating.average === 0 ? `${driver.rating.average} ` : ""}({driver.rating.jobCount} Jobs)
                <Icon name="Star1" size={14} className="fill-warning text-warning" />
              </Typography>
            ) : <Typography variant="small" className="flex items-center gap-2">-</Typography>}
          </div>
        )}
      </div>
    </>
  );
};
