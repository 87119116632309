import { Skeleton, ErrorPanel } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetExternalSuppliersQuery } from "@/redux/apis/externalSupplier/externalSupplierApi";
import { useAppSelector } from "@/redux/hooks";
import { externalSuppliersSelector } from "@/redux/slices/externalSupplier/selectors";
import { ArchivedExternalSuppliersTable } from "./ArchivedExternalSuppliersTable";
import { useGetArchivedExternalSuppliersParams } from "./useGetArchivedExternalSuppliersParams";

export const ArchivedExternalSuppliersPanel = () => {
  const { params } = useGetArchivedExternalSuppliersParams();
  const { isFetching, error } = useGetExternalSuppliersQuery(params, { refetchOnMountOrArgChange: true });
  const externalSuppliers = useAppSelector(externalSuppliersSelector);

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;

  return (
    <>
      <ArchivedExternalSuppliersTable items={externalSuppliers} />
      <ArchivedExternalSuppliersTable.Pagination />
    </>
  );
};
