import { TypeFilter, TypeFilterDrawer } from "./filters/TypeFilter";
import { RatingFilter, RatingFilterDrawer } from "./filters/RatingFilter";
import { StateFilter, StateFilterDrawer } from "./filters/StateFilter";
import { StatusFilter, StatusFilterDrawer } from "./filters/StatusFilter";

export const getFilterPopover = (key: string) => {
  switch (key) {
    case "type":
      return <TypeFilter key={key} />;
    case "state":
      return <StateFilter key={key} />;
    case "rating":
      return <RatingFilter key={key} />;
    case "status":
      return <StatusFilter key={key} />;
    default:
      throw new Error(`Unknown filter key: ${key}`);
  }
};

export const getFilterDrawer = (key: string, asJSX = false) => {
  switch (key) {
    case "type":
      return asJSX ? <TypeFilterDrawer key={key} /> : TypeFilterDrawer;
    case "state":
      return asJSX ? <StateFilterDrawer key={key} /> : StateFilterDrawer;
    case "rating":
      return asJSX ? <RatingFilterDrawer key={key} /> : RatingFilterDrawer;
    case "status":
      return asJSX ? <StatusFilterDrawer key={key} /> : StatusFilterDrawer;
    default:
      throw new Error(`Unknown filter key: ${key}`);
  }
};
