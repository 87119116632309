import { useMemo } from "react";
import { useOperator, usePagination, useSearch, useSorting } from "@/hooks";
import { useExternalSuppliersFilters } from "../features/Filters/useExternalSuppliersFilters";
import { GetExternalSuppliersParams } from "@/redux/apis/externalSupplier/types";
import { ArchivedStatuses } from "../types";

export const useGetArchivedExternalSuppliersParams = () => {
  const { id: operatorId } = useOperator();
  const { page, pageSize } = usePagination("archived-external-suppliers");
  const [search, setSearch] = useSearch();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const { params: filterParams } = useExternalSuppliersFilters();
  const { account_status, ...filterParamsRest } = filterParams;

  const params: GetExternalSuppliersParams = useMemo(
    () => ({
      page,
      page_size: pageSize,
      sort: sortKey,
      direction,
      search,
      provider_uuid: operatorId,
      account_status: account_status ? account_status : ArchivedStatuses,
      ...filterParamsRest,
    }),
    [page, pageSize, sortKey, direction, search, operatorId, account_status, filterParamsRest]
  );

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    search,
    setSearch,
    operatorId,
    filters: filterParams,
  };
};
