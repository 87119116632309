import { Typography } from "@/components/atoms";
import { clsx, getAssetUrl } from "@/utils";

export interface StateCardProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  name: string;
  isActive?: boolean;
}

// TODO: Reuse this component in `src/components/pages/Config/Pricing/TripCost/TripCost.tsx`
export const StateCard = (stateCardProps: StateCardProps) => {
  const { onClick, className, image, name, isActive = false, ...props } = stateCardProps;

  return (
    <div onClick={onClick} className={clsx(className, { "border-primary": isActive })} {...props}>
      <div className="aspect-h-9 aspect-w-16 block w-full">
        <img src={getAssetUrl(image)} alt={name} className="object-cover" />
      </div>
      <div className={clsx("px-4 py-3", { "bg-primary-light text-primary-dark": isActive })}>
        <Typography className="flex items-center truncate" variant="action">
          {name}
        </Typography>
      </div>
    </div>
  );
};
