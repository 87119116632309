import { Icon, Skeleton, Tooltip, Typography } from "@/components/atoms";
import { ApplicationRuleEdit } from "./ApplicationRuleEdit";
import { ApplicationRuleView } from "./ApplicationRuleView";
import { ApplicationRulesSidebar } from "./ApplicationRulesSidebar";
import { ApplicationRulesProvider, useApplicationRulesContext } from "./context";

export const ApplicationRulesPanel = () => {
  return (
    <ApplicationRulesProvider>
      <header className="border-b border-neutral-mid-gray pb-6">
        <Typography variant="h3" className="flex items-center gap-2">
          Driver Application Rules
          <Tooltip
            content="Customize the questions to ensure drivers meet your specific operational requirements during the registration process."
            maxWidth={440}
            placement="right"
          >
            <Icon name="InfoCircle" variant="Bold" size="md" className="rotate-180 text-neutral-dark-gray" />
          </Tooltip>
        </Typography>
      </header>
      <Content />
    </ApplicationRulesProvider>
  );
};

const Content = () => {
  const { editMode, isFetching } = useApplicationRulesContext();

  if (isFetching) {
    return <Skeleton />;
  }
  return (
    <div className="flex gap-5 py-5">
      <ApplicationRulesSidebar />
      {editMode ? <ApplicationRuleEdit /> : <ApplicationRuleView />}
    </div>
  );
};
