import { useClose } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TextInput, Typography } from "@/components/atoms";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useClientsFilters } from "../useClientsFilters";

const schema = z.object({
  email: z.string(),
});

type FormData = z.infer<typeof schema>;

export const EmailFilter = () => {
  const {
    filters: { email },
    setFilter,
  } = useClientsFilters();

  const value = useMemo(() => email ?? "", [email]);

  const onClose = () => setFilter("email", undefined);

  return (
    <FilterPopover name="email" value={value} label="Email" onClose={onClose}>
      <EmailFilterForm />
    </FilterPopover>
  );
};

interface EmailFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const EmailFilterDrawer = ({ open, onOpenChange }: EmailFilterDrawerProps) => {
  const {
    filters: { email },
    setFilter,
  } = useClientsFilters();

  const value = useMemo(() => email ?? "", [email]);
  const onClose = () => setFilter("email", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Email" onClose={onClose}>
      <EmailFilterForm />
    </FilterDrawer>
  );
};

const EmailFilterForm = () => {
  const close = useClose();

  const { setFilter, filters } = useClientsFilters();

  const { watch, register } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { email: filters.email ? filters.email : "" },
  });

  const value = watch("email");

  useDebouncedEffect(
    () => {
      setFilter("email", value);
    },
    [value],
    300
  );

  return (
    <form
      className="contents"
      onSubmit={(event) => {
        event.preventDefault();
        close();
      }}
    >
      <Typography className="mb-4">Search Email</Typography>
      <TextInput {...register("email")} size="md" iconName="SearchNormal1" placeholder="Search Email" />
    </form>
  );
};
