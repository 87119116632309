import { useState } from "react";
import { Button, Icon, Typography } from "@/components/atoms";
import { ApplicationRuleIcon } from "./common/ApplicationRuleIcon";
import { useApplicationRulesContext } from "./context";
import { applicationRuleLabels } from "./fixtures";
import { ConfirmRemoveApplicationRule } from "./modals/ConfirmRemoveApplicationRule";
import { ApplicationRule } from "./types";

export const ApplicationRuleView = () => {
  const { activeRule: rule, setEditMode } = useApplicationRulesContext();
  const [pendingDelete, setPendingDelete] = useState<ApplicationRule | null>(null);

  const onEdit = () => {
    setEditMode(true);
  };
  const onDelete = () => {
    if (!rule) return;
    setPendingDelete(rule);
  };

  if (!rule) return null;

  return (
    <article className="flex-1 overflow-hidden rounded-lg shadow-lg">
      <header className="flex items-center bg-neutral-gray p-4">
        <div className="flex flex-1 gap-2">
          <ApplicationRuleIcon type={rule.type} className="bg-primary text-white" />
          <div className="flex min-w-0 flex-1 flex-col justify-center space-y-1">
            <Typography className="question truncate font-semibold leading-none text-neutral-black">
              {applicationRuleLabels[rule.type]}
            </Typography>
            {rule.isManaged && <Typography className="font-semibold leading-none text-neutral-dark-gray">Fixed Question</Typography>}
          </div>
        </div>
        <div className="flex shrink-0 items-center gap-2">
          {!rule.isManaged && (
            <Button onClick={onDelete} variant="primary" className="border-danger bg-danger hover:bg-danger-dark" size="sm">
              Delete Question
            </Button>
          )}
          <Button onClick={onEdit} variant="primary" size="sm">
            Edit Question
          </Button>
        </div>
      </header>
      <div className="h-full flex-1 bg-white px-5 py-6">
        <ViewContent rule={rule} />
      </div>
      {pendingDelete && <ConfirmRemoveApplicationRule rule={pendingDelete} open onClose={() => setPendingDelete(null)} />}
    </article>
  );
};

const ViewContent = ({ rule }: { rule: ApplicationRule }) => {
  const renderConditions = () => {
    if (!rule.conditions) return null;

    switch (rule.type) {
      case "select_multi":
      case "select_single":
        return (
          <>
            <fieldset>
              <Typography className="font-semibold text-neutral-dark-gray">Choices</Typography>
              {rule.conditions.options.map((option, index) => (
                <Typography key={index} variant="action" className="block">
                  {option}
                </Typography>
              ))}
            </fieldset>
            <fieldset>
              <Typography className="font-semibold text-neutral-dark-gray">Answers</Typography>
              <Typography variant="action">{rule.conditions.answers.join(", ")}</Typography>
            </fieldset>
          </>
        );
      case "bool":
        return (
          <fieldset>
            <Typography className="font-semibold text-neutral-dark-gray">Conditions (Answer)</Typography>
            <Typography variant="action">{rule.conditions.answer ? "Yes" : "No"}</Typography>
          </fieldset>
        );

      case "numeric":
        return (
          <>
            <fieldset>
              <Typography className="font-semibold text-neutral-dark-gray">Set Conditions (Minimum)</Typography>
              <Typography variant="action">{rule.conditions.answerMin}</Typography>
            </fieldset>
            <fieldset>
              <Typography className="font-semibold text-neutral-dark-gray">Set Conditions (Maximum)</Typography>
              <Typography variant="action">{rule.conditions.answerMax}</Typography>
            </fieldset>
          </>
        );
    }
  };

  return (
    <div className="space-y-6">
      <fieldset>
        <Typography className="font-semibold text-neutral-dark-gray">Question</Typography>
        <Typography variant="h3">{rule.question}</Typography>
      </fieldset>

      <fieldset>
        <Typography className="font-semibold text-neutral-dark-gray">Subtext (Optional)</Typography>
        <Typography variant="action">{rule.description || "-"}</Typography>
      </fieldset>

      {renderConditions()}

      <fieldset>
        <Typography className="font-semibold text-neutral-dark-gray">Display Question?</Typography>
        <Typography variant="action" className="flex items-center gap-2">
          {rule.isEnabled ? <Icon name="Eye" size="sm" /> : <Icon name="EyeSlash" size="sm" />}
          {rule.isEnabled ? "Show" : "Hide"}
        </Typography>
      </fieldset>

      <fieldset>
        <Typography className="font-semibold text-neutral-dark-gray">Required Question?</Typography>
        <Typography variant="action" className="flex items-center gap-2">
          {rule.isRequired ? <Icon name="TickCircle" variant="Bold" className="text-success" size="sm" /> : null}
          {rule.isRequired ? "Yes" : "No"}
        </Typography>
      </fieldset>
    </div>
  );
};
