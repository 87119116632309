import { useForm } from "react-hook-form";
import { Button, Loading, TextArea, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetFleetDriverQuery, useUpdateFleetStatusMutation } from "@/redux/apis/driver/driverApi";
import { addToast, parseHtml } from "@/utils";
import { ArchivedStatuses } from "../../../MyDrivers/types";
import { useAdminActionsContext } from "../context";
import { useEffect } from "react";

export const AdminNotes = () => {
  const { driver, isEditMode, setIsEditMode } = useAdminActionsContext();
  const [updateFleetDriver, { isLoading }] = useUpdateFleetStatusMutation();
  const { isFetching, data } = useGetFleetDriverQuery(driver.uuid, { refetchOnMountOrArgChange: true });
  const notes = data?.notes;
  const { register, handleSubmit, reset } = useForm<{ notes: string }>();
  const isArchived = ArchivedStatuses.includes(driver.status);

  useEffect(() => {
    reset({ notes: notes ? notes : "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes])

  const onSubmit = async (data: { notes: string }) => {
    updateFleetDriver({ id: driver.uuid, notes: data.notes })
      .unwrap()
      .then(() => {
        addToast("success", "Notes updated successfully");
        setIsEditMode(false);
      })
      .catch((e) => {
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  if (isEditMode) {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="relative mt-4 space-y-4 rounded-lg bg-neutral-gray p-4">
        {isLoading && <Loading />}
        <Typography variant="paragraph">
          Notes about {driver.personal.firstName} {driver.personal.lastName}
        </Typography>
        <TextArea {...register("notes")} placeholder="Add notes" rows={4} maxLength={4096} />
        <div className="flex w-full items-center justify-end gap-3">
          <Button onClick={() => setIsEditMode(false)} variant="secondary" className="!bg-transparent" size="sm">
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading} variant="primary" size="sm">
            Save
          </Button>
        </div>
      </form>
    );
  }

  return (
    <div className="relative flex flex-col gap-4 mt-4 p-4 rounded-lg bg-neutral-gray">
      {isFetching && <Loading />}
      <Typography variant="title">
        Notes about {driver.personal.firstName} {driver.personal.lastName}
      </Typography>
      {notes && <Typography variant="paragraph">{parseHtml(notes)}</Typography>}
      {!isArchived && (
        <div className="flex">
          <Button onClick={() => setIsEditMode(true)} startIcon={notes ? "Edit2" : "Add"} variant="secondary" className="!bg-transparent" size="sm">
            {notes ? "Update" : "Add Notes"}
          </Button>
        </div>
      )}
    </div>
  );
};
