
import { Typography } from "@/components/atoms";
import { ArchivedExternalSuppliersTableData } from "./types";
import { DataTableColumnDef } from "@/components/molecules";
import { accountTypes } from "../types";
import { ExternalSupplier } from "@/redux/slices/externalSupplier/types";
import { renderAvatar, renderRating, renderStatus } from "../helpers";

export const generateArchivedExternalSuppliersTableColumns = () => {
  const columns: DataTableColumnDef<ArchivedExternalSuppliersTableData>[] = [
    { 
      accessorKey: "name", 
      header: "Name", 
      cell: (props) => props.row.original.name, 
      id: "name", 
      size: 300 
    },
    {
      accessorKey: "type",
      header: "Type",
      cell: (props) => props.row.original.type,
      enableSorting: false,
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: (props) => props.row.original.email,
      id: "email",
      size: 370,
    },
    {
      accessorKey: "phone",
      header: "Phone",
      cell: (props) => props.row.original.phone,
      enableSorting: false,
      size: 250,
    },
    {
      accessorKey: "state",
      header: "State",
      cell: (props) => props.row.original.state,
      enableSorting: false,
    },
    {
      accessorKey: "rating",
      header: "Rating",
      cell: (props) => props.row.original.rating,
      id: "rating",
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
      size: 250,
    },
  ];

  return columns;
};

export const generateArchivedExternalSuppliersTableData = (items: ExternalSupplier[], onManageClick: (id: string) => void) => {
  const data = items.map(
    (i) =>
      ({
        name: (
          <div className="flex cursor-pointer items-center gap-2.5 hover:text-info" onClick={() => onManageClick(i.id)}>
            {renderAvatar(i.avatar, i.name)}
            <div className="flex flex-col">
              <Typography variant="paragraph">{i.name}</Typography>
            </div>
          </div>
        ),
        type: accountTypes[i.type],
        email: i.email,
        phone: i.phone,
        state: i.state,
        rating: renderRating(i.rating),
        status: renderStatus(i.status),
      } as ArchivedExternalSuppliersTableData)
  );

  return data;
};