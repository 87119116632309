import { useWindowSize } from "@react-hookz/web";
import { saveAs } from "file-saver";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, DropdownMenu, Icon, IconButton, Loading, SearchInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePagination } from "@/hooks";
import { useLazyExportClientsQuery  } from "@/redux/apis/reporting/reportingApi";
import { addToast, clsx } from "@/utils";
import { useGetClientsParams } from "../useGetClientsParams";
import { ClientsFilters } from "../features/Filters/ClientsFilters";
import Template from "@/assets/templates/upload-bulk-clients-template.xlsx";
import { useClientsContext } from "../context";

export const ClientsActionMenu = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [exportClients, { isFetching }] = useLazyExportClientsQuery();
  const { setStep } = useClientsContext();
  const { search, setSearch } = useGetClientsParams();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const { clearPagination } = usePagination("clients");

  const handleSearch = (value?: string) => {
    if (!value) setIsSearchActive(false);

    clearPagination();
    setSearch(value);
  };

  const handleExportClients = (type: "active" | "full") => {
    exportClients({ report_type: type })
      .unwrap()
      .then((res) => saveAs(res.data.path, "clients"))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  if (!isSearchActive && !search) {
    return (
      <div className="flex flex-1 items-center justify-end gap-1 xl:gap-2 ">
        <Button
          className="max-md:[&>span]:hidden"
          variant="tertiary"
          size="sm"
          startIcon="SearchNormal1"
          onClick={() => setIsSearchActive(true)}
        >
          Search
        </Button>
        <FilterButton />
        <DropdownMenu
          button={
            <div className="relative">
              {isFetching && <Loading />}
              {width >= 768 ? (
                <DropdownMenu.Button as={Button} startIcon="Add" isDropdown size="sm" className="w-full">
                  Actions
                </DropdownMenu.Button>
              ) : (
                <DropdownMenu.Button as={IconButton} iconName="options" isCustomIcon variant="primary" size="sm" className="fill-white" />
              )}
            </div>
          }
          isCustomButton
          position="bottom-start"
        >
          <DropdownMenu.Item onClick={() => navigate("add")} className="w-[298px]">
            <Icon name="Add" className="mr-2.5 text-primary-dark" size="sm" />
            <Typography className="text-primary-dark">Add Client</Typography>
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => setStep("import")} className="w-[298px]">
            <Icon name="DocumentUpload" className="mr-2.5 text-primary-dark" size="sm" />
            <Typography className="text-primary-dark">Upload Client List</Typography>
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => saveAs(Template, "upload-bulk-clients-template")} className="w-[298px]">
            <Icon name="excel" isCustom className="mr-2.5 text-primary-dark" size="sm" />
            <Typography className="text-primary-dark">Download Template</Typography>
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => handleExportClients("active")} className="w-[298px] text-primary-dark">
            <Icon name="Export" className="mr-2.5" size="sm" />
            <Typography>Active Client Export</Typography>
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => handleExportClients("full")} className="w-[298px] text-primary-dark">
            <Icon name="Export" className="mr-2.5" size="sm" />
            <Typography>Full Export</Typography>
          </DropdownMenu.Item>
        </DropdownMenu>
      </div>
    );
  }

  return (
    <div className="flex flex-1 items-center justify-end gap-2">
      <SearchInput
        search={search}
        onSearch={handleSearch}
        autoFocus
        placeholder="Search Clients"
        className={clsx("w-full max-w-sm p-0 sm:w-fit [&>input]:transition-size", isSearchActive && "[&>input]:w-[280px]")}
        size="sm"
        onClear={() => setIsSearchActive(false)}
      />
    </div>
  );
};

const FilterButton = () => {
  const { width } = useWindowSize();

  return width >= 768 ? <ClientsFilters.Button /> : <ClientsFilters.DrawerButton />;
};
