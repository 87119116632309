import { differenceInWeeks, format } from "date-fns";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Icon, IconButton, Typography } from "@/components/atoms";
import { useOperator, useProfile } from "@/hooks";
import { useLazyRequestVehicleDocumentQuery } from "@/redux/apis/document/documentApi";
import { downloadFile } from "@/utils";
import { getAccreditationStatus } from "../../MyVehicles/helpers";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const VehicleAccreditationDetailCard = () => {
  const navigate = useNavigate();
  const { id: operatorId } = useOperator();
  const { dateFormat } = useProfile();
  const { vehicle } = useViewVehicleContext();
  const [requestDocument, { isLoading }] = useLazyRequestVehicleDocumentQuery();

  const status = getAccreditationStatus(vehicle);
  const accreditationDoc = vehicle.documents.find((doc) => doc.documentType === "ACCREDITATION");

  const isAffiliateVehicle = vehicle.owner.uuid !== operatorId;
  const isActionsDisabled = vehicle.metadata.isArchived || isAffiliateVehicle;

  const tooltip = useMemo(() => {
    if (!accreditationDoc || !accreditationDoc.expiryDate) return undefined;

    switch (status) {
      case "expiring": {
        const expiryDate = new Date(accreditationDoc.expiryDate);
        const difference = differenceInWeeks(expiryDate, new Date(), {
          roundingMethod: "ceil",
        });

        return (
          <Typography className="leading-tight">
            Vehicle accreditation expiring in {difference} {difference === 1 ? "week" : "weeks"}. <br /> Kindly update the accreditation
          </Typography>
        );
      }

      case "expired":
        return <Typography className="leading-tight">Vehicle accreditation expired.</Typography>;

      case "verified":
      default:
        return undefined;
    }
  }, [accreditationDoc, status]);

  const handleDownload = () => {
    if (!accreditationDoc) return;

    requestDocument({
      id: accreditationDoc.uuid,
      fileType: "pdf", //todo: get file type from document
      vehicleId: vehicle.uuid,
    })
      .unwrap()
      .then((res) => {
        downloadFile(res.filename, res.uuid);
      });
  };

  const ActionButton = () => {
    if (isActionsDisabled) return null;

    switch (status) {
      case "expiring":
      case "expired":
        return <IconButton onClick={() => navigate("./edit")} iconName="Refresh2" variant="primary" size="sm" className="relative z-10" />;
      case "verified":
        return accreditationDoc ? (
          <IconButton
            disabled={isLoading}
            iconName="ImportCurve"
            className="!bg-transparent p-1.5 text-neutral-black"
            iconSize="lg"
            variant="tertiary"
            onClick={handleDownload}
          />
        ) : null;
      default:
        return null;
    }
  };

  const description = accreditationDoc?.expiryDate
    ? `${status === "expired" ? "Expired" : "Expires"} on: ${format(new Date(accreditationDoc.expiryDate), dateFormat)}`
    : "N/A";

  return (
    <VehicleDetailCard
      title={
        <>
          Vehicle Accreditation
          <StatusBadge status={status} />
        </>
      }
      description={description}
      icon={<Icon name="file-pdf" isCustom size="xl" className="text-neutral-black" />}
      action={<ActionButton />}
      actionClick={accreditationDoc ? handleDownload : undefined}
      tooltip={tooltip}
      status={status}
    />
  );
};

const StatusBadge = ({ status }: { status: string }) => {
  switch (status) {
    case "expiring":
      return <Icon name="Danger" size="md" className="text-warning" variant="Bold" />;
    case "expired":
      return <Icon name="InfoCircle" size="md" className=" text-danger" variant="Bold" />;
    case "unknown":
    case "missing-details":
    case "verified":
    default:
      return null;
  }
};
