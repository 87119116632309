import { Container, Skeleton } from "@/components/atoms";
import { ClientsActionMenu, ClientsImportPanel, ClientsModals, PageHeaderV2 } from "@/components/organisms";
import { useAppSelector } from "@/redux/hooks";
import { clientsSelector } from "@/redux/slices/client/selectors";
import { useGetClientsParams } from "@/components/organisms/Clients/useGetClientsParams";
import { ClientsTable } from "@/components/organisms/Clients/ClientsTable";
import { useGetClientsQuery } from "@/redux/apis/client/clientApi";
import { ClientsFilters } from "./features/Filters/ClientsFilters";
import { useClientsContext } from "./context";

export const ClientsPanel = () => {
  const { step, setStep } = useClientsContext();
  const { params } = useGetClientsParams();
  const { isFetching, refetch } = useGetClientsQuery(params, { refetchOnMountOrArgChange: true });
  const clients = useAppSelector(clientsSelector);

  return (
    <Container className="flex flex-1 flex-col w-full mb-12">
      <PageHeaderV2 name="Clients" skipCrumbs={1}>
        <ClientsActionMenu />
      </PageHeaderV2>
      <ClientsFilters.ActiveFilters className="mt-2" />
      {isFetching ? (
        <Skeleton />
      ) : (
        <div className="mt-2">
          <ClientsTable items={clients} />
          <ClientsTable.Pagination />
        </div>
      )}
      <ClientsModals />
      <ClientsImportPanel open={step === "import"} onClose={() => setStep(undefined)} refetchClients={() => refetch()} />
    </Container>
  );
};
