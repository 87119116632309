import { formatInTimeZone } from "date-fns-tz";
import { LinkButton, Stars, Tooltip, Typography } from "@/components/atoms";
import { BookingStatusIcon } from "@/components/organisms/Bookings/common/BookingStatusIcon/BookingStatusIcon";
import { statusMapping } from "@/components/organisms/Bookings/fixtures";
import { getBookingDetailsUrl } from "@/helpers/bookingHelpers";
import { DriverBooking } from "@/redux/apis/driver/types";
import { Profile } from "@/redux/slices/profile/types";
import { JobHistoryTableData } from "./types";

export const generateJobHistoryTableData = (bookings: DriverBooking[], profile: Profile) => {
  const data = bookings.map(
    (booking) =>
      ({
        bookingId: booking.id,
        id: (
          <>
            <div className="group/cell flex items-center gap-2 overflow-ellipsis">
              <Typography
                variant="paragraph"
                className="shrink-0 leading-tight group-hover/cell:text-info group-[.compact-view]:flex sm:leading-none"
              >
                booking.jobNumber
              </Typography>
            </div>
            <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
              ID
            </Typography>
          </>
        ),
        date: (
          <>
            <Typography className="leading-tight">
              {formatInTimeZone(new Date(booking.pickupTime.dateTime), booking.pickupTime.tz, profile.dateFormat)}
            </Typography>
            <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
              Date
            </Typography>
          </>
        ),
        time: (
          <>
            <div className="flex group-[.compact-view]:items-center group-[.compact-view]:justify-normal sm:flex-col sm:justify-center group-[.compact-view]:sm:flex-row">
              <Typography className="whitespace-nowrap leading-tight ">
                {formatInTimeZone(new Date(booking.pickupTime.dateTime), booking.pickupTime.tz, profile.timeFormat)}
              </Typography>
              {/* {profile.timezone.name !== booking.pickupTime.tz && (
                <Typography variant="small" className="whitespace-nowrap leading-snug text-neutral-dark-gray">
                  <span className="hidden group-[.compact-view]:hidden sm:inline"> ({booking.pickupTime.tz.split("/")[1]})</span>
                  <Tooltip
                    content={formatInTimeZone(new Date(booking.pickupTime.date), profile.timezone.name, `${profile.timeFormat} zzzz`)}
                    placement="bottom"
                  >
                    <Icon name="InfoCircle" variant="Bold" size="md" className="ml-1 inline rotate-180" />
                  </Tooltip>
                </Typography>
              )} */}
            </div>
            <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
              Time
            </Typography>
          </>
        ),
        operator: <Typography className="group-[.compact-view]:truncate">{booking.provider?.name}</Typography>,
        rating: renderRating(booking.rating?.bookingExperience),
        status: renderBookingStatus(booking.jobStatus),
        actions: (
          <LinkButton
            to={getBookingDetailsUrl(booking.provider.providerUuid, booking.id, "").replace("/admin/", "")}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            startIcon="ExportSquare"
            variant="custom"
          />
        ),
      } as JobHistoryTableData)
  );

  return data;
};

const renderRating = (rating?: number | null) => <Stars value={rating ? rating : 0} count={5} edit={false} size={24} color1="#D1D2D5" />;

const renderBookingStatus = (status: DriverBooking["jobStatus"]) => {
  const statusObj = statusMapping[status];

  return (
    <div>
      <div className="flex items-center gap-1.5 2xl:gap-2 ">
        <Tooltip content={statusObj.name} placement="top">
          <i>
            <BookingStatusIcon
              status={{
                color: statusObj.color,
                icon: statusObj.icon,
              }}
            />
          </i>
        </Tooltip>
        <Typography variant="paragraph" className="leading-tight">
          {statusObj.legend}
        </Typography>
      </div>
      <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
        Status
      </Typography>
    </div>
  );
};
