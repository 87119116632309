import { Page } from "@/redux/types";

export type ClientState = {
  clients: Client[];
  page: Page;
  options: ClientsOptions;
};

export type Client = {
  id: string;
  name: string;
  email: string;
  phone: string;
  companyId: string;
  companyName: string;
  accountType: string;
  hearAboutUs: string;
  joined: string;
  status: string;
};

export type ClientsOptions = {
  types: Array<{ name: string; value: string }>;
  statuses: Array<{ name: string; value: string }>;
};

export type ClientSearchItem = {
  id: string;
  name: string;
  email: string;
  phone: string;
  company: {
    id: string;
    name: string;
  } | null;
  avatar: string | null;
};

export const clientOptionsInitialState = {
  types: [],
  statuses: [],
};
