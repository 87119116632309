import { useMemo } from "react";
import { DataTable, Pagination } from "@/components/molecules";
import { usePagination, useProfile, useSorting } from "@/hooks";
import { DriverBooking } from "@/redux/apis/driver/types";
import { Page } from "@/redux/types";
import { clsx } from "@/utils";
import { jobHistoryTableColumns } from "./fixtures";
import { generateJobHistoryTableData } from "./helpers";

export interface JobHistoryTableProps extends React.ComponentPropsWithoutRef<"div"> {
  items: DriverBooking[];
}

export const JobHistoryTable = ({ items, ...props }: JobHistoryTableProps) => {
  const profile = useProfile();
  const { sorting, setSorting } = useSorting();

  const data = useMemo(() => generateJobHistoryTableData(items, profile), [items, profile]);

  const rowClass =
    "[&_.tr]:border-bottom-1 [&_.tr]:px-2 xl:[&_.tr]:px-0 [&_.tr]:grid sm:[&_.tr]:flex [&_.tr]:gap-0 [&_.tr]:lg:gap-3 xl:[&_.tr]:gap-0 [&_.tr]:grid-cols-4 [&_.tr.subcomponent]:block [&_.tr.subcomponent]:!bg-primary-light";
  const cellClass =
    "max-sm:[&_.td]:!w-auto [&_.td]:flex-col sm:[&_.td]:flex-row max-sm:[&_.td]:items-start [&_.td]:p-2 sm:[&_.td]:p-2 lg:[&_.td]:px-3 lg:[&_.td]:py-2 [&_tr.subcomponent_.td]:block [&_tr.subcomponent_.td]:w-full";

  return (
    <DataTable
      className={clsx("mt-5", rowClass, cellClass)}
      columns={jobHistoryTableColumns}
      data={data}
      sorting={sorting}
      setSorting={setSorting}
      {...props}
    />
  );
};

const JobHistoryTablePagination = ({ page }: { page: Page }) => {
  const { current, count, total, size } = page;
  const { handlePageClick, handleSizeChange } = usePagination("driver-jobs");

  return (
    <Pagination
      className="my-4 max-lg:mb-16"
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      pageSizes={[12, 100, 200]}
      totalItems={total}
      pageCount={count}
    />
  );
};

JobHistoryTable.Pagination = JobHistoryTablePagination;
