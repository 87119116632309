import { differenceInWeeks } from "date-fns";
import { Icon, Tooltip, Typography } from "@/components/atoms";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { clsx } from "@/utils";
import { getRegistrationStatus } from "../helpers";
import { VehicleRegistrationStatus } from "../types";

interface VehicleStatusBadgeProps {
  vehicle: Vehicle;
  status?: VehicleRegistrationStatus;
  size?: "sm" | "md" | "lg";
  className?: string;
  children?: React.ReactNode;
}

export const VehicleStatusBadge = ({ status: givenStatus, vehicle, size = "md", className, children }: VehicleStatusBadgeProps) => {
  const status = getRegistrationStatus(vehicle);

  switch (givenStatus || status) {
    case "expired":
      return (
        <Typography variant="action" className={clsx("inline-flex items-center text-danger", className)}>
          <Tooltip
            content={
              <Typography className="leading-tight">
                Vehicle document expired.
                <br /> This vehicle is unable
                <br /> to perform network jobs
              </Typography>
            }
            placement="top"
          >
            <Icon name="InfoCircle" variant="Bold" size={size} className="mr-1 text-danger" />
          </Tooltip>
          {children}
        </Typography>
      );
    case "expiring": {
      const expiryDate = vehicle.registration.expiryDate ? new Date(vehicle.registration.expiryDate) : null;
      const expiringInWeeks = expiryDate
        ? differenceInWeeks(expiryDate, new Date(), {
            roundingMethod: "ceil",
          })
        : 0;

      const insuranceExpiryDate = vehicle.documents.find((doc) => doc.documentType === "INSURANCE")?.expiryDate;
      const insuranceExpiry = insuranceExpiryDate ? new Date(insuranceExpiryDate) : null;
      const insuranceExpiringInWeeks = insuranceExpiry
        ? differenceInWeeks(insuranceExpiry, new Date(), {
            roundingMethod: "ceil",
          })
        : 0;

      const displayCount = expiringInWeeks > 0 && expiringInWeeks <= 6 ? expiringInWeeks : insuranceExpiringInWeeks;

      return (
        <Typography variant="action" className={clsx("inline-flex items-center text-warning", className)}>
          <Tooltip content={`Vehicle document is expiring in ${displayCount} ${displayCount === 1 ? "week" : "weeks"}`} placement="top">
            <Icon name="Verify" variant="Bold" size={size} className="mr-1 text-warning" />
          </Tooltip>
          {children}
        </Typography>
      );
    }

    case "verified": {
      const content = (
        <Typography>
          Vehicle is verified. {""}
          <a href="#" className="underline">
            Learn about Vehicle Verification
          </a>
        </Typography>
      );

      return (
        <Typography variant="action" className={clsx("inline-flex items-center text-info", className)}>
          <Tooltip content={content} placement="top">
            <Icon name="Verify" variant="Bold" size={size} className="mr-1 text-info" />
          </Tooltip>
          {children}
        </Typography>
      );
    }

    case "unverified":
    case "unknown":
      return (
        <Typography variant="action" className={clsx("inline-flex items-center text-neutral-dark-gray", className)}>
          <Tooltip content="Vehicle is unverified" placement="top">
            <Icon name="Verify" variant="Bold" size={size} className="mr-1 text-neutral-mid-gray" />
          </Tooltip>
          {children}
        </Typography>
      );

    case "missing-details":
      return (
        <Typography variant="action" className={clsx("inline-flex items-center text-neutral-dark-gray", className)}>
          <Tooltip content="Vehicle missing key details. Needs to be updated" placement="top">
            <Icon name="InfoCircle" variant="Bold" size={size} className="mr-1 text-danger" />
          </Tooltip>
          {children}
        </Typography>
      );

    default:
      return null;
  }
};
