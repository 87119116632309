import { TypeFilter, TypeFilterDrawer } from "./filters/TypeFilter";
import { StatusFilter, StatusFilterDrawer } from "./filters/StatusFilter";
import { CompanyFilter, CompanyFilterDrawer } from "./filters/CompanyFilter";
import { EmailFilter, EmailFilterDrawer } from "./filters/EmailFilter";
import { PhoneNumberFilter, PhoneNumberFilterDrawer } from "./filters/PhoneNumberFilter";

export const getFilterPopover = (key: string) => {
  switch (key) {
    case "company":
      return <CompanyFilter key={key} />;
    case "email":
      return <EmailFilter key={key} />;
    case "phoneNumber":
      return <PhoneNumberFilter key={key} />;
    case "type":
      return <TypeFilter key={key} />;
    case "status":
      return <StatusFilter key={key} />;
    default:
      throw new Error(`Unknown filter key: ${key}`);
  }
};

export const getFilterDrawer = (key: string, asJSX = false) => {
  switch (key) {
    case "company":
      return asJSX ? <CompanyFilterDrawer key={key} /> : CompanyFilterDrawer;
    case "email":
      return asJSX ? <EmailFilterDrawer key={key} /> : EmailFilterDrawer;
    case "phoneNumber":
      return asJSX ? <PhoneNumberFilterDrawer key={key} /> : PhoneNumberFilterDrawer;
    case "type":
      return asJSX ? <TypeFilterDrawer key={key} /> : TypeFilterDrawer;
    case "status":
      return asJSX ? <StatusFilterDrawer key={key} /> : StatusFilterDrawer;
    default:
      throw new Error(`Unknown filter key: ${key}`);
  }
};