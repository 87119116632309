import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty } from "ramda";
import { useMemo, useState } from "react";
import { FieldErrors, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AddOnTextInput, Alert, Button, ErrorMessage, Loading, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePrompt } from "@/hooks/usePrompt";
import { useDeleteStateBasedDynamicPricingMutation, useUpdateStateBasedDynamicPricingMutation } from "@/redux/apis/config/pricing/tripCost";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setDynamicPricingAction } from "@/redux/slices/pricing/pricingSlice";
import { dynamicPricingPageStateSelector } from "@/redux/slices/pricing/selectors";
import { DynamicPricingState } from "@/redux/slices/pricing/types";
import { addToast, clsx, getAssetUrl, getCostDisplay, getCurrencySymbol } from "@/utils";
import { DynamicPricingEditorModals } from "./DynamicPricingEditorModals";
import { IntervalRow } from "./IntervalRow";
import { dynamicPricingFormSchema } from "./fixtures";
import { getPriceRanges, validateIntervals } from "./helpers";
import { DynamicPricingFormData } from "./types";

interface StateBasedDynamicPricingEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  pricing: DynamicPricingState;
}

export const StateBasedDynamicPricingEditor = ({ pricing, className, ...props }: StateBasedDynamicPricingEditorProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { baseInterval, intervals, state, name, background, currency, distanceUnit } = pricing;
  const currencySymbol = getCurrencySymbol(currency) || "";
  const [selectedInterval, setSelectedInterval] = useState<number | null>(null);
  const { action } = useAppSelector(dynamicPricingPageStateSelector);

  const [updateStateBasedPricing, { isLoading: isLoadingUpdate }] = useUpdateStateBasedDynamicPricingMutation();
  const [deleteStateBasedPricing, { isLoading: isLoadingDelete }] = useDeleteStateBasedDynamicPricingMutation();
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, errors },
  } = useForm<DynamicPricingFormData>({
    defaultValues: {
      baseInterval: {
        minimumRange: baseInterval?.minimumRange || 0,
        maximumRange: 0,
        cost: baseInterval?.cost || 0,
        note: baseInterval?.note || "",
      },
      intervals: intervals.map((interval) => ({
        minimumRange: interval.minimumRange || 0,
        rate: interval.rate || 0,
        note: interval.note || "",
      })),
    },
    resolver: zodResolver(dynamicPricingFormSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "intervals",
  });
  const values = watch();
  const priceRanges = useMemo(() => getPriceRanges(values), [values]);

  usePrompt({ when: isDirty && action !== "exit", message: "Changes will not be saved. Do you want to proceed?" });

  const onSubmit = handleSubmit((data) => {
    const isValid = validateIntervals([data.baseInterval.minimumRange, ...data.intervals.map((interval) => interval.minimumRange)]);
    if (isValid) {
      updateStateBasedPricing({
        state,
        base_interval: {
          range_from: data.baseInterval.minimumRange,
          range_to: isEmpty(fields) ? data.baseInterval.maximumRange : data.intervals[0].minimumRange,
          interval_cost: data.baseInterval.cost * 100,
          note: data.baseInterval.note,
        },
        intervals: isEmpty(fields)
          ? []
          : data.intervals.map((i) => ({
              range_from: i.minimumRange,
              rate: i.rate * 100,
              note: i.note,
            })),
      })
        .unwrap()
        .then(() => {
          addToast("success", "Successfully Updated State Based Dynamic Pricing");
          navigate("../");
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    }
  });

  const handleDeleteDynamicPricing = () => {
    dispatch(setDynamicPricingAction(undefined));
    deleteStateBasedPricing({ state })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Reset Trip Costs");
        navigate("../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const handleAddRow = () => {
    append({
      minimumRange:
        values.intervals.length > 0
          ? Number(values.intervals[values.intervals.length - 1].minimumRange) + 1
          : Number(values.baseInterval.minimumRange) + 1,
      rate: 0,
      note: "",
    });
  };

  const handleConfirmDelete = (i: number) => {
    setSelectedInterval(i);
    dispatch(setDynamicPricingAction("delete"));
  };

  const handleDeleteRow = () => {
    if (selectedInterval != null) {
      remove(selectedInterval);
      dispatch(setDynamicPricingAction(undefined));
    }
  };

  const handleConfirmAddNote = (i: number) => {
    setSelectedInterval(i);
    dispatch(setDynamicPricingAction("addNote"));
  };

  const handleAddNote = (note: string) => {
    if (selectedInterval != null) {
      if (selectedInterval > -1) {
        setValue(`intervals.${selectedInterval}.note`, note, { shouldDirty: true });
      } else {
        setValue("baseInterval.note", note, { shouldDirty: true });
      }
    }

    dispatch(setDynamicPricingAction(undefined));
  };

  const getSelectedIntervalNote = (): string => {
    if (selectedInterval != null) {
      if (selectedInterval < 0) {
        return values.baseInterval.note;
      } else if (selectedInterval < fields.length) {
        return values.intervals[selectedInterval].note;
      }
    }

    return "";
  };

  const renderRows = () => {
    return fields.map((interval, i) => {
      return (
        <IntervalRow
          key={interval.id}
          label={`Interval ${i + 1}`}
          note={values.intervals[i].note}
          renderRange={() => (
            <div className="flex flex-col gap-y-1">
              <Typography variant="small" className="text-neutral-dark-gray">
                Range from - to
              </Typography>
              <div className="flex flex-col gap-x-2 gap-y-2 md:flex-row lg:flex-row">
                <div className="flex flex-row items-center gap-x-2">
                  <TextInput
                    type="number"
                    size="sm"
                    className="w-[51px] text-center"
                    disabled
                    value={Number(values.intervals[i].minimumRange)}
                  />
                  <Typography variant="small" className="text-neutral-dark-gray">
                    -
                  </Typography>
                </div>
                {i + 1 === fields.length ? (
                  <AddOnTextInput endAddOn={distanceUnit} size="sm" className="w-[115px] [&_input]:text-right" disabled value="∞" />
                ) : (
                  <AddOnTextInput
                    endAddOn={distanceUnit}
                    type="number"
                    size="sm"
                    className="w-[115px] [&_input]:text-right"
                    step="any"
                    {...register(`intervals.${i + 1}.minimumRange`)}
                  />
                )}
              </div>
            </div>
          )}
          renderCost={() => (
            <div className="flex flex-col gap-y-1">
              <Typography variant="small" className="text-neutral-dark-gray">
                Rate / {distanceUnit}
              </Typography>
              <AddOnTextInput
                startAddOn={`${currency} ${currencySymbol}`}
                type="number"
                size="sm"
                className="w-[145px]"
                hasError={!!errors.intervals?.[i]?.rate}
                step="any"
                {...register(`intervals.${i}.rate`)}
              />
              <Typography variant="small" className="text-neutral-dark-gray">
                {`Interval Range ${getCostDisplay(priceRanges[i + 1].min, currencySymbol)} - ${
                  priceRanges[i + 1].max > 0 ? getCostDisplay(priceRanges[i + 1].max, "") : "∞"
                }`}
              </Typography>
            </div>
          )}
          renderErrors={() => {
            const intervalError = errors.intervals?.[i];
            const nextIntervalError = errors.intervals?.[i + 1];
            return intervalError || nextIntervalError ? (
              <div className="flex flex-col">
                {nextIntervalError && <ErrorMessage errors={nextIntervalError as FieldErrors} name="minimumRange" label="Maximum Range" />}
                {intervalError && <ErrorMessage errors={intervalError as FieldErrors} name="rate" label={`Rate / ${distanceUnit}`} />}
              </div>
            ) : null;
          }}
          onAddNote={() => handleConfirmAddNote(i)}
          onDelete={() => handleConfirmDelete(i)}
        />
      );
    });
  };

  return (
    <div className={clsx("relative", className)} {...props}>
      {(isLoadingUpdate || isLoadingDelete) && <Loading />}
      <form onSubmit={onSubmit}>
        <div className="flex items-center">
          <div className="flex flex-1 items-center gap-x-4">
            <img src={getAssetUrl(background)} className="h-12 w-12 shrink-0 rounded-full object-cover" />
            <Typography variant="h3" className="leading-8">
              {name}
            </Typography>
          </div>
          <div className="flex flex-row gap-x-4">
            <Button
              variant="secondary"
              onClick={() => {
                isDirty ? dispatch(setDynamicPricingAction("exit")) : navigate("../");
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={!isDirty}>
              Save
            </Button>
          </div>
        </div>
        <Alert
          type="info"
          message="Interval based pricing - set as many or as little intervals as you wish & input details of your most frequent trips to get an estimate of the base trip cost."
          className="mt-6"
          hasClose
        />
        <div className="mt-4 flex flex-col gap-y-4">
          <IntervalRow
            label="Minimum Cost"
            note={values.baseInterval.note}
            renderRange={() => (
              <div className="flex flex-col gap-y-1">
                <Typography variant="small" className="text-neutral-dark-gray">
                  Range from - to
                </Typography>
                <div className="flex flex-col gap-x-2 gap-y-2 md:flex-row lg:flex-row">
                  <div className="flex flex-row items-center gap-x-2">
                    <TextInput
                      type="number"
                      size="sm"
                      className="w-[51px] text-center"
                      disabled
                      {...register("baseInterval.minimumRange")}
                    />
                    <Typography variant="small" className="text-neutral-dark-gray">
                      -
                    </Typography>
                  </div>
                  {isEmpty(fields) ? (
                    <AddOnTextInput endAddOn={distanceUnit} size="sm" className="w-[115px] [&_input]:text-right" disabled value="∞" />
                  ) : (
                    <AddOnTextInput
                      endAddOn={distanceUnit}
                      type="number"
                      size="sm"
                      className="w-[115px] [&_input]:text-right"
                      hasError={!!errors.intervals?.[0]?.minimumRange}
                      {...register("intervals.0.minimumRange")}
                    />
                  )}
                </div>
              </div>
            )}
            renderCost={() => (
              <div className="flex flex-col gap-y-1">
                <Typography variant="small" className="text-neutral-dark-gray">
                  Minimum Fare
                </Typography>
                <AddOnTextInput
                  startAddOn={`${currency} ${currencySymbol}`}
                  type="number"
                  size="sm"
                  className="w-[145px]"
                  hasError={!!errors.baseInterval?.cost}
                  step="any"
                  {...register("baseInterval.cost")}
                />
              </div>
            )}
            renderErrors={() => {
              const intervalError = errors.baseInterval;
              const nextIntervalError = errors.intervals?.[0];
              return intervalError || nextIntervalError ? (
                <div className="flex flex-col">
                  {nextIntervalError && (
                    <ErrorMessage errors={nextIntervalError as FieldErrors} name="minimumRange" label="Maximum Range" />
                  )}
                  {intervalError && <ErrorMessage errors={intervalError as FieldErrors} name="cost" label="Minimum Fare" />}
                </div>
              ) : null;
            }}
            onAddNote={() => handleConfirmAddNote(-1)}
          />
          {renderRows()}
        </div>
        <div className="mt-6 flex flex-row">
          <div className="flex-1">
            <Button variant="primary" startIcon="Add" size="sm" onClick={handleAddRow}>
              Add New Interval
            </Button>
          </div>
          <Button
            variant="secondary"
            className="text-danger enabled:!border-danger"
            size="sm"
            onClick={() => dispatch(setDynamicPricingAction("resetTripCosts"))}
            disabled={!baseInterval}
          >
            Reset Trip Costs
          </Button>
        </div>
      </form>
      <DynamicPricingEditorModals
        selectedInterval={selectedInterval}
        selectedIntervalNote={getSelectedIntervalNote()}
        onDeleteRow={() => handleDeleteRow()}
        onDeleteDynamicPricing={() => handleDeleteDynamicPricing()}
        onAddNote={(note) => handleAddNote(note)}
      />
    </div>
  );
};
