import { useEffect, useState } from "react";
import { Alert, Button, Panel, Typography, Skeleton, DropdownSelect } from "@/components/atoms";
import { ExportReportPanel, PageHeader, StateCard } from "@/components/organisms";
import { useLazyGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { CountryState } from "@/redux/slices/resource/types";
import { useOperator } from "@/hooks";
import { addToast } from "@/utils";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { getCountryName } from "@/utils/getCountryName";

export const TripReporting = () => {
  const { bookableCountryList, country: defaultCountry } = useOperator();
  const [getCountryStates, { isFetching }] = useLazyGetCountryStatesQuery();
  const [step, setStep] = useState<"export" | undefined>(undefined);
  const [stateItems, setStateItems] = useState<CountryState[]>([]);
  const [exportItems, setExportItems] = useState<CountryState[]>([]);
  const [country, setCountry] = useState(defaultCountry);

  useEffect(() => {
    getCountryStates(country)
      .unwrap()
      .then(data => setStateItems(data.data.states))
      .catch((e) => {
        getErrorMessages(e).forEach((m) => {
          if (m === "Not found") addToast("danger", "No states found.");
          else addToast("danger", m);
        });
        setStateItems([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const handleSetExportItems = (stateItem: CountryState) => {
    const items = [...exportItems];
    const index = items.indexOf(stateItem);
    if (index > -1) {
      items.splice(index, 1);
      setExportItems(items);
    } else {
      setExportItems([...items, stateItem]);
    }
  };

  const getCountryOptions = () => {
    const options: Array<{ name: string; value: string }> = [];
    bookableCountryList.forEach(countryCode => {
      const country = getCountryName(countryCode);
      if (country) options.push({ name: country, value: countryCode });
    })

    return options;
  };

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader skipCrumbs={1} title="Trip Reporting" />
      <>
        {isFetching ? (
          <Skeleton />
        ) : (
          <>
            <Panel>
              <div className="flex flex-col gap-3 lg:flex-row">
                <div className="flex-1">
                  <Typography variant="h3" className="leading-8">
                    Select State to Export Report
                  </Typography>
                  <Typography className="text-neutral-dark-gray">
                    Provide details of all trips booked within a set month. Report is based off Pickup time.
                  </Typography>
                </div>
                <div className="flex flex-col items-center gap-x-4 gap-y-2 lg:flex-row">
                  {bookableCountryList.length > 1 && (
                    <DropdownSelect
                      size="md"
                      options={getCountryOptions()}
                      placeholder="Select Country"
                      value={country}
                      onChange={(value: string) => setCountry(value)}
                      className="w-full lg:w-40"
                    />
                  )}
                  <Button
                    onClick={() => {
                      setExportItems(stateItems);
                      setStep("export");
                    }}
                    variant="secondary"
                    startIcon="Export"
                    disabled={isFetching}
                    className="w-full lg:w-fit"
                  >
                    Export All
                  </Button>
                  <Button 
                    onClick={() => setStep("export")} 
                    variant="primary" 
                    startIcon="Export" 
                    disabled={!exportItems.length}
                    className="w-full lg:w-fit"
                  >
                    Export Report
                  </Button>
                </div>
              </div>
              <div className="mt-6 flex">
                <Alert type="info" message="You can select multiple states when exporting." className="w-full" />
              </div>
              <div className="flex">
                <ul role="list" className="mt-5 grid w-full grid-cols-2 gap-x-6 gap-y-6 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7">
                  {stateItems.map((stateItem) => (
                    <li key={stateItem.id}>
                      <StateCard
                        image={stateItem.imageCdn}
                        name={stateItem.abbreviation}
                        onClick={() => handleSetExportItems(stateItem)}
                        className="relative cursor-pointer overflow-hidden rounded-lg border"
                        isActive={exportItems.includes(stateItem)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </Panel>
          </>
        )}
      </>
      <ExportReportPanel
        open={step === "export"}
        onClose={() => setStep(undefined)}
        stateItems={exportItems}
        isExportAll={exportItems.length === stateItems.length}
      />
    </div>
  );
};