import { useEffect, useMemo } from "react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Button, IconButton, Loading, RadioButtonGroup, RadioInput, SortableList, TextInput, Toggle, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAddApplicationRuleMutation, useUpdateApplicationRuleMutation } from "@/redux/apis/driver/driverApi";
import { addToast } from "@/utils";
import { ApplicationRuleIcon } from "./common/ApplicationRuleIcon";
import { useApplicationRulesContext } from "./context";
import { applicationRuleLabels } from "./fixtures";
import { ApplicationRule } from "./types";

export const ApplicationRuleEdit = () => {
  const { activeRule: rule, setEditMode, setActiveRule, setDraftRule } = useApplicationRulesContext();

  const onCancel = () => {
    setEditMode(false);
    setDraftRule(null);
    if (!rule?.uuid) setActiveRule(null);
  };

  const onSave = (rule: ApplicationRule) => {
    setActiveRule(rule);
    setEditMode(false);
    setDraftRule(null);
  };

  if (!rule) return null;

  return (
    <article className="flex-1 overflow-hidden rounded-lg shadow-lg">
      <header className="flex items-center bg-neutral-gray p-4">
        <div className="flex flex-1 gap-2">
          <ApplicationRuleIcon type={rule.type} className="bg-primary text-white" />
          <div className="flex min-w-0 flex-1 flex-col justify-center space-y-1">
            <Typography className="question truncate font-semibold leading-none text-neutral-black">
              {applicationRuleLabels[rule.type]}
            </Typography>
            {rule.isManaged && <Typography className="font-semibold leading-none text-neutral-dark-gray">Fixed Question</Typography>}
          </div>
        </div>
        <div className="shrink-0">
          <div className="flex items-center gap-2">
            <Button onClick={onCancel} variant="secondary" size="sm" className="bg-transparent">
              Cancel
            </Button>
            <Button variant="primary" size="sm" form="edit-question-form" type="submit">
              Save Question
            </Button>
          </div>
        </div>
      </header>
      <div className="h-full flex-1 bg-white px-5 py-6">
        <EditForm rule={rule} onSave={onSave} key={rule.uuid} />
      </div>
    </article>
  );
};

const EditForm = ({ rule, onSave }: { rule: ApplicationRule; onSave: (rule: ApplicationRule) => void }) => {
  const [addApplicationRule, { isLoading: isAdding }] = useAddApplicationRuleMutation();
  const [updateApplicationRule, { isLoading: isUpdating }] = useUpdateApplicationRuleMutation();

  const form = useForm<ApplicationRule>({
    defaultValues: {
      uuid: rule.uuid,
      type: rule.type,
      isEnabled: rule.isEnabled,
      isRequired: rule.isRequired,
      isManaged: rule.isManaged,
      question: rule.question,
      description: rule.description,
      conditions: rule.conditions,
    } as unknown as ApplicationRule,
  });

  const { control, handleSubmit, register } = form;

  const onSubmit = async (data: ApplicationRule) => {
    if (!data.uuid) {
      return addApplicationRule({
        question: data.question,
        description: data.description || "",
        type: data.type,
        is_required: data.isRequired,
        is_enabled: data.isEnabled,
        conditions: data.conditions,
      })
        .unwrap()
        .then((res) => {
          onSave(res as ApplicationRule);
          addToast("success", "Question has been saved successfully");
        })
        .catch((e) => {
          getErrorMessages(e).forEach((message) => addToast("danger", message));
        });
    }

    return updateApplicationRule({
      id: data.uuid,
      body: {
        question: data.question,
        description: data.description || "",
        type: data.type,
        is_required: data.isRequired,
        is_enabled: data.isEnabled,
        conditions: data.conditions,
      },
    })
      .unwrap()
      .then((res) => {
        onSave(res as ApplicationRule);
        addToast("success", "Question has been saved successfully");
      })
      .catch((e) => {
        getErrorMessages(e).forEach((message) => addToast("danger", message));
      });
  };

  const isLoading = isAdding || isUpdating;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} id="edit-question-form" className="relative space-y-6">
        {isLoading && <Loading />}
        <fieldset className="flex gap-6">
          <div className="space-y-2">
            <Typography className="font-semibold text-neutral-dark-gray">Display Question?</Typography>
            <Controller
              name="isEnabled"
              control={control}
              render={({ field }) => (
                <div className="flex items-center">
                  <Toggle size="xs" checked={field.value} onChange={(checked) => field.onChange(checked)} />
                  <Typography variant="action" className="ml-2">
                    {field.value ? "Show" : "Hide"}
                  </Typography>
                </div>
              )}
            />
          </div>
          <div className="space-y-2">
            <Typography className="font-semibold text-neutral-dark-gray">Required Question?</Typography>
            <Controller
              name="isRequired"
              control={control}
              render={({ field }) => (
                <div className="flex items-center">
                  <Toggle size="xs" checked={field.value} onChange={(checked) => field.onChange(checked)} />
                  <Typography variant="action" className="ml-2">
                    {field.value ? "Required" : "Optional"}
                  </Typography>
                </div>
              )}
            />
          </div>
        </fieldset>
        <fieldset className="space-y-1">
          <Typography className="font-semibold ">Question</Typography>
          <TextInput {...register("question")} className="w-[370px]" placeholder="Type Question here" />
        </fieldset>
        <fieldset className="space-y-1">
          <Typography className="font-semibold ">Subtext (Optional)</Typography>
          <TextInput {...register("description")} className="w-full" placeholder="Type a Descriptive Note" />
        </fieldset>
        <Conditions rule={rule} />
      </form>
    </FormProvider>
  );
};

const Conditions = ({ rule }: { rule: ApplicationRule }) => {
  const { control, register } = useFormContext<ApplicationRule>();

  if (!rule.conditions) return null;
  switch (rule.type) {
    case "select_multi":
    case "select_single":
      return <MultipleChoiceFields />;
    case "bool":
      return (
        <fieldset className="space-y-2">
          <Typography className="font-semibold">Choice (Select the correct answer)</Typography>
          <Controller
            control={control}
            name="conditions.answer"
            render={({ field }) => (
              <>
                <RadioInput label="Yes" onChange={() => field.onChange(true)} checked={field.value === true} />
                <br />
                <RadioInput label="No" onChange={() => field.onChange(false)} checked={field.value === false} />
              </>
            )}
          />
        </fieldset>
      );

    case "numeric":
      return (
        <>
          <fieldset className="space-y-2">
            <Typography className="font-semibold">Set Conditions (Minimum)</Typography>
            <TextInput type="number" {...register("conditions.answerMin", { valueAsNumber: true })} className="w-[100px]" placeholder="0" />
          </fieldset>
          <fieldset className="space-y-2">
            <Typography className="font-semibold">Set Conditions (Maximum)</Typography>
            <TextInput type="number" {...register("conditions.answerMax", { valueAsNumber: true })} className="w-[100px]" placeholder="0" />
          </fieldset>
        </>
      );
  }
};

// we need to define a new type here
// because react-hook-form will only accept Array<object> as the type for useFieldArray

type MultipleChoiceFormData = {
  options: {
    name: string;
    isAnswer: boolean;
  }[];
};

const MultipleChoiceFields = () => {
  const { activeRule } = useApplicationRulesContext();
  const defaultOptions = useMemo(() => {
    if (!activeRule || (activeRule.type !== "select_multi" && activeRule.type !== "select_single")) return [];

    return activeRule.conditions.options.map((option) => ({ name: option, isAnswer: activeRule.conditions.answers.includes(option) }));
  }, [activeRule]);

  const { setValue: setParentValue, watch: parentWatch, control: parentControl } = useFormContext<ApplicationRule>();
  const {
    control,
    register,
    watch,
    setValue: setChildValue,
  } = useForm<MultipleChoiceFormData>({
    defaultValues: {
      options: defaultOptions,
    },
  });

  const { fields, move, append, remove } = useFieldArray<MultipleChoiceFormData>({
    control,
    name: "options",
  });

  const allowMultipleAnswers = parentWatch("type") === "select_multi";
  const options = watch("options");
  const answers = options.filter((option) => option.isAnswer).map((option) => option.name);

  useEffect(() => {
    setParentValue(
      "conditions.options",
      options.filter((option) => option.name !== "").map((option) => option.name)
    );
    setParentValue("conditions.answers", answers);
  }, [answers, options, setParentValue]);
  return (
    <>
      <fieldset className="space-y-2">
        <Typography className="font-semibold text-neutral-dark-gray">Type</Typography>
        <Controller
          name="type"
          control={parentControl}
          render={({ field }) => (
            <RadioButtonGroup
              defaultValue="select_single"
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                if (value === "select_single" && answers.length > 1) {
                  setChildValue(
                    "options",
                    options.map((option) => ({ ...option, isAnswer: false }))
                  );
                }
              }}
              className="inline-flex"
            >
              <RadioButtonGroup.Option
                name="Single-Select"
                value="select_single"
                className="w-[120px] bg-transparent p-2.5 ring-neutral-dark-gray"
              />
              <RadioButtonGroup.Option
                name="Multi-Select"
                value="select_multi"
                className="w-[120px] bg-transparent p-2.5 ring-neutral-dark-gray"
              />
            </RadioButtonGroup>
          )}
        />
      </fieldset>
      <fieldset className="space-y-2">
        <Typography className="font-semibold">Manage Choices (Filter the correct answer/s)</Typography>
        <SortableList
          items={fields}
          onMove={(activeIndex, overIndex) => {
            move(activeIndex, overIndex);
          }}
          // onChange={(items) => {
          //   setParentValue(
          //     "conditions.options",
          //     items.map((item) => item.name)
          //   );
          // }}
          renderItem={(item, index) => (
            <SortableList.Item id={item.id}>
              <div className="flex items-center gap-2 rounded-lg border border-neutral-dark-gray px-3 py-1">
                <SortableList.DragHandle className="bg-transparent" />
                <TextInput
                  placeholder="Type Choice here"
                  {...register(`options.${index}.name`)}
                  className="border-none bg-transparent px-0 !shadow-none"
                  size="md"
                />
                <div className="flex shrink-0 items-center gap-3">
                  <Typography className="flex items-center gap-2 text-neutral-dark-gray">
                    Accepted Answer
                    <Controller
                      control={control}
                      name={`options.${index}.isAnswer`}
                      render={({ field }) => (
                        <Toggle
                          size="xs"
                          checked={Boolean(field.value)}
                          onChange={(checked) => {
                            if (!checked) {
                              field.onChange(false);
                              // setParentValue(
                              //   "conditions.answers",
                              //   answers.filter((answer) => answer !== options[index].name)
                              // );
                            } else {
                              if (allowMultipleAnswers) {
                                field.onChange(true);
                                // setParentValue("conditions.answers", [...answers, options[index].name]);
                              } else {
                                options.forEach((_, i) => {
                                  setChildValue(`options.${i}.isAnswer`, false);
                                });
                                field.onChange(true);
                                // setParentValue("conditions.answers", [options[index].name]);
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </Typography>
                  <IconButton
                    onClick={() => {
                      // setParentValue(
                      //   "conditions.options",
                      //   options.filter((_, i) => i !== index).map((option) => option.name)
                      // );
                      // setParentValue(
                      //   "conditions.answers",
                      //   answers.filter((answer) => answer !== options[index].name)
                      // );
                      remove(index);
                    }}
                    variant="custom"
                    iconName="Trash"
                    className="text-danger"
                  />
                </div>
              </div>
            </SortableList.Item>
          )}
        />
        <Button
          className="mt-2"
          startIcon="Add"
          size="sm"
          variant="secondary"
          onClick={() => {
            // setParentValue("conditions.options", [...options.map((option) => option.name), ""]);
            append({ name: "", isAnswer: false });
          }}
        >
          Add Choice
        </Button>
      </fieldset>
    </>
  );
};
