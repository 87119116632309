import { Container } from "@/components/atoms";
import { AddClientPanel, PageHeader, PageHeaderV2 } from "@/components/organisms";

export const AddClient = () => {
  return (
    <Container className="flex flex-1 flex-col w-full mb-12">
      <PageHeaderV2 name="Clients" skipCrumbs={1} />
      <PageHeader.Actions />
      <AddClientPanel />
    </Container>
  );
};
