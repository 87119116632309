import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { Pagination, PricingList } from "@/components/molecules";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setFixedPricingAction } from "@/redux/slices/pricing/pricingSlice";
import { useFixedPricingContext } from "../contexts/fixedPricing";
import { FixedPricingModals } from "./FixedPricingModals";
import { FixedPricingEmptyState } from "./emptyStates/FixedPricingEmptyState";
import { usePagination } from "@/hooks";
import { pricingProfilesPageSelector } from "@/redux/slices/pricing/selectors";

export const FixedPricingPanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useAppDispatch();
  const { pricingProfiles, isFetching } = useFixedPricingContext();

  const handleAddPricingProfile = () => dispatch(setFixedPricingAction("addPricingProfile"));

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <>
          <Panel className={className} {...props}>
            {pricingProfiles.length < 1 ? (
              <FixedPricingEmptyState onAddPricingProfile={handleAddPricingProfile} />
            ) : (
              <div className="flex flex-col gap-y-4">
                <div className="flex">
                  <div className="flex-1">
                    <Typography variant="h3" className="leading-8">
                      Fixed Pricing &#40;Trip Cost&#41;
                    </Typography>
                    <Typography className="text-neutral-dark-gray">
                      Establish customized pricing profiles to seamless assignment to your valued customers.
                    </Typography>
                  </div>
                  <div className="flex items-center justify-center">
                    <Button startIcon="Add" onClick={handleAddPricingProfile}>
                      Add Pricing Profile
                    </Button>
                  </div>
                </div>
                <PricingList>
                  {pricingProfiles.map((pricingProfile) => (
                    <PricingList.Item
                      key={pricingProfile.id}
                      title={pricingProfile.name}
                      to={pricingProfile.id}
                      alert={
                        !(pricingProfile.hasNationwidePricing && pricingProfile.hasStatePricing && pricingProfile.hasLocationPricing)
                          ? {
                              type: "warning",
                              message: "Missing some pricing configurations",
                            }
                          : undefined
                      }
                    />
                  ))}
                </PricingList>
              </div>
            )}
          </Panel>
          <FixedPricingPagination />
        </>
      )}
      <FixedPricingModals />
    </>
  );
};

const FixedPricingPagination = () => {
  const { current, size, total, count } = useAppSelector(pricingProfilesPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("pricing-profiles");

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};
