import { pageState } from "@/redux/types";
import { PricingConfigState } from "./types";

export const initialState: PricingConfigState = {
  tripCost: {
    dynamicPricing: {
      pricingStates: [],
      pageState: {
        action: undefined,
      },
    },
    fixedPricing: {
      pricingProfiles: [],
      pricingProfilesPage: pageState,
      nationwidePricing: {
        id: "default-nationwide-id",
        type: "nationwide",
        countryCode: "AU",
        currency: "",
        distanceUnit: "km",
        managed: false,
        baseInterval: null,
        intervals: [],
      },
      pricingStates: [],
      pricingLocations: [],
      pageState: {
        action: undefined,
      },
    },
    errors: [],
  },
  costExtras: {
    items: [],
    page: pageState,
    errors: [],
  },
  vehicleCategories: {
    items: [],
    page: pageState,
    errors: [],
  },
  timeAndDay: {
    items: [],
    page: pageState,
    errors: [],
  },
  transferTypes: {
    items: [],
    page: pageState,
    errors: [],
  },
  cancellationFees: {
    asapPricing: {
      cancellationFee: 0,
      noFeeWindow: 0,
      currency: ""
    },
    standardPricing: {
      basePrice: {
        rangeTo: 2,
        rate: 0,
      },
      intervals: [],
    },
    errors: [],
  },
  hourlyHire: {
    pricing: {
      currency: "",
      currencySymbol: "",
      basePrice: {
        minimumRange: 0,
        rate: 0,
      },
      intervals: [],
    },
    minimumBookingPeriodMinutes: 2,
    errors: [],
  },
  dailyHire: {
    pricing: {
      currency: "",
      currencySymbol: "",
      basePrice: {
        minimumRange: 0,
        rate: 0,
      },
      intervals: [],
    },
    minimumBookingPeriodMinutes: 1,
    errors: [],
  },
  unscheduledStops: {
    details: {
      unscheduledStopsFee: 0,
      countryCode: "",
      currency: "",
    },
    errors: [],
  },
};
