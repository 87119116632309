import { ComponentProps } from "react";
import { IconButton, Tooltip, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { applicationRuleLabels } from "../fixtures";
import { ApplicationRule } from "../types";
import { ApplicationRuleIcon } from "./ApplicationRuleIcon";

interface ApplicationRuleNavProps extends ComponentProps<"div"> {
  rule: ApplicationRule;
  isSelected: boolean;
  onClick: () => void;
}

export const ApplicationRuleNav = ({ rule, isSelected, className, ...props }: ApplicationRuleNavProps) => {
  const statusClass = !isSelected
    ? "bg-transparent [&_.question]:text-neutral-black [&_.rule-icon]:bg-neutral-mid-gray"
    : "bg-primary-light [&_.question]:text-primary [&_.rule-icon]:bg-primary";

  return (
    <div className={clsx("flex cursor-pointer items-center gap-2.5 rounded-md p-3 ", statusClass, className)} {...props}>
      <ApplicationRuleIcon type={rule.type} />
      <div className="min-w-0 flex-1 space-y-1 ">
        <Typography className="question truncate font-normal leading-none text-neutral-black">{rule.question || "-"}</Typography>
        <Typography className="font-semibold leading-none text-neutral-dark-gray">{applicationRuleLabels[rule.type]}</Typography>
      </div>
      <div className="flex shrink-0 gap-1.5">
        {rule.isRequired && (
          <Tooltip content="Marked as Required" placement="right">
            <IconButton iconName="TickCircle" variant="custom" iconVariant="Bold" className="text-success " />
          </Tooltip>
        )}
        {rule.isEnabled && <IconButton iconName="Eye" variant="custom" />}
      </div>
    </div>
  );
};
