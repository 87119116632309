import { MyExternalSuppliersPanel } from "@/components/organisms";
import { AddExternalSupplier } from "./subpages/AddExternalSupplier";
// import { ExternalSupplierProfile } from "./subpages/ExternalSupplierProfile";

export const MyExternalSuppliers = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col py-2">
      <MyExternalSuppliersPanel />
    </div>
  );
};

MyExternalSuppliers.Add = AddExternalSupplier;
// MyExternalSuppliers.Profile = ExternalSupplierProfile;