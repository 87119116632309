import { Button } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useNavigate } from "react-router-dom";

export const MyExternalSuppliersEmptyState = ({ hasFilters }: { hasFilters?: boolean }) => {
  const navigate = useNavigate();

  if (hasFilters)
    return <EmptyState title="Manage External Suppliers" description="There are no external suppliers matching your filter criteria at the moment." />;

  return (
    <EmptyState
      title="Manage External Suppliers"
      description="It looks like you haven't added any external suppliers yet. Begin by adding your first external supplier to get started"
    >
      <div className="flex flex-row gap-4">
        <Button onClick={() => navigate("my-external-suppliers/add")} variant="primary" startIcon="Add" size="md">
          Add External Supplier
        </Button>
      </div>
    </EmptyState>
  );
};
