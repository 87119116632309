import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Panel, Skeleton, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { BookingControls } from "@/components/organisms/Bookings/BookingControls";
import { PageHeader } from "@/components/organisms/PageHeader/PageHeader";
import { useGetDriverJobHistoryQuery } from "@/redux/apis/driver/driverProfileApi";
import { DriverNavigation } from "../../common/DriverNavigation";
import { JobHistoryTable } from "./JobHistoryTable";
import { JobHistoryProvider, useJobHistoryContext } from "./context";
import { useGetJobHistoryParams } from "./useGetJobHistoryParams";

export const JobHistoryPanel = () => {
  const navigate = useNavigate();

  return (
    <JobHistoryProvider>
      <PageHeader.Actions onBack={() => navigate("../../")} />
      <Content />
    </JobHistoryProvider>
  );
};

const Content = () => {
  const { driver } = useJobHistoryContext();
  const { params } = useGetJobHistoryParams();
  const { data, isFetching } = useGetDriverJobHistoryQuery(params);

  const [jobs, page] = useMemo(() => {
    if (!data?.jobs || !data?.page) return [[], { current: 1, count: 0, total: 0, size: 12 }];

    return [data.jobs, data.page];
  }, [data]);

  const title = useMemo(() => {
    if (!data) return `${driver.personal.firstName} ${driver.personal.lastName}'s Job History`;
    return `${driver.personal.firstName} ${driver.personal.lastName}'s Job History (${data.jobs.length})`;
  }, [data, driver]);

  return (
    <div className="flex gap-3">
      <DriverNavigation driver={driver} />
      <Panel className="relative flex-1">
        <header className="flex items-center justify-between gap-3">
          <Typography variant="h3">{title}</Typography>
          <BookingControls />
        </header>
        {isFetching ? (
          <Skeleton />
        ) : !isFetching && jobs.length === 0 ? (
          <EmptyState title="Job History" description="This driver does not have jobs in given time period." />
        ) : (
          <>
            <JobHistoryTable items={jobs} />
            <JobHistoryTable.Pagination page={page} />
          </>
        )}
      </Panel>
    </div>
  );
};
