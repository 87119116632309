import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Alert, Button, ErrorMessage, Loading, Panel, TextInput, Toggle, Typography } from "@/components/atoms";
import { PageHeader } from "@/components/organisms/PageHeader/PageHeader";
import { useOperator } from "@/hooks";
import { addToast } from "@/utils";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAddExternalSupplierMutation, useGetExternalSuppliersFormOptionsQuery } from "@/redux/apis/externalSupplier/externalSupplierApi";
import { AddExternalSupplierFormData } from "./types";
import { addExternalSupplierFormSchema } from "./fixtures";
import { TypeField } from "./fields/TypeField";
import { StateField } from "./fields/StateField";
import { AddExternalSupplierParams } from "@/redux/apis/externalSupplier/types";
import { AddressField } from "./fields/AddressField";
import { ContactNumberField } from "@/components/organisms/SignUp/forms/fields";

export const AddExternalSupplierPanel = () => {
  const { name: operatorName } = useOperator();
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader.Actions />
      <Panel className="mx-auto flex w-full flex-col p-[30px]">
        <Typography variant="h3" className="mb-1.5">
          Sign up New External Supplier
        </Typography>
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          Add a new external supplier for {operatorName}
        </Typography>
        <Form />
      </Panel>
    </div>
  );
};

const Form = () => {
  const navigate = useNavigate();
  const { country } = useOperator();
  const { isFetching } = useGetExternalSuppliersFormOptionsQuery();
  const [addExternalSupplier, { isLoading }] = useAddExternalSupplierMutation();
  const [requestErrors, setRequestErrors] = useState<string[]>([]);
  const methods = useForm<AddExternalSupplierFormData>({
    resolver: zodResolver(addExternalSupplierFormSchema),
    criteriaMode: "all",
    defaultValues: {
      type: "",
      state: "",
      regionAu: country === "AU",
    },
  });
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { isDirty, errors: formErrors },
  } = methods;
  const isGstRegistered = watch("isGstRegistered");
  const isRegionAu = watch("regionAu");

  const onSubmit = handleSubmit(async (data) => {
    const params: AddExternalSupplierParams = {
      name: data.name,
      type: data.type,
      address: { address: data.address },
      address_state: data.state,
      email: data.email,
      phone: data.contactNumber,
      key_contact_name: data.keyContactName,
      region_au: isRegionAu ? {
        is_gst_registered: data.isGstRegistered,
        abn: data.isGstRegistered ? data.abn : undefined,
      } : undefined,
    };
    addExternalSupplier(params)
      .unwrap()
      .then(() => {
        addToast("success", "Successfully added new external supplier.");
        navigate("../");
      })
      .catch((e) => setRequestErrors(getErrorMessages(e)));
  });

  const renderRequestErrors = () => {
    return requestErrors.length > 0 ? (
      <div className="mb-4">
        {requestErrors.map((e, i) => (
          <Alert key={i} type="danger" message={e} className="mb-4" />
        ))}
      </div>
    ) : null;
  };

  return (
    <FormProvider {...methods}>
      <div className="relative pt-8">
        {isFetching && <Loading />}
        {renderRequestErrors()}
        <form onSubmit={onSubmit}>
          <div className="grid grid-cols-3 animate-enter gap-x-5 gap-y-6">
            <div className="col-span-3 grid grid-cols-3 gap-x-5 gap-y-6">
              <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
                <Typography variant="paragraph">Name</Typography>
                <TextInput className="w-full" hasError={!!formErrors.name} placeholder="Enter your Supplier's Name" {...register("name")} />
                <ErrorMessage errors={formErrors} name="name" />
              </div>
              <AddressField className="col-span-3 flex flex-col gap-1 lg:col-span-2" />
            </div>
            <div className="col-span-3 grid grid-cols-3 gap-x-5 gap-y-6">
              <TypeField className="col-span-3 flex flex-col gap-1 lg:col-span-1" />
              <StateField className="col-span-3 grid grid-cols-2 gap-x-5 gap-y-6 lg:col-span-2" />
            </div>
            <div className="col-span-3 grid grid-cols-3 gap-x-5 gap-y-6">
              <ContactNumberField className="col-span-3 flex flex-col gap-1 lg:col-span-1" />
              <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
                <Typography variant="paragraph">Email</Typography>
                <TextInput className="w-full" hasError={!!formErrors.email} placeholder="supplier@email.com" {...register("email")} />
                <ErrorMessage errors={formErrors} name="email" />
              </div>
              <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
                <Typography variant="paragraph">Key Contact Name</Typography>
                <TextInput
                  className="w-full"
                  hasError={!!formErrors.keyContactName}
                  placeholder="Contact Name"
                  {...register("keyContactName")}
                />
                <ErrorMessage errors={formErrors} name="keyContactName" />
              </div>
            </div>
            {isRegionAu && (
              <div className="col-span-3 grid grid-cols-3 gap-x-5 gap-y-6">
                <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
                  <Typography variant="paragraph">ABN Registered</Typography>
                  <Controller
                    name="isGstRegistered"
                    control={control}
                    render={({ field }) => (
                      <Toggle
                        className="flex-shrink-0"
                        checked={field.value}
                        onChange={(checked) => field.onChange(checked)}
                      />
                    )}
                  />
                </div>
                {isGstRegistered && (
                  <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
                    <Typography variant="paragraph">ABN</Typography>
                    <TextInput type="number" placeholder="00 000 000 000" maxLength={16} hasError={!!formErrors.abn} {...register("abn")} />
                    <ErrorMessage errors={formErrors} name="abn" />
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="mb-4 mt-10 flex justify-end gap-4">
            <Button 
              className="px-8 ring-1 ring-neutral-dark-gray"
              variant="tertiary" 
              size="lg" 
              onClick={() => navigate("/external-suppliers")}
            >
              Cancel
            </Button>
            <Button 
              className="relative px-8"
              variant="primary" 
              type="submit" 
              size="lg"
              disabled={!isDirty || isLoading}
            >
              {isLoading && <Loading />}
              Add External Supplier
            </Button>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};
