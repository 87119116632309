import { ErrorPanel, Skeleton } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetVehiclesQuery } from "@/redux/apis/vehicle/vehicleApi";
import { AffiliateVehiclesTable } from "./AffiliateVehiclesTable";
import { useGetAffiliateVehiclesParams } from "./hooks/useGetAffiliateVehiclesParams";

export const AffiliateVehiclesPanel = () => {
  const { params } = useGetAffiliateVehiclesParams();
  const {
    isFetching,
    data: response,
    error,
  } = useGetVehiclesQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;

  if (response && response.vehicles.length > 0)
    return (
      <>
        <AffiliateVehiclesTable items={response.vehicles ?? []} />
        <AffiliateVehiclesTable.Pagination page={response.page} />
      </>
    );

  return <EmptyState title="Vehicle Manager" description="There are no vehicles matching your filter criteria at the moment." />;
};
