import { createContext, useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetPricingProfilesQuery } from "@/redux/apis/config/pricing/tripCost";
import { useAppSelector } from "@/redux/hooks";
import { pricingProfileSelector, pricingProfilesSelector } from "@/redux/slices/pricing/selectors";
import { PricingProfile } from "@/redux/slices/pricing/types";
import { useGetPricingProfilesParams } from "../FixedPricing/hooks/useGetPricingProfilesParams";
import { ErrorPanel } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";

interface FixedPricingState {
  pricingProfiles: PricingProfile[];
  isFetching: boolean;
  isRestricted: boolean;
  pricingProfile?: PricingProfile;
}

const FixedPricingContext = createContext<FixedPricingState | null>(null);

export const FixedPricingContextProvider = ({ children }: React.PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const profileName = searchParams.get("profileName");
  const { id = "" } = useParams();

  const { params } = useGetPricingProfilesParams();
  const { isFetching, error } = useGetPricingProfilesQuery(params, { refetchOnMountOrArgChange: true });
  const pricingProfiles = useAppSelector(pricingProfilesSelector);
  const pricingProfile = useAppSelector(pricingProfileSelector(id));
  const [isRestricted, setIsRestricted] = useState(false);

  // breadcrumbs setup
  useEffect(() => {
    if (pricingProfile && profileName !== pricingProfile.name) {
      searchParams.set("profileName", pricingProfile.name);
      setSearchParams(searchParams);
    }
  }, [pricingProfile, profileName, searchParams, setSearchParams]);

  // restricted check
  useEffect(() => {
    if (!isFetching && !pricingProfile) setIsRestricted(true);
  }, [isFetching, pricingProfile]);

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;

  return (
    <FixedPricingContext.Provider value={{ pricingProfiles, isFetching, isRestricted, pricingProfile }}>
      {children}
    </FixedPricingContext.Provider>
  );
};

export const useFixedPricingContext = () => {
  const context = useContext(FixedPricingContext);
  if (!context) throw new Error("useFixedPricingContext must be used within FixedPricingContextProvider");

  return context;
};
