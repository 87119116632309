import { Container } from "@/components/atoms";
import { ApplicationRulesPanel, PageHeader } from "@/components/organisms";

export const ApplicationRules = () => {
  return (
    <Container className="w-full">
      <div className="sticky top-0 z-30 w-full bg-neutral-surface-gray">
        <PageHeader title="Drivers" skipCrumbs={1} />
        <PageHeader.Actions />
      </div>
      <div className="mb-12 flex flex-1 flex-col py-2">
        <ApplicationRulesPanel />
      </div>
    </Container>
  );
};
