import { createApi } from "@reduxjs/toolkit/dist/query/react";
import camelcaseKeys from "camelcase-keys";
import { omit } from "ramda";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { Links } from "@/redux/types";
import { createSearchParams } from "@/utils";
import { transformVehicleMake, transformVehicleModel } from "./helpers";
import {
  SearchVehicleModelResponse,
  SearchVehicleModelParams,
  SearchVehicleMakeResponse,
  GetAllVehicleMakeAndModelsRawResponse,
  GetAllVehicleMakeAndModelsParams,
  VehicleNote,
  GetVehicleNotesParams,
  GetVehicleNotesRawResponse,
  AddVehicleNoteParams,
  RawVehicleNote,
  UpdateAffiliateVehicleParams,
} from "./types";

export const vehicleGuestApi = createApi({
  reducerPath: "vehicleGuestApi",
  baseQuery: baseQueryWithoutProvider("vehicle"),
  endpoints: (builder) => ({
    searchVehicleModel: builder.query<SearchVehicleModelResponse, SearchVehicleModelParams>({
      query: ({ q, ...params }) => `/model?${createSearchParams({ search: q, ...params }).toString()}`,
      transformResponse: transformVehicleModel,
    }),
    searchVehicleMake: builder.query<SearchVehicleMakeResponse, { q?: string; type?: string; page_size?: number }>({
      query: ({ q, ...params }) => `/make?${createSearchParams({ search: q, ...params }).toString()}`,
      transformResponse: transformVehicleMake,
    }),
    getAllVehicleMakeAndModels: builder.query<GetAllVehicleMakeAndModelsRawResponse, GetAllVehicleMakeAndModelsParams>({
      query: (params) => `/make-model/all?${createSearchParams(params).toString()}`,
      transformResponse: (response: GetAllVehicleMakeAndModelsRawResponse & Links) => omit(["_links"], response),
    }),
    getVehicleNotes: builder.query<VehicleNote[], GetVehicleNotesParams>({
      query: ({ ownerId, vehicleId }) => `/${ownerId}/${vehicleId}/notes`,
      transformResponse: (response: GetVehicleNotesRawResponse) =>
        response._embedded.notes.map((note) => camelcaseKeys(note, { deep: true })) as VehicleNote[],
      providesTags: ["VehicleNotes"],
    }),
    addVehicleNote: builder.mutation<VehicleNote, AddVehicleNoteParams>({
      query: ({ ownerId, vehicleId, ...body }) => ({
        url: `/${ownerId}/${vehicleId}/notes`,
        method: "POST",
        body,
      }),
      transformResponse: (response: RawVehicleNote) => camelcaseKeys(response, { deep: true }) as VehicleNote,
      invalidatesTags: ["VehicleNotes"],
    }),
    removeVehicleNote: builder.mutation<void, { ownerId: string; vehicleId: string; noteId: string }>({
      query: ({ ownerId, vehicleId, noteId }) => ({
        url: `/${ownerId}/${vehicleId}/notes/${noteId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["VehicleNotes"],
    }),
    updateAffiliateVehicle: builder.mutation<{ access: string; approval_status: string }, UpdateAffiliateVehicleParams>({
      query: ({ ownerId, vehicleId, ...body }) => ({
        url: `/${ownerId}/${vehicleId}`,
        method: "PATCH",
        body,
      }),
    }),
  }),
  tagTypes: ["VehicleNotes"],
});

export const {
  useLazySearchVehicleModelQuery,
  useSearchVehicleModelQuery,
  useSearchVehicleMakeQuery,
  useGetAllVehicleMakeAndModelsQuery,
  useGetVehicleNotesQuery,
  useAddVehicleNoteMutation,
  useRemoveVehicleNoteMutation,
  useUpdateAffiliateVehicleMutation,
} = vehicleGuestApi;
