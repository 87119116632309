import { Link } from "react-router-dom";
import { DropdownMenu, Icon, Typography } from "@/components/atoms";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { getAssetUrl } from "@/utils";
import { AffiliateVehicleTableData } from "./types";

export const generateAffiliateVehicleTableData = (operatorId: string, vehicles: Vehicle[]): AffiliateVehicleTableData[] => {
  const data = vehicles.map(
    (vehicle) =>
      ({
        numberPlate: renderNumberPlate(vehicle, operatorId),
        make: (
          <div className="flex items-center gap-2">
            <div className="flex h-6 w-6 items-center justify-center rounded bg-neutral-gray">
              <img
                src={getAssetUrl(vehicle.model.avatar, {
                  width: 36,
                  height: 36,
                  fit: "contain",
                  background: "transparent",
                })}
                alt={vehicle.model.make}
                className="h-[18px] w-[18px]"
              />
            </div>

            {vehicle.model.make}
          </div>
        ),
        model: vehicle.model.model,
        year: vehicle.year?.toString() ?? "-",
        color: (
          <div className="flex items-center gap-2 capitalize">
            {vehicle.color ? (
              <>
                {vehicle.color.toLowerCase().replace("_", " ")}
                {vehicle.color && (
                  <div
                    className="h-3 w-3 rounded-full border border-black"
                    style={{ backgroundColor: vehicle.color.toLowerCase().replace("_", "") }}
                  />
                )}
              </>
            ) : (
              "-"
            )}
          </div>
        ),
        state: (
          <div className="flex gap-2 ">
            {vehicle.state.image && <img src={vehicle.state.image} className="h-6 w-6 rounded-full object-cover" />}
            <Typography variant="action">{vehicle.state.abbreviation}</Typography>
          </div>
        ),
        owner: (
          <div className="flex items-center gap-2">
            {vehicle.owner ? (
              <>
                {vehicle.owner.avatar ? (
                  <img
                    src={getAssetUrl(vehicle.owner.avatar, {
                      width: 48,
                      height: 48,
                      fit: "contain",
                    })}
                    className="h-6 w-6 rounded-full"
                    alt={vehicle.owner?.name}
                  />
                ) : (
                  <Icon
                    name="Profile"
                    size="sm"
                    variant="Bold"
                    className="h-6 w-6 rounded-full bg-neutral-gray p-1 text-neutral-dark-gray"
                  />
                )}
                <Typography className="line-clamp-1">{vehicle.owner ? `${vehicle.owner.name}` : "-"}</Typography>
              </>
            ) : (
              <Typography className="line-clamp-1">-</Typography>
            )}
          </div>
        ),
        status: renderStatus(vehicle),
        actions: renderActions(vehicle, operatorId),
      } as AffiliateVehicleTableData)
  );

  return data;
};

const renderNumberPlate = (vehicle: Vehicle, operatorId: string) => {
  const link = `/operator/${operatorId}/vehicles/affiliate-vehicles/${vehicle.uuid}`;

  return (
    <Link to={link} className="flex items-center gap-1 hover:text-info">
      {vehicle.registrationNumber}
    </Link>
  );
};

const renderActions = (vehicle: Vehicle, operatorId: string) => {
  const link = `/operator/${operatorId}/vehicles/affiliate-vehicles/${vehicle.uuid}`;

  return (
    <>
      <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
        <Link to={link}>
          <DropdownMenu.Item>
            <Icon name="ArrowRight" className="mr-2.5 text-primary-dark" size="sm" />
            <Typography className="text-primary-dark">View Vehicle</Typography>
          </DropdownMenu.Item>
        </Link>
      </DropdownMenu>
    </>
  );
};

const renderStatus = (vehicle: Vehicle) => {
  const status = !vehicle.approval ? "PENDING" : vehicle.approval.status;

  switch (status) {
    case "PENDING":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 rounded-full bg-neutral-dark-gray" /> Pending
        </Typography>
      );
    case "APPROVED":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 rounded-full bg-info" /> Approved
        </Typography>
      );
    case "DENIED":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 rounded-full bg-danger" /> Denied
        </Typography>
      );
    default:
      return null;
  }
};
