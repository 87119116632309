import { useMemo } from "react";
import { Icon, IconButton } from "@/components/atoms";
import { useGetVehicleNotesQuery } from "@/redux/apis/vehicleGuest/vehicleGuestApi";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const VehicleOperatorNotesDetailCard = () => {
  const {
    vehicle: { owner, uuid: vehicleId },
    setActiveModal,
  } = useViewVehicleContext();

  const { data, isFetching } = useGetVehicleNotesQuery({
    ownerId: owner.uuid,
    vehicleId,
  });

  const notes = useMemo(() => {
    if (isFetching || !data) return [];

    return data;
  }, [data, isFetching]);

  const handleClick = () => {
    setActiveModal("operator-notes");
  };

  const ActionButton = () => {
    return (
      <IconButton
        iconName="ArrowRight2"
        className="!bg-transparent p-1.5 text-neutral-black"
        iconSize="lg"
        variant="tertiary"
        onClick={handleClick}
      />
    );
  };

  return (
    <VehicleDetailCard
      icon={<Icon name="DocumentText" size="xl" className="text-neutral-black" />}
      title="Operator notes"
      description={notes.length > 0 ? `${notes.length} ${notes.length > 1 ? "Notes" : "Note"}` : "N/A"}
      action={<ActionButton />}
      actionClick={handleClick}
    />
  );
};
