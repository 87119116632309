import { useAppSelector } from "@/redux/hooks";
import { profileSelector } from "@/redux/slices/profile/selectors";
import { Profile } from "@/redux/slices/profile/types";

export const useProfile = () => {
  const profile = useAppSelector(profileSelector);
  if (!profile) throw Error("Profile is not found");

  return {
    ...profile,
    //convert to date-fns format
    dateFormat: profile.dateFormat.replace("DD", "dd").replace("YYYY", "yyyy"),
    timeFormat: profile.timeFormat === "12H" ? "hh:mm a" : "HH:mm",
    fullTimeFormat: profile.timeFormat === "12H" ? "hh:mm:ss a" : "HH:mm:ss a",
  } as Profile;
};
