import { format } from "date-fns";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Button, Icon, IconButton, SidePanel, TextArea, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import {
  useAddVehicleNoteMutation,
  useGetVehicleNotesQuery,
  useRemoveVehicleNoteMutation,
} from "@/redux/apis/vehicleGuest/vehicleGuestApi";
import { addToast, getAssetUrl } from "@/utils";
import { useViewVehicleContext } from "../../context";

interface VehicleOperatorNotesModalProps {
  open: boolean;
  onClose: () => void;
}

export const VehicleOperatorNotesModal = ({ open, onClose }: VehicleOperatorNotesModalProps) => {
  const {
    vehicle: { owner, uuid: vehicleId },
  } = useViewVehicleContext();

  const { data, isFetching } = useGetVehicleNotesQuery({
    ownerId: owner.uuid,
    vehicleId,
  });

  const [removeNote, { isLoading }] = useRemoveVehicleNoteMutation();

  const handleDelete = (noteId: string) => {
    removeNote({ ownerId: owner.uuid, vehicleId, noteId })
      .unwrap()
      .then(() => {
        addToast("success", "Note removed successfully");
      });
  };

  const notes = useMemo(() => {
    if (isFetching || !data) return [];

    return data;
  }, [data, isFetching]);

  return (
    <SidePanel open={open} onClose={onClose} width={430} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="DocumentText" size="lg" />
          <Typography variant="title">Operator Notes</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <section className="flex h-full flex-1 flex-col p-5">
        <AddOperatorNoteForm />
        <div className="flex flex-1 flex-col">
          <div className="mt-3 flex flex-1 flex-col gap-4">
            {notes.map((note) => (
              <div
                key={note.uuid}
                aria-readonly={note.isDeleted}
                className="group rounded-lg bg-neutral-surface-gray p-4 aria-readonly:bg-danger-light"
              >
                <div className="relative flex flex-1 items-center gap-3">
                  <img src={getAssetUrl(note.account.avatar, { fit: "cover" })} className="h-8 w-8 rounded-full" />
                  <Typography variant="action">{`${note.account.firstName} ${note.account.lastName}`}</Typography>
                  {note.isDeleted ? (
                    <Typography variant="small" className="absolute right-2 top-2 text-danger">
                      Deleted
                    </Typography>
                  ) : (
                    <IconButton
                      onClick={() => handleDelete(note.uuid)}
                      disabled={isLoading}
                      iconName="Trash"
                      variant="custom"
                      className="absolute right-2 top-2 text-danger"
                    />
                  )}
                </div>
                <Typography variant="paragraph" className="mt-3 group-aria-readonly:line-through">
                  {note.content}
                </Typography>
                <footer className="mt-3">
                  <Typography variant="small" className="flex items-center gap-4 text-neutral-dark-gray">
                    <span>{format(new Date(note.createdAt), "dd MMMM yyyy")}</span>
                    <span>{format(new Date(note.createdAt), "hh:mm a")}</span>
                  </Typography>
                </footer>
              </div>
            ))}
          </div>
        </div>
      </section>
    </SidePanel>
  );
};

const AddOperatorNoteForm = () => {
  const { name } = useOperator();
  const {
    vehicle: { owner, uuid: vehicleId },
  } = useViewVehicleContext();

  const [addNote, { isLoading }] = useAddVehicleNoteMutation();
  const { register, handleSubmit } = useForm<{ note: string }>({
    defaultValues: {
      note: "",
    },
  });

  const onSubmit = (data: { note: string }) => {
    addNote({ ownerId: owner.uuid, vehicleId, contents: data.note })
      .unwrap()
      .then(() => {
        addToast("success", "Note added successfully");
      });
  };

  return (
    <div className="rounded-lg bg-neutral-surface-gray p-5">
      <Typography>Type in a note for this vehicle (Notes visible to {name} staff only)</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextArea {...register("note")} placeholder="Add notes..." className="mt-3" rows={2} />

        <div className="flex w-full justify-end">
          <Button type="submit" disabled={isLoading} className="mt-3" variant="primary">
            Add Note
          </Button>
        </div>
      </form>
    </div>
  );
};
