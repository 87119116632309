import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useGetDriverProfileQuery } from "@/redux/apis/driver/driverProfileApi";
import { DriverProfile } from "@/redux/slices/driver/types";

interface IAdminActionsContext {
  driver: DriverProfile;
  isFetching: boolean;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
}

export const VehiclesContext = createContext<IAdminActionsContext | null>(null);

export const AdminActionsProvider = ({ children }: PropsWithChildren) => {
  const { driverId } = useParams<{ driverId: string }>();
  const { id: operatorId } = useOperator();
  const [isEditMode, setIsEditMode] = useState(false);
  const { data: driver, isFetching } = useGetDriverProfileQuery({ id: driverId ?? "", operatorId }, { skip: !driverId });

  if (isFetching || !driver) return <Loading className="!bg-transparent" />;

  return <VehiclesContext.Provider value={{ driver: driver.data, isFetching, isEditMode, setIsEditMode }}>{children}</VehiclesContext.Provider>;
};

export const useAdminActionsContext = () => {
  const context = useContext(VehiclesContext);
  if (!context) {
    throw new Error("useAdminActionsContext must be used within an AdminActionsProvider");
  }
  return context;
};
