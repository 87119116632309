import { DropdownMenu, Dropzone, Icon, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { Document, useRemoveDocumentMutation, useUploadDocumentMutation } from "@/redux/apis/config/linksAndDocuments";
import { addToast, downloadFile } from "@/utils";
import { useState } from "react";

interface CustomDocumentListItemProps {
  id?: string;
  document?: Document;
  title: string;
  documentType: string;
  icon: JSX.Element;
}

interface ItemUploadProps {
  title: string;
  documentType: string;
}

export const CustomDocumentListItem = ({ id, document, title, documentType, icon }: CustomDocumentListItemProps) => {
  const [removeDocument, { isLoading }] = useRemoveDocumentMutation();

  const handleDownloadDocument = () => {
    if (document) downloadFile(document.link, document.slug);
  };

  const handleRemoveDocument = () => {
    if (id) {
      removeDocument(id)
        .unwrap()
        .then(() => addToast("success", "Successfully removed document"))
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    }
  };

  if (!document) return <ItemUpload title={title} documentType={documentType} />;

  return (
    <div className="relative inline-flex min-w-[170px] max-w-[170px] flex-1 shrink-0 flex-col items-center justify-center rounded-lg bg-white shadow py-10 px-5">
      {isLoading && <Loading className="z-20 rounded-lg" />}
      <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="absolute top-2.5 !flex max-w-[24px] self-end">
        <DropdownMenu.Item onClick={handleDownloadDocument}>
          <Icon name="DocumentDownload" className="mr-2.5 text-success" size="sm" />
          <Typography className="text-primary-dark">Download Document</Typography>
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleRemoveDocument}>
          <Icon name="close" isCustom className="mr-2.5 text-danger" size="sm" />
          <Typography className="text-primary-dark">Remove Document</Typography>
        </DropdownMenu.Item>
      </DropdownMenu>
      <div className="flex flex-col items-center">
        <span className="relative flex h-12 w-12 items-center justify-center">
          <Icon name="document" isCustom size={96} className="absolute h-full w-full" />
          <span className="z-10">{icon}</span>
        </span>
        <Typography className="mt-4 min-h-[3rem] text-center">{title}</Typography>
      </div>
    </div>
  );
};

const ItemUpload = ({ title, documentType }: ItemUploadProps) => {
  const [uploadDocument] = useUploadDocumentMutation();
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = (file: File) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("document_type", documentType);
    uploadDocument(formData)
      .unwrap()
      .then(() => addToast("success", "Successfully uploaded document."))
      .catch((e) => {
        setIsLoading(false);
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  return (
    <Dropzone
      accept={{ "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"], "application/pdf": [".pdf"] }}
      maxSize={90500000}
      upload={handleUpload}
      detail={
        <div className="relative">
          {isLoading && <Loading className="rounded-lg" />}
          <div className="inline-flex min-w-[170px] max-w-[170px] flex-1 shrink-0 flex-col items-center justify-center bg-neutral-gray border border-dashed border-neutral-mid-gray text-neutral-dark-gray rounded-lg py-10 px-5 cursor-pointer hover:opacity-50">
            <Icon name="Add" size={48} />
            <Typography variant="paragraph" className="text-center mt-[18px]">{`Upload ${title}`}</Typography>
          </div>
        </div>
      }
    />
  );
};

