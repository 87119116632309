import { useNavigate } from "react-router-dom";
import { ImageSelectItem, Panel, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useSignUpContext } from "../context";
import { userTypeImage, userTypes } from "../fixtures";
import { UserType, UserTypeData } from "../types";
import { Restricted } from "@/components/pages";

export const UserSelectForm = () => {
  const { name, singleTenant, availableAccountTypesOnRegister } = useOperator();
  const navigate = useNavigate();
  const { setStep, setUserTypeData } = useSignUpContext();
  const options = singleTenant
    ? availableAccountTypesOnRegister.map((type) => userTypes[type as UserType])
    : Object.keys(userTypes).map((type) => userTypes[type as UserType]);

  const handleSelectUserType = (userTypeData: UserTypeData) => {
    switch (userTypeData.type) {
      case "operator":
        setUserTypeData(userTypeData);
        setStep("signup");
        break;
      case "driver":
        navigate("/signup/driver");
        break;
      case "executive_assistant":
      case "passenger":
      case "travel_manager":
        setUserTypeData(userTypeData);
        if (!singleTenant) setStep("provider-select");
        else setStep("signup");
        break;
      default:
        setUserTypeData(userTypeData);
        setStep("provider-select");
    }
  };

  if (options.length <= 1) return <Restricted />;

  return (
    <div>
      <Panel>
        <div className="mb-7 flex flex-col gap-y-1.5">
          <Typography variant="h1">Sign up</Typography>
        </div>
        <div className="flex flex-col gap-y-4">
          <Typography variant="title">{`How are you primarily using ${name}?`}</Typography>
          <div className="grid grid-cols-2 gap-2.5 md:grid-cols-3 md:gap-5 lg:grid-cols-5">
            {options.map((o) => (
              <ImageSelectItem key={o.type} src={userTypeImage[o.type]} label={o.name} onClick={() => handleSelectUserType(o)} />
            ))}
          </div>
        </div>
      </Panel>
    </div>
  );
};
