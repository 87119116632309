import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FieldErrors, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AddOnTextInput, Button, Loading, TextInput, Typography, ErrorMessage, Toggle, Alert, Tooltip, Icon } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePrompt } from "@/hooks/usePrompt";
import { useDeleteStateBasedFixedPricingMutation, useUpdateStateBasedFixedPricingMutation } from "@/redux/apis/config/pricing/tripCost";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setFixedPricingAction } from "@/redux/slices/pricing/pricingSlice";
import { fixedPricingPageStateSelector } from "@/redux/slices/pricing/selectors";
import { PricingProfile, FixedPricingState } from "@/redux/slices/pricing/types";
import { addToast, clsx, getAssetUrl, getCurrencySymbol } from "@/utils";
import { FixedPricingEditorModals } from "./FixedPricingEditorModals";
import { RangeRow } from "./contents/RangeRow";
import { fixedPricingFormSchema } from "./fixtures";
import { getManagementCostUnitSymbol, getTotalTripCost, validateRanges } from "./helpers";
import { FixedPricingFormData, ManagementCostUnit } from "./types";

interface StateBasedFixedPricingEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  pricing: FixedPricingState;
  pricingProfile: PricingProfile;
}

export const StateBasedFixedPricingEditor = ({ pricing, pricingProfile, className, ...props }: StateBasedFixedPricingEditorProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { baseInterval, intervals, state, name, background, currency, distanceUnit } = pricing;
  const currencySymbol = getCurrencySymbol(currency) || "";
  const [managementCostUnit, setManagementCostUnit] = useState<ManagementCostUnit>(
    (baseInterval?.managementCostUnit as ManagementCostUnit) ?? "percentage"
  );
  const [selectedRange, setSelectedRange] = useState<number | null>(null);
  const { action } = useAppSelector(fixedPricingPageStateSelector);

  const [updateStateBasedPricing, { isLoading: isLoadingUpdate }] = useUpdateStateBasedFixedPricingMutation();
  const [deleteStateBasedPricing, { isLoading: isLoadingDelete }] = useDeleteStateBasedFixedPricingMutation();
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, errors },
  } = useForm<FixedPricingFormData>({
    defaultValues: {
      baseInterval: {
        minimumRange: 0,
        maximumRange: baseInterval?.maximumRange || 0,
        intervalCost: baseInterval?.intervalCost || 0,
        managementCost: baseInterval?.managementCost || 0,
        managementCostUnit,
        note: baseInterval?.note || "",
      },
      intervals: intervals.map((interval) => ({
        maximumRange: interval.maximumRange || 0,
        intervalCost: interval.intervalCost || 0,
        managementCost: interval.managementCost || 0,
        managementCostUnit,
        note: interval.note || "",
      })),
    },
    resolver: zodResolver(fixedPricingFormSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "intervals",
  });
  const values = watch();

  usePrompt({ when: isDirty && action !== "exit", message: "Changes will not be saved. Do you want to proceed?" });

  useEffect(() => {
    const oldManagementCostUnit = values.baseInterval.managementCostUnit;
    if (managementCostUnit !== oldManagementCostUnit) {
      const oldBaseIntervalManagementCost = values.baseInterval.managementCost;
      const baseIntervalIntervalCost = values.baseInterval.intervalCost;
      let newBaseIntervalManagementCost = 0;
      if (baseIntervalIntervalCost > 0) {
        newBaseIntervalManagementCost =
          managementCostUnit === "percentage"
            ? Number(((oldBaseIntervalManagementCost / values.baseInterval.intervalCost) * 100).toFixed())
            : Number(((oldBaseIntervalManagementCost / 100) * values.baseInterval.intervalCost).toFixed(2));
      }
      setValue("baseInterval.managementCost", newBaseIntervalManagementCost, { shouldDirty: true });
      setValue("baseInterval.managementCostUnit", managementCostUnit, { shouldDirty: true });

      for (let i = 0; i < values.intervals.length; i++) {
        const oldIntervalManagementCost = values.intervals[i].managementCost;
        const intervalIntervalCost = values.intervals[i].intervalCost;
        let newIntervalManagementCost = 0;
        if (intervalIntervalCost > 0) {
          newIntervalManagementCost =
            managementCostUnit === "percentage"
              ? Number(((oldIntervalManagementCost / values.intervals[i].intervalCost) * 100).toFixed())
              : Number(((oldIntervalManagementCost / 100) * values.intervals[i].intervalCost).toFixed(2));
        }
        setValue(`intervals.${i}.managementCost`, newIntervalManagementCost, { shouldDirty: true });
        setValue(`intervals.${i}.managementCostUnit`, managementCostUnit, { shouldDirty: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managementCostUnit]);

  const onSubmit = handleSubmit((data) => {
    const isValid = validateRanges([
      data.baseInterval.minimumRange,
      data.baseInterval.maximumRange,
      ...data.intervals.map((interval) => interval.maximumRange),
    ]);
    if (isValid) {
      updateStateBasedPricing({
        id: pricingProfile.id,
        state,
        base_interval: {
          range_from: data.baseInterval.minimumRange,
          range_to: data.baseInterval.maximumRange,
          interval_cost: data.baseInterval.intervalCost * 100,
          management_cost: managementCostUnit === "percentage" ? data.baseInterval.managementCost : data.baseInterval.managementCost * 100,
          management_cost_unit: data.baseInterval.managementCostUnit,
          note: data.baseInterval.note,
        },
        intervals: data.intervals.map((i) => ({
          range_to: i.maximumRange,
          interval_cost: i.intervalCost * 100,
          management_cost: managementCostUnit === "percentage" ? i.managementCost : i.managementCost * 100,
          management_cost_unit: i.managementCostUnit,
          note: i.note,
        })),
      })
        .unwrap()
        .then(() => {
          addToast("success", "Successfully Updated State Based Fixed Pricing");
          navigate("../");
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    }
  });

  const handleDeleteFixedPricing = () => {
    dispatch(setFixedPricingAction(undefined));
    deleteStateBasedPricing({ id: pricingProfile.id, state })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Deleted State Based Fixed Pricing");
        navigate("../../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const handleAddRow = () => {
    append({
      maximumRange:
        values.intervals.length > 0
          ? Number(values.intervals[values.intervals.length - 1].maximumRange) + 1
          : Number(values.baseInterval.maximumRange) + 1,
      intervalCost: 0,
      managementCost: values.baseInterval.managementCost,
      managementCostUnit,
      note: "",
    });
  };

  const handleConfirmDelete = (i: number) => {
    setSelectedRange(i);
    dispatch(setFixedPricingAction("deleteRange"));
  };

  const handleDeleteRow = () => {
    if (selectedRange != null) {
      remove(selectedRange);
      dispatch(setFixedPricingAction(undefined));
    }
  };

  const handleConfirmAddNote = (i: number) => {
    setSelectedRange(i);
    dispatch(setFixedPricingAction("addNote"));
  };

  const handleAddNote = (note: string) => {
    if (selectedRange != null) {
      if (selectedRange > -1) {
        setValue(`intervals.${selectedRange}.note`, note, { shouldDirty: true });
      } else {
        setValue("baseInterval.note", note, { shouldDirty: true });
      }
    }

    dispatch(setFixedPricingAction(undefined));
  };

  const getSelectedRangeNote = (): string => {
    if (selectedRange != null) {
      if (selectedRange < 0) {
        return values.baseInterval.note;
      } else if (selectedRange < fields.length) {
        return values.intervals[selectedRange].note;
      }
    }

    return "";
  };

  const handleManagementCostUnitToggle = (checked: boolean) => {
    setManagementCostUnit(checked ? "percentage" : "fixed");
    addToast("success", `Management Fee switched to ${checked ? "Percentage" : "Fixed Dollar Value"}`);
  };

  const renderRows = () => {
    return fields.map((interval, i) => {
      return (
        <RangeRow
          key={interval.id}
          note={values.intervals[i].note}
          rowNumber={i + 2}
          renderRange={() => (
            <div className="flex flex-col gap-y-1">
              <Typography variant="small" className="text-neutral-dark-gray">
                Range from - to
              </Typography>
              <div className="flex flex-col gap-x-2 gap-y-2 md:flex-row lg:flex-row">
                <div className="flex flex-row items-center gap-x-2">
                  <TextInput
                    type="number"
                    size="sm"
                    className="w-[51px] text-center"
                    disabled
                    value={i > 0 ? Number(values.intervals[i - 1].maximumRange) : Number(values.baseInterval.maximumRange)}
                  />
                  <Typography variant="small" className="text-neutral-dark-gray">
                    -
                  </Typography>
                </div>
                <AddOnTextInput
                  endAddOn={distanceUnit}
                  type="number"
                  size="sm"
                  className="w-[115px] [&_input]:text-right"
                  step="any"
                  hasError={!!errors.intervals?.[i]?.maximumRange}
                  {...register(`intervals.${i}.maximumRange`)}
                />
              </div>
            </div>
          )}
          renderCost={() => (
            <div className="flex flex-col gap-x-[30px] gap-y-2 md:flex-row lg:flex-row">
              <div className="flex flex-col gap-y-1">
                <Typography variant="small" className="text-neutral-dark-gray">
                  Base Trip Cost
                </Typography>
                <AddOnTextInput
                  startAddOn={`${currency} ${currencySymbol}`}
                  type="number"
                  size="sm"
                  className="w-[145px]"
                  step="any"
                  hasError={!!errors.intervals?.[i]?.intervalCost}
                  {...register(`intervals.${i}.intervalCost`)}
                />
              </div>
              <div className="flex flex-col gap-y-1">
                <Typography variant="small" className="text-neutral-dark-gray">
                  Management Fee
                </Typography>
                <AddOnTextInput
                  endAddOn={getManagementCostUnitSymbol(values.intervals[i].managementCostUnit, currencySymbol)}
                  type="number"
                  size="sm"
                  className="w-[130px] [&_input]:text-right"
                  step="any"
                  hasError={!!errors.intervals?.[i]?.managementCost}
                  {...register(`intervals.${i}.managementCost`)}
                />
              </div>
            </div>
          )}
          renderErrors={() =>
            errors.intervals?.[i] ? (
              <div className="flex flex-col">
                <ErrorMessage errors={errors.intervals[i] as FieldErrors} name="maximumRange" label="Maximum Range" />
                <ErrorMessage errors={errors.intervals[i] as FieldErrors} name="intervalCost" label="Base Trip Cost" />
                <ErrorMessage errors={errors.intervals[i] as FieldErrors} name="managementCost" label="Management Fee" />
              </div>
            ) : null
          }
          totalTripCost={getTotalTripCost(
            Number(values.intervals[i].intervalCost),
            Number(values.intervals[i].managementCost),
            values.intervals[i].managementCostUnit,
            currencySymbol
          )}
          onAddNote={() => handleConfirmAddNote(i)}
          onDelete={() => handleConfirmDelete(i)}
        />
      );
    });
  };

  return (
    <div className={clsx("relative", className)} {...props}>
      {(isLoadingUpdate || isLoadingDelete) && <Loading />}
      <form onSubmit={onSubmit}>
        <div className="flex items-center">
          <div className="flex flex-1 items-center gap-x-4">
            <img src={getAssetUrl(background)} className="h-12 w-12 shrink-0 rounded-full object-cover" />
            <Typography variant="h3" className="leading-8">
              {name}
            </Typography>
          </div>
          <div className="flex flex-row items-center gap-x-[26px]">
            <div className="flex flex-row items-center gap-x-4">
              <div className="flex flex-row items-center gap-x-1.5">
                <Typography variant="action">Management Fee ({managementCostUnit === "percentage" ? "%" : currencySymbol})</Typography>
                <Tooltip
                  content={`Switch between setting your Management Fee as a percentage (%) or a fixed dollar amount (${currencySymbol}).`}
                  placement="bottom"
                >
                  <span>
                    <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
                  </span>
                </Tooltip>
              </div>
              <Toggle
                checked={managementCostUnit === "percentage"}
                onChange={handleManagementCostUnitToggle}
                className={managementCostUnit === "percentage" ? "bg-info" : "bg-success"}
              >
                {managementCostUnit === "percentage" ? (
                  <Typography variant="title" className="text-info">
                    %
                  </Typography>
                ) : (
                  <Typography variant="title" className="text-success">
                    {currencySymbol}
                  </Typography>
                )}
              </Toggle>
            </div>
            <div className="flex flex-row items-center gap-x-4">
              <Button
                variant="secondary"
                onClick={() => {
                  isDirty ? dispatch(setFixedPricingAction("exit")) : navigate("../");
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          </div>
        </div>
        <Alert type="info" message="Fixed based pricing - set the fixed cost for as many ranges as you wish." className="mt-6" hasClose />
        <div className="mt-4 flex flex-col gap-y-4">
          <RangeRow
            rowNumber={1}
            note={values.baseInterval.note}
            renderRange={() => (
              <div className="flex flex-col gap-y-1">
                <Typography variant="small" className="text-neutral-dark-gray">
                  Range from - to
                </Typography>
                <div className="flex flex-col gap-x-2 gap-y-2 md:flex-row lg:flex-row">
                  <div className="flex flex-row items-center gap-x-2">
                    <TextInput
                      type="number"
                      size="sm"
                      className="w-[51px] text-center"
                      disabled
                      {...register("baseInterval.minimumRange")}
                    />
                    <Typography variant="small" className="text-neutral-dark-gray">
                      -
                    </Typography>
                  </div>
                  <AddOnTextInput
                    endAddOn={distanceUnit}
                    type="number"
                    size="sm"
                    className="w-[115px] [&_input]:text-right"
                    step="any"
                    hasError={!!errors.baseInterval?.maximumRange}
                    {...register("baseInterval.maximumRange")}
                  />
                </div>
              </div>
            )}
            renderCost={() => (
              <div className="flex flex-col gap-x-[30px] gap-y-2 md:flex-row lg:flex-row">
                <div className="flex flex-col gap-y-1">
                  <div className="flex flex-row gap-x-1.5">
                    <Typography variant="small" className="text-neutral-dark-gray">
                      Base Trip Cost
                    </Typography>
                    <Tooltip content="Driver payment is calculated as a % of Base Cost only" placement="bottom">
                      <span>
                        <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
                      </span>
                    </Tooltip>
                  </div>
                  <AddOnTextInput
                    startAddOn={`${currency} ${currencySymbol}`}
                    type="number"
                    size="sm"
                    className="w-[145px]"
                    hasError={!!errors.baseInterval?.intervalCost}
                    step="any"
                    {...register("baseInterval.intervalCost")}
                  />
                </div>
                <div className="flex flex-col gap-y-1">
                  <div className="flex flex-row gap-x-1.5">
                    <Typography variant="small" className="text-neutral-dark-gray">
                      Management Fee
                    </Typography>
                    <Tooltip content="Management fee is fully paid to the Operator" placement="bottom">
                      <span>
                        <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
                      </span>
                    </Tooltip>
                  </div>
                  <AddOnTextInput
                    endAddOn={getManagementCostUnitSymbol(values.baseInterval.managementCostUnit, currencySymbol)}
                    type="number"
                    size="sm"
                    className="w-[130px] [&_input]:text-right"
                    step="any"
                    hasError={!!errors.baseInterval?.managementCost}
                    {...register("baseInterval.managementCost")}
                  />
                </div>
              </div>
            )}
            renderErrors={() =>
              errors.baseInterval ? (
                <div className="flex flex-col">
                  <ErrorMessage errors={errors.baseInterval as FieldErrors} name="maximumRange" label="Maximum Range" />
                  <ErrorMessage errors={errors.baseInterval as FieldErrors} name="intervalCost" label="Base Trip Cost" />
                  <ErrorMessage errors={errors.baseInterval as FieldErrors} name="managementCost" label="Management Fee" />
                </div>
              ) : null
            }
            totalTripCost={getTotalTripCost(
              Number(values.baseInterval.intervalCost),
              Number(values.baseInterval.managementCost),
              values.baseInterval.managementCostUnit,
              currencySymbol
            )}
            onAddNote={() => handleConfirmAddNote(-1)}
          />
          {renderRows()}
        </div>
        <div className="mt-6 flex flex-row">
          <div className="flex-1">
            <Button variant="primary" startIcon="Add" size="sm" onClick={handleAddRow}>
              Add New Range
            </Button>
          </div>
          <Button
            variant="secondary"
            className="text-danger enabled:!border-danger"
            size="sm"
            onClick={() => dispatch(setFixedPricingAction("deleteFixedPricing"))}
            disabled={!baseInterval}
          >
            Delete Fixed Pricing
          </Button>
        </div>
      </form>
      <FixedPricingEditorModals
        selectedRange={selectedRange}
        selectedRangeNote={getSelectedRangeNote()}
        onDeleteRow={() => handleDeleteRow()}
        onDeleteFixedPricing={() => handleDeleteFixedPricing()}
        onAddNote={(note) => handleAddNote(note)}
      />
    </div>
  );
};
