import { Skeleton, ErrorPanel } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAppSelector } from "@/redux/hooks";
import { useGetExternalSuppliersParams } from "./useGetExternalSuppliersParams";
import { useGetExternalSuppliersQuery } from "@/redux/apis/externalSupplier/externalSupplierApi";
import { externalSuppliersSelector } from "@/redux/slices/externalSupplier/selectors";
import { MyExternalSuppliersTable } from "./MyExternalSuppliersTable";

export const MyExternalSuppliersPanel = () => {
  const { params } = useGetExternalSuppliersParams();
  const { isFetching, error } = useGetExternalSuppliersQuery(params, { refetchOnMountOrArgChange: true });
  const externalSuppliers = useAppSelector(externalSuppliersSelector);

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;

  return (
    <>
      <MyExternalSuppliersTable items={externalSuppliers} />
      <MyExternalSuppliersTable.Pagination />
    </>
  );
};
