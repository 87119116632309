import { createContext, useContext, useState } from "react";
import { Client } from "@/redux/slices/client/types";

type ClientsStep = "remove-access" | "import" | undefined;

interface ClientsState {
  selectedClient?: Client;
  setSelectedClient: React.Dispatch<React.SetStateAction<Client | undefined>>;
  step: ClientsStep;
  setStep: React.Dispatch<React.SetStateAction<ClientsStep>>;
}

const ClientsContext = createContext<ClientsState | null>(null);

export const ClientsContextProvider = ({ children }: React.PropsWithChildren) => {
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [step, setStep] = useState<ClientsStep>();

  return (
    <ClientsContext.Provider value={{ selectedClient, setSelectedClient, step, setStep }}>
      {children}
    </ClientsContext.Provider>
  );
};

export const useClientsContext = () => {
  const context = useContext(ClientsContext);
  if (!context) throw new Error("useClientsContext must be used within a ClientsContextProvider");

  return context;
};
