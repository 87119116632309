import { format } from "date-fns";
import { Button, IconButton, Typography } from "@/components/atoms";
import { DriverIncident } from "@/redux/apis/driver/types";
import { clsx } from "@/utils";

interface IncidentReportProps {
  incident: DriverIncident;
  onDelete: (incident: DriverIncident) => void;
  onDownload: (documentId: string) => void;
}
export const IncidentReport = ({ incident, onDelete, onDownload }: IncidentReportProps) => {
  const { text, documents, isDeleted, jobId, createdBy, deletedBy } = incident;

  return (
    <div className={clsx("py-5", isDeleted && "text-danger [&_.typography]:!text-danger")}>
      <div className="flex items-center gap-2">
        <Typography variant="action" className="flex-1 text-primary">
          Incident Report {jobId && `for Job #${jobId}`} {createdBy && `(From ${createdBy.firstName} ${createdBy.lastName})`}
        </Typography>
        {isDeleted ? (
          <Typography variant="action" className="text-danger">
            Deleted by {deletedBy ? `${deletedBy.firstName} ${deletedBy.lastName}` : "Admin"}
          </Typography>
        ) : (
          <IconButton onClick={() => onDelete(incident)} iconName="Trash" className="text-danger" variant="custom" iconSize="md" />
        )}
      </div>
      <Typography className={clsx(isDeleted && "line-through")}>{text}</Typography>
      <div className="divide-y divide-neutral-mid-gray">
        {documents && documents.length > 0 && (
          <div className="space-y-2">
            {documents.map((doc) => (
              <Button
                key={doc.uuid}
                endIcon={!isDeleted ? "ImportCurve" : undefined}
                onClick={() => onDownload(doc.uuid)}
                variant="custom"
                className="!px-0 text-info"
                disabled={isDeleted}
              >
                {doc.filename}
              </Button>
            ))}
          </div>
        )}
      </div>
      <div className="flex justify-end gap-4 [&_.typography]:!text-neutral-dark-gray ">
        <Typography variant="small">
          {new Date(incident.createdAt).toLocaleDateString("en-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Typography>
        <Typography variant="small">{format(new Date(incident.createdAt), "hh:mm a")}</Typography>
      </div>
    </div>
  );
};
