import { Icon, Panel, Typography, Skeleton, URLDetail, Button } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useOperator } from "@/hooks";
import { useGetAppLinksQuery } from "@/redux/apis/config/linksAndDocuments";
import { useAppSelector } from "@/redux/hooks";
import { registrationSelector } from "@/redux/slices/brandingAndAcquisition/selectors";
import { copyToClipboard } from "@/utils";

export const DriverTools = () => {
  const { providerCode } = useOperator();
  const { isFetching } = useGetAppLinksQuery();
  const { appLinks } = useAppSelector(registrationSelector);
  const appDownload = appLinks.find(link => link.document.slug === "app-driver");

  const renderAppLink = () => {
    const link = appDownload?.document.link;

    if (link) {
      return (
        <div className="flex flex-col lg:items-center lg:flex-row gap-4">
          <div className="flex flex-col">
            <URLDetail href={link} />
            <div className="flex flex-row items-center ml-9 gap-x-2">
              <Typography variant="paragraph" className="text-neutral-dark-gray">Transport Provider Code:</Typography>
              <Typography variant="action" className="text-neutral-black uppercase">{providerCode}</Typography>
            </div>
          </div>
          <Button
            variant="tertiary"
            startIcon="Copy"
            className="!text-info hover:bg-info-light"
            size="sm"
            onClick={() => copyToClipboard(link)}
          >
            Copy
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      <PageHeader.Actions />
      {isFetching ? <Skeleton /> : (
        <Panel>
          <div className="flex flex-col lg:flex-row gap-2">
            <div className="flex flex-1 flex-col gap-y-1">
              <Typography variant="h3">Driver Tools</Typography>
              <Typography variant="paragraph" className="text-neutral-dark-gray">Links and tools to help you register your drivers</Typography>
            </div>
          </div>
          <ConfigDetail 
            title="App Download Link"
            titleEndIcon={(
              <div className="flex flex-row items-center gap-x-1">
                <Icon isCustom name="app-google-playstore" size="sm" />
                <Icon isCustom name="app-ios" size="sm" />
              </div>
            )}
          >
            {renderAppLink()}
          </ConfigDetail>
        </Panel>
      )}
    </>
  );
};
