import { DropdownMenu, Icon, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { MyExternalSuppliersTableData } from "./types";
import { ExternalSupplier } from "@/redux/slices/externalSupplier/types";
import { accountTypes } from "../../types";
import { renderAvatar, renderRating, renderStatus } from "../../helpers";

export const generateMyExternalSuppliersTableColumns = () => {
  const columns: DataTableColumnDef<MyExternalSuppliersTableData>[] = [
    { 
      accessorKey: "name", 
      header: "Name", 
      cell: (props) => props.row.original.name, 
      id: "name", 
      size: 300 
    },
    {
      accessorKey: "type",
      header: "Type",
      cell: (props) => props.row.original.type,
      enableSorting: false,
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: (props) => props.row.original.email,
      id: "email",
      size: 370,
    },
    {
      accessorKey: "phone",
      header: "Phone",
      cell: (props) => props.row.original.phone,
      enableSorting: false,
      size: 250,
    },
    {
      accessorKey: "state",
      header: "State",
      cell: (props) => props.row.original.state,
      enableSorting: false,
    },
    {
      accessorKey: "rating",
      header: "Rating",
      cell: (props) => props.row.original.rating,
      id: "rating",
    },
    {
      accessorKey: "completion",
      header: "Completion",
      cell: (props) => props.row.original.completion,
      id: "completion",
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
      size: 250,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateMyExternalSuppliersTableData = (items: ExternalSupplier[], onManageClick: (id: string) => void) => {
  const data = items.map(
    (i) =>
      ({
        name: (
          <div className="flex cursor-pointer items-center gap-2.5 hover:text-info" onClick={() => onManageClick(i.id)}>
            {renderAvatar(i.avatar, i.name)}
            <div className="flex flex-col">
              <Typography variant="paragraph">{i.name}</Typography>
            </div>
          </div>
        ),
        type: accountTypes[i.type],
        email: i.email,
        phone: i.phone,
        state: i.state,
        rating: renderRating(i.rating),
        completion: `${i.profileCompletion * 100}%`,
        status: renderStatus(i.status),
        action: (
          <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
            <DropdownMenu.Item onClick={() => onManageClick(i.id)} className="w-[242px]">
              <Icon name="People" className="mr-2.5 text-neutral-black" size="sm" />
              <Typography className="text-neutral-black">Manage External Supplier</Typography>
            </DropdownMenu.Item>
          </DropdownMenu>
        ),
      } as MyExternalSuppliersTableData)
  );

  return data;
};