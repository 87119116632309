import { useWindowSize } from "@react-hookz/web";
import { flatten } from "ramda";
import { useCallback, useMemo, useState } from "react";
import { Button, Typography, Popover, Drawer } from "@/components/atoms";
import { getFilterDrawer, getFilterPopover } from "./helpers";
import { FilterNavButton } from "./common/FilterNavButton";
import { ExternalSuppliersDecodedFilters, ExternalSuppliersFilterKey, useExternalSuppliersFilters } from "./useExternalSuppliersFilters";
import { useLocation } from "react-router-dom";
import { clsx } from "@/utils";

const ExternalSuppliersFilterNavButtons = ({ filters, toggleActiveFilters }: { filters: ExternalSuppliersDecodedFilters, toggleActiveFilters: (key: ExternalSuppliersFilterKey) => void }) => {
  const { pathname } = useLocation();
  const className = "my-3 flex list-none flex-col [&>button>.typography]:-mr-1"

  switch(true) {
    case pathname.includes("/external-suppliers/my-external-suppliers"): 
      return (
        <div className={className}>
          <FilterNavButton onClick={() => toggleActiveFilters("type")} isActive={filters.type !== undefined}>
            Type
          </FilterNavButton>
          <FilterNavButton onClick={() => toggleActiveFilters("state")} isActive={filters.state !== undefined}>
            State
          </FilterNavButton>
          <FilterNavButton onClick={() => toggleActiveFilters("rating")} isActive={filters.rating !== undefined}>
            Rating
          </FilterNavButton>
          <FilterNavButton onClick={() => toggleActiveFilters("status")} isActive={filters.status !== undefined}>
            Status
          </FilterNavButton>
        </div>
      );
    case pathname.includes("/external-suppliers/archived-external-suppliers"): 
      return (
        <div className={className}>
          <FilterNavButton onClick={() => toggleActiveFilters("status")} isActive={filters.status !== undefined}>
            Status
          </FilterNavButton>
        </div>
      );
    default: return null;
  }
};

const ExternalSuppliersFiltersButton = () => {
  const { filters, setFilter } = useExternalSuppliersFilters();
  const numActiveFilters = useMemo(() => flatten(Object.values(filters).filter(Boolean)).length, [filters]);

  const toggleActiveFilters = useCallback(
    (key: ExternalSuppliersFilterKey) => {
      if (filters[key] === undefined) {
        setFilter(key, null);

        // to avoid adding more state of the popover state, we will just click the button to open the popover
        setTimeout(
          () => document.querySelectorAll(`[data-filter-name="${key}"]`).forEach((button) => (button as HTMLElement).click()),
          100
        );
      } else setFilter(key, undefined);
    },
    [filters, setFilter]
  );

  return (
    <Popover placement="bottom-end">
      <Button variant="tertiary" startIcon="Sort" className="h-[34px] sm:p-3 max-md:[&>span]:hidden" size="sm">
        Filters {numActiveFilters > 0 && `(${numActiveFilters})`}
      </Button>
      <div className="flex w-[225px] flex-col-reverse rounded-lg bg-white shadow-dropdown">
        <nav className="flex flex-col">
          <ExternalSuppliersFilterNavButtons filters={filters} toggleActiveFilters={toggleActiveFilters} />
        </nav>
      </div>
    </Popover>
  );
};

const ExternalSuppliersActiveFilters = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { width } = useWindowSize();
  const { sortedFilters, filters, clearFilters, resetFilters } = useExternalSuppliersFilters();

  const renderFilters = useCallback(
    () =>
      sortedFilters.map(([key, value]) => {
        if (value === undefined) return null;
        return width > 768 ? getFilterPopover(key) : (getFilterDrawer(key, true) as React.ReactElement);
      }),

    [sortedFilters, width]
  );

  const numActiveFilters = flatten(Object.values(filters).filter((f) => f !== undefined)).length;

  if (numActiveFilters === 0) return null;

  return (
    <div className={clsx("flex max-sm:flex-col sm:gap-2", className)} {...props}>
      <div className="thin-scrollbar flex flex-1 items-center gap-2 overflow-x-auto pb-2">
        <Typography variant="action" className="shrink-0 text-neutral-dark-gray max-sm:hidden">
          Selected Filters:
        </Typography>
        <div className="flex flex-1 gap-2">{renderFilters()}</div>
      </div>
      <div className="shink-0 flex self-start max-sm:self-end">
        <Button onClick={clearFilters} variant="tertiary" size="xs" className="text-neutral-dark-gray">
          Clear
        </Button>
        <Button onClick={resetFilters} variant="tertiary" size="xs" className="text-neutral-dark-gray">
          Reset
        </Button>
      </div>
    </div>
  );
};

const ExternalSuppliersFiltersMenuDrawer = () => {
  const { filters, setFilter } = useExternalSuppliersFilters();
  const [open, setOpen] = useState(false);
  const [openChild, setOpenChild] = useState<ExternalSuppliersFilterKey>();

  const toggleActiveFilters = useCallback(
    (key: ExternalSuppliersFilterKey) => {
      if (filters[key] !== null) {
        setFilter(key, null);
        setOpenChild(key);
      } else setFilter(key, undefined);
    },
    [filters, setFilter]
  );

  const DrawerComponent = openChild ? getFilterDrawer(openChild) : null;

  return (
    <Drawer
      asNested
      title="Select Filter"
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          setOpenChild(undefined);
          setOpen(false);
        }
      }}
    >
      <Button variant="tertiary" startIcon="Sort" className="h-[34px] justify-start sm:p-3 max-md:[&>span]:hidden" size="sm">
        Filters
      </Button>
      <>
        <nav className="flex flex-col">
        <ExternalSuppliersFilterNavButtons filters={filters} toggleActiveFilters={toggleActiveFilters} />
        </nav>
        {typeof DrawerComponent === "function" ? (
          <DrawerComponent
            open={!!openChild}
            onOpenChange={(open: boolean) => {
              setOpenChild(open ? openChild : undefined);
              if (!open) setOpen(false);
            }}
          />
        ) : null}
      </>
    </Drawer>
  );
};

export const ExternalSuppliersFilters = () => {
  throw new Error("Use Button or ActiveFilters instead of ExternalSuppliersFilters");
};

ExternalSuppliersFilters.Button = ExternalSuppliersFiltersButton;
ExternalSuppliersFilters.ActiveFilters = ExternalSuppliersActiveFilters;
ExternalSuppliersFilters.DrawerButton = ExternalSuppliersFiltersMenuDrawer;
